import React from 'react';
import { Input, Button, Space, Upload, Select, Switch } from 'antd';
import service from "../../../request"
const { Option } = Select;
class EditorFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID: 0,
            Name: "",
            Cover: "",
            File: "",
            FileList: [],
            CoverFileList: [],
            Header: "https://api2.yuexiaojing.com/v3/",
            form: null,
            DirectoryID: 0,
            IsDownload:1,
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>视频名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.changeName(e)
                            }} />
                        </Space>
                        <Space align="center" style={{ marginBottom: "20px" }} >
                            <div style={{ width: "100px", textAlign: 'right' }}>文件：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadFile(f) }} fileList={this.state.FileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传文件</Button>
                            </Upload>
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>文件封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadCover(f) }} fileList={this.state.CoverFileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传封面</Button>
                            </Upload>
                        </Space>
                        <Space align="center" >
                                <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>允许下载</div>
                                <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.IsDownload == 1 ? true : false} onChange={
                                    () => { this.setState({ IsDownload: this.state.IsDownload == 1 ? 2 : 1 }) }} />
                            </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {

        // this.paging()
        this.setState({
            IsDownload :this.props.FileItem.IsDownload,
            ID: this.props.FileItem.ID,
            DirectoryID: this.props.FileItem.DirectoryID,
            Name: this.props.FileItem.Name,
            File: this.props.FileItem.Path,
            Cover: this.props.FileItem.Cover,
        }, () => {
            var Covers = this.state.Cover.split("/")
            var Coverslength = Covers.length - 10
            var CoversItem = Covers[Coverslength]

            var File = this.state.File.split("/")
            var Filelength = File.length - 1
            var FileItem = File[Filelength]
            this.setState({
                CoverFileList: [{
                    uid: '-1',
                    name: CoversItem,
                    status: 'done',
                    url: this.state.Header + this.state.Cover,
                    thumbUrl: this.state.Header + this.state.Cover,
                }],
                FileList: [{
                    uid: '-1',
                    name: FileItem,
                    status: 'done',
                    url: this.state.Header + this.state.File,
                    thumbUrl: this.state.Header + this.state.File,
                }]
            })

        })
    }
  
    changeName(e) {
        this.setState({
            Name: e.target.value,
        })
    }
    // 文件
    UploadFile(f) {
        this.setState({ File: f.file, FileList: f.fileList })
    }

    // 文件封面
    UploadCover(f) {
        this.setState({ Cover: f.file, CoverFileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default EditorFile 