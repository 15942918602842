import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Layout, Menu, Button, Popover, Space, message, Modal, Input, } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, } from '@ant-design/icons';
import { adminRoutes } from '../routes'
// import './frame.css'
import logo from '../images/logo_blue.png'
import service from '../request';
import cookie from "react-cookies";

import "../index.css"
const { Header, Content, Sider } = Layout;

class Frame extends Component {
  constructor(props) {
    super(props)
    this.state = {
      routes: [],
      collapsed: false,
      setPasswordVisible: false,
      oldpassword: "",
      newpassword: "",
      repeatnewpassword: "",
      User:{Username:"",FaceURL:""},
      Header: "https://api2.yuexiaojing.com/v3/",
    }

  }
  componentDidMount() {
    // // var permission = cookie.load("permissions")
    // var ids = []
    // permission.map(p => { ids.push(p.ID) })
    // var routes = adminRoutes.filter(route => ids.includes(route.permissionID))
    this.setState({ routes: adminRoutes,User:cookie.load("user") })
  }
  //
  render() {
    const user = cookie.load("user")
    let ss = this.props.history.location.pathname.split("/")
    const items = this.state.routes.map((route, index) => {
      return {
        key: route.path,
        icon: route.icon,
        label: route.title,
        children: route.children ? route.children.map((item, _) => {
          return {
            key: item.path,
            label: item.title,
            children: item.children ? item.children.map((j, k) => {
              return {
                key: j.path,
                label: j.title,
              };
            }) : '',
          };
        }) : '',
      };
    });
    ////////////////////////////
    // const popMenu = (
    //   <Menu onClick={(p) => {
    //     if (p.key === 'logout') {
    //       cookie.remove("admintoken")
    //       this.props.history.push('/login')
    //     } else {
    //       message.info(p.key)
    //     }
    //   }}>
    //     <Menu.Item key='logout'>退出登录</Menu.Item>
    //   </Menu>
    // )
    return (
      <Layout style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }}  >
        {/* <Header className="header" style={{
          backgroundColor: '#001529'
        }}>
          <div style={{ color: '#fff', height: '100%', fontWeight: 'bold', fontSize: '20px', display: 'flex' }}>
            <div>后台管理</div>
          </div>
         
        </Header> */}

        <Sider collapsible trigger={null} collapsed={this.state.collapsed} width={200} className="site-layout-background" >
          <div className="demo-logo-vertical" style={{ height: "64px", background: "#1F2935", display: 'flex', paddingTop: "8px", }}>
            <img src={logo} alt='' style={{ height: '48px', width: "48px", marginLeft: "18px" }} />
            <div style={{ color: "#fff", marginLeft: "20px" }}>
              {this.state.collapsed == false ? <div style={{ lineHeight: "23px", height: "23px" }}>悦小鲸</div> : ""}
              {this.state.collapsed == false ? <div style={{ lineHeight: "23px", height: "23px" }}>管理系统</div> : ""}
            </div>
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={[this.props.history.location.pathname]}
            defaultOpenKeys={["/" + ss[1] + "/" + ss[2]]}
            // defaultOpenKeys={["/admin/tissue"]}
            style={{ height: 'calc(100% - 64px)', borderRight: 0 }}
            items={items}
            onClick={p => this.props.history.push(p.key)}
          >
          </Menu>
        </Sider>

        <Layout>
          <Header
            style={{
              padding: 0,
              background: "#1F2935",
              position: "relative"
            }}
          >
            <Button
              type="text"
              icon={this.state.collapsed ? <MenuUnfoldOutlined style={{ color: '#fff' }} /> : <MenuFoldOutlined style={{ color: '#fff' }} />}
              onClick={() => this.setCollapsed(!this.state.collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
            <div style={{color:"#fff", position: "absolute",  right: "20px",
                    top: "0px",}}>{"管理员"}</div>
            <Space wrap>
              <Popover content={() => this.renderPopover()} title="设置" trigger="hover">
                <Button
                  onClick={() => { }}
                  type="text"
                  style={{
                    fontSize: '16px',
                    position: "absolute",
                    borderRadius: "50%",
                    padding: "0px",
                    right: "80px",
                    top: "0px",
                    width: 64,
                    height: 64,
                  }}
                >
                  <img src={logo} alt='' style={{ height: '50px', width: "50px", borderRadius: "50%", marginTop: "6px" }} />
                </Button>
              </Popover>
            </Space>
           
            <Modal
              destroyOnClose={true}
              centered={true}
              title={null}
              footer={null}
              modalRender={(modal) => (
                modal
              )}
              bodyStyle={{ padding: '0px', }}
              open={this.state.setPasswordVisible}
              onCancel={() => this.setState({ setPasswordVisible: false })}
              closable={true}
              width={348}
            >

              <div style={{ height: "330px", padding: "43px 34px", background: "#fff", borderRadius: "6px" }}>
                <p style={{ textAlign: "center", fontSize: "20px" }}>修改密码</p>
                <Input placeholder="旧密码"  type='password' value={this.state.oldpassword} onChange={(e) => {
                  this.setState({ oldpassword: e.target.value, })
                }} style={{ marginBottom: "20px", border: "1px solid #53bfe5", borderRadius: "6px" }} />
                <Input type='password' placeholder="新密码" value={this.state.newpassword} onChange={(e) => {
                  this.setState({ newpassword: e.target.value, })
                }} style={{ marginBottom: "20px", border: "1px solid #53bfe5", borderRadius: "6px" }} />
                <Input type='password' placeholder="确认密码" value={this.state.repeatnewpassword} onChange={(e) => {
                  this.setState({ repeatnewpassword: e.target.value, })
                }} style={{ marginBottom: "20px", border: "1px solid #53bfe5", borderRadius: "6px" }} />
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.save()}>确认</Button>
                  <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ setPasswordVisible: false }) }}>取消</Button>
                </div>

              </div>
            </Modal>
          </Header>
          <Content
            style={{
              // minWidth: "1600px",
              // margin: '24px 16px',
              // padding: 24,
              // minHeight: 280,
              // background: colorBgContainer,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>

      </Layout>
    )
  }
  setCollapsed(collapsed) {
    this.setState({
      collapsed: collapsed
    })
  }
  renderPopover() {
    return <div style={{ width: "120px", cursor: "pointer" }}>
      <div style={{ height: "50px", lineHeight: '50px', textAlign: "center", borderBottom: "1px solid #ccc", }} onClick={() => { this.setPassword() }}>修改密码</div>
      <div style={{ height: "50px", lineHeight: '50px', textAlign: "center" }} onClick={() => { this.logOut() }} >退出登录</div>
    </div>
  }
  setPassword() {
    this.setState({ setPasswordVisible: true })
  }
  logOut() {
    cookie.remove("admintoken")
    cookie.remove("user")
    this.props.history.push("/login")
  }
  save() {
    if (this.state.newpassword !== this.state.repeatnewpassword) {
      message.error('两次输入密码不一样!');
      return
    }
    service.request({
      method: 'put',
      url: "/admin/updatePassword",
      data: {
        OldPassword: this.state.oldpassword,
        NewPassword: this.state.newpassword,
        ConfirmPassword: this.state.repeatnewpassword
      }
    }).then(res => {
      if (res.code === 200) {
        message.success('修改成功!');
        this.setState({
          ldpassword: "",
          newpassword: "",
          repeatnewpassword: "",
          setPasswordVisible:false,
        })
        // window.location.reload()
      } else {
        if (res.code == 458) {
          message.error('旧密码输入有误!');
        } else {
          message.error('修改失败，请重试!');
        }

      }
    })
  }
}
export default withRouter(Frame)
