import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Switch, Radio, Space, Input, Modal } from 'antd';
import moment from 'moment/moment';
import AddUser from './adduser';
import EditorUser from './editoruser';
import PermissionsUser from './permissionsUser';
import UserIP from './userIps';

const { Search } = Input;
export default class User extends Component {
  constructor(props) {
    super(props)
    this.childAddUserRef = null
    this.childEditorUserRef = null
    this.childPermissionsRef = null
    this.childUserIPRef = null
    this.state = {
      UserList: [],
      SchoolList: [],
      offset: 0,
      Count: 0,
      page: 1,
      TableItem: {},
      searchValue: "",
      AddVisible: false,
      AddLoading: false,
      EditorVisible: false,
      EditorLoading: false,
      DeleteLoading: false,
      DeleteVisible: false,
      ID: 0,
      TypeList: [{ ID: 1, Name: "阶段权限" }, { ID: 2, Name: "阶段下载权限" }],
      TypeVisible: false,
      PermissionsLoading: false,
      PermissionsVisible: false,
      Type: 0,
      IPVisible: false
    }
  }
  render() {
    const columns = [
      { title: '用户昵称', dataIndex: 'NickName', key: 'NickName' },
      { title: '用户名', dataIndex: 'Username', key: 'Username' },
      // { title: '手机号', dataIndex: 'Phone', key: 'Phone', },
      {
        title: '所属学校', dataIndex: 'SchoolID', key: 'SchoolID',
        render: (text, record, index) => {
          return <div>
            {this.renderSchool(record.SchoolID)}
          </div>
        }
      },
      {
        title: '身份', dataIndex: 'Identity', key: 'Identity',
        render: (text, record, index) => {
          return <div>
            {record.Identity == 1 ? "老师" : '校长'}
          </div>
        }
      },
      {
        title: '到期时间', dataIndex: 'TimeLimit', key: 'TimeLimit',
        render: (text, record, index) => {
          return <div>
            {record.TimeLimit <= 0 ? "永久" : moment(parseInt(record.TimeLimit)).format("YYYY-MM-DD")}
          </div>
        }
      },
      {
        title: '状态', dataIndex: 'IsDisabled', key: 'IsDisabled',
        render: (text, record, index) => {
          return <div>
            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={!record.IsDisabled} onChange={
              () => { this.setIsDisabled(record) }} />
          </div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorShowDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
            <Button onClick={() => this.setState({ TypeVisible: true, TableItem: record })} style={{ marginLeft: "20px", color: "#0081FF", }} >权限</Button>
            <Button onClick={() => this.setState({ TableItem: record }, () => { this.setState({ IPVisible: true, }) })} style={{ marginLeft: "20px", color: "#0081FF", }} >IP地址</Button>
            <Button onClick={() => this.setState({ ID: record.ID }, () => { this.setState({ DeleteVisible: true }) })} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    const paginationProps = {
      current: this.state.page, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.Count, // 总条数
      onChange: page => handlePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handlePageChange = (page) => {
      this.setState({ page: page })
      this.getUserList(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          <Search placeholder="搜索" style={{ width: "200px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} />
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddVisible: true })}>添加</Button>
        </div>
        <Drawer
          open={this.state.AddVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ AddVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.AddLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <AddUser ref={this.addUserRef} ></AddUser>
          </Spin>
          {/* 底部 */}
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.AddUserSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ AddVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        <Drawer
          open={this.state.EditorVisible}
          title="修改"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.EditorLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <EditorUser TableItem={this.state.TableItem} ref={this.editorUserRef} ></EditorUser>
          </Spin>
          {/* 底部 */}
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditorUserSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 选择权限 */}
        <Modal
          destroyOnClose={true}
          centered={true}
          title={null}
          footer={null}
          modalRender={(modal) => (
            modal
          )}
          // bodyStyle={{ padding: '0px' }}
          open={this.state.TypeVisible}
          onCancel={() => this.setState({ TypeVisible: false })}
          closable={true}
          width={600}
        >
          <div style={{ height: "200px", padding: "10px 20px" }}>
            <div style={{ fontSize: "20px", marginBottom: "50px" }}>请选择要修改的权限类型</div>
            <Radio.Group buttonStyle="solid">
              {
                this.state.TypeList.map((item, index) => {
                  return <Radio.Button onChange={() => this.setState({ Type: item.ID }, () => { this.setState({ PermissionsVisible: true, TypeVisible: false }) })} value={item.ID} style={{ marginLeft: "15px" }} key={item.ID}>{item.Name}</Radio.Button>
                })
              }
            </Radio.Group>
          </div>
        </Modal>
        {/* 权限列表 */}
        <Drawer
          open={this.state.PermissionsVisible}
          title={this.state.Type == 1 ? "查看权限" : "下载权限"}
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ PermissionsVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.PermissionsLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <PermissionsUser Type={this.state.Type} TableItem={this.state.TableItem} ref={this.PermissionsRef} ></PermissionsUser>
          </Spin>
          {/* 底部 */}
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px', background: "#fff" }}>
            <Space>
              <Button onClick={() => this.PermissionsUserSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ PermissionsVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* IP列表 */}
        <Drawer
          open={this.state.IPVisible}
          title="IP地址"
          width='1200px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ IPVisible: false })}
          destroyOnClose={true}
        >
          <UserIP TableItem={this.state.TableItem} ref={this.userIPRefRef} ></UserIP>
        </Drawer>
        {/* 删除 */}
        <Spin spinning={this.state.DeleteLoading} tip="加载中......" size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            title={null}
            footer={null}
            modalRender={(modal) => (
              modal
            )}
            // bodyStyle={{ padding: '0px' }}
            open={this.state.DeleteVisible}
            onCancel={() => this.setState({ DeleteVisible: false })}
            closable={true}
            width={400}
          >
            <div style={{ heighe: "205px", padding: "10px 20px" }}>
              <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
              <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.determineDelete()}>确认</Button>
                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => this.setState({ DeleteVisible: false })}>取消</Button>
              </div>
            </div>
          </Modal>
        </Spin>
        <Table columns={columns}
          // rowSelection={rowSelection}
          dataSource={this.state.UserList}
          className="table"
          bordered={true}
          pagination={paginationProps}
          rowKey={record => record.ID}
          size='small'
        />
      </div>
    )
  }
  componentDidMount() {
    this.getUserList(this.state.page)
    this.getSchoolList()
  }
  getSchoolList() {
    service.request({
      url: "/admin/school",
      method: "get",
      params: {
        limit: 0,
        offset: 0,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ SchoolList: res.data, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  renderSchool(ID) {
    let c = this.state.SchoolList.find(c => {
      if (c.ID === ID) {
        return c
      }
    })
    // console.log(c)
    if (c) {
      return c.Name
    }
    return ""
  }
  onSearch() {
    this.getUserList(this.state.page)
  }

  getUserList(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/user",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        name: this.state.searchValue,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ UserList: res.data, Count: res.count, page: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  addUserRef = (ref) => {
    this.childAddUserRef = ref
  }
  AddUserSave() {
    if (this.childAddUserRef.state.UserName != "" && this.childAddUserRef.state.NickName != "" && this.childAddUserRef.state.Phone != "" && this.childAddUserRef.state.Password != "" && this.childAddUserRef.state.SchoolID > 0) {
      this.setState({
        AddLoading: true,
      })
      service.request({
        url: "/admin/user",
        method: "post",
        data: {
          NickName: this.childAddUserRef.state.NickName,
          IsDisabled: this.childAddUserRef.state.IsDisabled,
          UserName: this.childAddUserRef.state.UserName,
          Phone: this.childAddUserRef.state.Phone,
          Note: this.childAddUserRef.state.Note,
          TimeLimit: this.childAddUserRef.state.TimeLimit == 0 ? -1 : this.childAddUserRef.state.TimeLimit,
          SchoolID: this.childAddUserRef.state.SchoolID,
          Identity: this.childAddUserRef.state.Identity,
          IsLive: this.childAddUserRef.state.IsLive ? 1 : 2,
          IsEditor: this.childAddUserRef.state.IsEditor ? 1 : 2,
          Password: this.childAddUserRef.state.Password
        },
      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddLoading: false,
            AddVisible: false,
          })
          this.getUserList(this.state.page)
          message.success('添加成功');
        } else {
          this.setState({
            AddLoading: false,
          })
          if (res.code == 452) {
            message.error('添加失败,名字重复');
          } else if (res.code == 454) {
            message.error('添加失败,用户名最少6位')
          } else {
            message.error('添加失败,请重试');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
  editorShowDrawer(item) {
    this.setState({
      EditorVisible: true,
      TableItem: item,
    })
  }
  editorUserRef = (ref) => {
    this.childEditorUserRef = ref
  }
  EditorUserSave() {

    if (this.childEditorUserRef.state.UserName != "" && this.childEditorUserRef.state.NickName != "" && this.childEditorUserRef.state.Phone != "" && this.childEditorUserRef.state.SchoolID > 0) {
      this.setState({
        EditorLoading: true,
      })
      service.request({
        url: "/admin/user",
        method: "put",
        data: {
          ID: this.childEditorUserRef.state.ID,
          NickName: this.childEditorUserRef.state.NickName,
          IsDisabled: !this.childEditorUserRef.state.IsDisabled,
          UserName: this.childEditorUserRef.state.Username,
          Phone: this.childEditorUserRef.state.Phone,
          Note: this.childEditorUserRef.state.Note,
          TimeLimit: this.childEditorUserRef.state.TimeLimit,
          SchoolID: this.childEditorUserRef.state.SchoolID,
          Identity: this.childEditorUserRef.state.Identity,
          IsLive: this.childEditorUserRef.state.IsLive ? 1 : 2,
          IsEditor: this.childEditorUserRef.state.IsEditor ? 1 : 2,
          Password: this.childEditorUserRef.state.Password
        },
      }).then(res => {
        if (res.code == 200) {
          this.getUserList(this.state.page)
          message.success('修改成功');
          this.setState({
            EditorLoading: false,
            EditorVisible: false,
          })
        } else {
          this.setState({
            EditorLoading: false,
          })
          if (res.code == 455) {
            message.error('修改失败,用户名重复');
          } else if (res.code == 454) {
            message.error('修改失败,密码格式错误');
          } else {
            message.error('修改失败,请重试');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
  // 删除
  determineDelete() {
    this.setState({
      DeleteLoading: true
    })
    service.request({
      url: "/admin/user",
      method: "delete",
      data: {
        ID: this.state.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.getUserList(this.state.page)
        this.setState({
          DeleteLoading: false,
          DeleteVisible: false
        })
        message.success('删除成功');
      } else {
        if (res.code == 401) {
          message.error('删除失败，该用户有权限，无法删除');
        } else {
          message.error('删除失败，请重试');
        }
        this.setState({
          DeleteLoading: false,
        })

      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
  PermissionsRef = (ref) => {
    this.childPermissionsRef = ref
  }

  PermissionsUserSave() {
    this.setState({
      PermissionsLoading: true,
    })
    if (this.childPermissionsRef.state.Type == 1) {
      service.request({
        url: "/admin/user/updateViewPhasePermission",
        method: "put",
        data: {
          ViewPhase: this.childPermissionsRef.state.PermissionsList,
          ID: this.childPermissionsRef.state.ID,
        }
      }).then(res => {
        if (res.code == 200) {
          this.getUserList(this.state.page)
          this.setState({
            PermissionsLoading: false,
            PermissionsVisible: false,
          })
          message.success("修改成功")
        } else {
          this.setState({
            PermissionsLoading: false,
          })
          message.error("系统繁忙！")
        }
      }).catch(function (error) {
        message.error("系统繁忙！")
        console.log(error)
      })
    } else {
      service.request({
        url: "/admin/user/updateDownloadPhasePermission",
        method: "put",
        data: {
          DownloadPhase: this.childPermissionsRef.state.PermissionsList,
          ID: this.childPermissionsRef.state.ID,
        }
      }).then(res => {
        if (res.code == 200) {
          this.getUserList(this.state.page)
          this.setState({
            PermissionsLoading: false,
            PermissionsVisible: false,
          })
          message.success("修改成功")
        } else {
          this.setState({
            PermissionsLoading: false,
          })
          message.error("系统繁忙！")
        }
      }).catch(function (error) {
        message.error("系统繁忙！")
        console.log(error)
      })
    }
  }
  userIPRefRef = (ref) => {
    this.childUserIPRef = ref
  }
  setIsDisabled(item) {
    service.request({
      url: "/admin/user/updateIsDisabled",
      method: "put",
      data: {
        ID: item.ID,
        IsDisabled: item.IsDisabled,
      },
    }).then(res => {
      if (res.code == 200) {
        this.getUserList(this.state.page)
        message.success('修改成功');

      } else {
        message.error('修改失败,请重试');
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
}
