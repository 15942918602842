import React from 'react';
import { Input, Button, Space, Upload, Select, message ,Switch} from 'antd';
import service from "../../../request"
const { Option } = Select;
class AddVideo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            Vedio:"",
            fileList: [],
            Header: "https://api2.yuexiaojing.com/v3/",
            form: null,
            tarinList:[],
            TrainID:0,
            IsDownload:1,
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>课程：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="课程"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.TrainID}
                                onChange={(value) => {
                                    this.setState({ TrainID: value, })
                                }}
                            >
                                <Option key={0} value={0} >全部</Option>
                                {this.state.tarinList.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>视频名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.setState({
                                    Name: e.target.value,
                                })
                            }} />
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>视频：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadVideo(f) }} fileList={this.state.VideoFileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传视频</Button>
                            </Upload>
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>视频封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadCover(f) }} fileList={this.state.CoverFileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传封面</Button>
                            </Upload>
                        </Space>
                        <Space align="center" >
                                <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>允许下载</div>
                                <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.IsDownload == 1 ? true : false} onChange={
                                    () => { this.setState({ IsDownload: this.state.IsDownload == 1 ? 2 : 1 }) }} />
                            </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.paging()
        var form = new FormData()
        form.set("Type", this.state.type)
    }
    paging() {
        service.request({
            url: "/admin/train",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
                category: 1,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error("数据加载失败，请重试！")
            } else {
                this.setState({ tarinList: res.data, })
            }
        }).catch(function (error) {
            message.error("系统繁忙！")
            console.log(error)
        })
    }
    UploadVideo(f){
        this.setState({ Video: f.file, VideoFleList: f.fileList }) 
    }
    UploadCover(f) {
        this.setState({ Cover: f.file, CoverFileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default AddVideo 