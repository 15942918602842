import React from 'react';
import { Input, Button, Space, Upload,  Select, message } from 'antd';
import service from "../../../request"
const { Option } = Select;
class AddCourse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            fileList: [],
            ActivityID:0,
            Header: "https://api2.yuexiaojing.com/v3/",
            ActivityList:[],
            form: null,
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                         <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>活动：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="活动"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.ActivityID}
                                onChange={(value) => {
                                    this.setState({ ActivityID: value, })
                                }}
                            >
                                <Option key={0} value={0} >全部</Option>
                                {this.state.ActivityList.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>课程名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.setState({
                                    Name: e.target.value,
                                })
                            }} />
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传</Button>
                            </Upload>
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.paging()
    }
    paging() {
        service.request({
            url: "/admin/activity",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
                category: 2,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error("数据加载失败，请重试！")
            } else {
                this.setState({ ActivityList: res.data, })
            }
        }).catch(function (error) {
            message.error("系统繁忙！")
            console.log(error)
        })
    }
    Upload(f) {
        // var fileList = option.fileList
        // const reader = new FileReader();
        // reader.readAsDataURL(option.file);
        // reader.onloadend = () => {
        //     this.setState({
        //         Cover: reader.result,
        //         fileList: fileList,
        //     })
        // }
        this.setState({ Cover: f.file, fileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default AddCourse 