
import React from 'react';
import { Input, Tabs, Select, Upload, Space, Button, message } from 'antd';
// import { Dropdown, } from "@cs/react"
import service from "../../../../request"
import moment from 'moment';

import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select;
const { TabPane } = Tabs;
class EditorClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            ID: 0,
            fileList: [],
            Header: "https://api2.yuexiaojing.com/v3/",
            
        };
    }
    // 抽屉
    render() {
        return (
            <div style={{ width: "100%", }}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        display: 'flex',
                    }}
                >
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>名称</div>
                        <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                            this.setState({ Name: e.target.value, })
                        }} placeholder="名称" />
                    </Space>
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>封面</div>
                        <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.ImgUpload(f) }} fileList={this.state.fileList}>
                            <Button icon={<UploadOutlined />}>上传</Button>
                        </Upload>
                    </Space>
                </Space>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            ID:this.props.ClassItem.ID,
            Name: this.props.ClassItem.Name,
            Cover: this.props.ClassItem.Cover,
        },()=>{
                var Covers= this.state.Cover.split("/")
                var Coverslength=Covers.length-1
                var CoversItem=Covers[Coverslength]
                 this.setState({
                    fileList:[{
                         uid: '-1',
                         name:CoversItem,
                         status: 'done',
                         url:this.state.Header+this.state.Cover,
                         thumbUrl: this.state.Header+this.state.Cover,
                       }]
                 })
        })
    }
    ImgUpload(f) {
        this.setState({ Cover: f.file, fileList: f.fileList })
     
    }
    beforeUpload() {
        return false
    }
}

export default EditorClass;