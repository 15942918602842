import React from 'react';
import { Input, Button, Switch, Space, DatePicker, Select, message } from 'antd';

import 'dayjs/locale/zh-cn';
const { Option } = Select;
const { TextArea } = Input;
class AddUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            IsDisabled: false,
            Type: 1,
            ID: 0,
            TypeList: [{ Name: "常规课程", ID: 1, Type: 1, }, { Name: "假期课程", ID: 2, Type: 2, }, { Name: "工具课程", ID: 3, Type: 3, }, { Name: "特色课程", ID: 4, Type: 4, },],
        };
    }
    render() {
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>模块：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder=""
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.Type}
                                onChange={(value) => {
                                    this.setState({ Type: value, })
                                }}
                            >
                                {this.state.TypeList.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>阶段名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>状态：</div>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.IsDisabled} onChange={
                                () => { this.setState({ IsDisabled: !this.state.IsDisabled }) }} />
                        </Space>

                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            Name: this.props.PhaseItem.Name,
            IsDisabled: !this.props.PhaseItem.IsDisabled,
            Type: this.props.PhaseItem.PlateID,
            ID: this.props.PhaseItem.ID,
            TypeList: this.props.TypeList,
        })
    }

}
export default AddUser;