import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Switch, Modal, Space, Input } from 'antd';
import moment from 'moment/moment';
import OrderPost from "../shoppingCenter/orderManage_Page/orderPost"
import OrderDetails from "../shoppingCenter/orderManage_Page/orderDetails"



const { Search } = Input;
export default class OrderManage extends Component {
  constructor(props) {
    super(props)
    this.childOrderPostRef = null
    this.childOrderDetailsRef = null
    this.state = {
      OrderList: [],
      SchoolList: [],
      offset: 0,
      count: 0,
      page: 1,
      TableItem: {},
      IsGrounding: 1,
      searchValue: "",
     
      EditorVisible: false,
      OrderPostLoading: false,
      DetailsVisible: false,
      ItemID: 0,
      StateType: 0,
      StateList: [{ Name: "全部", ID: 0, }, { Name: "未支付", ID: 1, }, { Name: "未发货", ID: 2, }, { Name: "已完成", ID: 3, },],
      Header: "https://api2.yuexiaojing.com/v3/"
    }
  }
  render() {
    const columns = [
      { title: '商品名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '订单编号', dataIndex: 'Number', key: 'Number',
      },
      { title: '商品数量', dataIndex: 'Amount', key: 'Amount', },
      {
        title: '订单金额', dataIndex: 'Price', key: 'Price',
        render: (text, record, index) => {
          return <div>
           {record.Price/100}
          </div>
        }
      },
      {
        title: '商品来源', dataIndex: 'Price', key: 'Price',
        render: (text, record, index) => {
          return <div>
           {record.Category==1?"周边订购":"付费课程"}
          </div>
        }
      },
      // { title: '快递公司', dataIndex: 'MailingCompany', key: 'MailingCompany', },
      // { title: '快递单号', dataIndex: 'ExpressNumber', key: 'ExpressNumber', },
      {
        title: '状态', dataIndex: 'IsGrounding', key: 'IsGrounding',
        render: (text, record, index) => {
          return <div>
          {record.State==1?<div>未支付</div>:""}
          {record.State==2?<div>未发货</div>:""}
          {record.State==3?<div>已完成</div>:""}
          </div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            {record.State==2?<Button onClick={() => this.setState({ItemID:record.ID},()=>{this.setState({EditorVisible:true})})} style={{ color: "#0081FF",marginLeft:"20px" }} >邮寄</Button>:''}
            <Button onClick={() => this.setState({TableItem:record},()=>{this.setState({DetailsVisible:true})})} style={{ color: "#0081FF", }} >详情</Button>
            
          </div>
        }
      },
    ];
    const paginationProps = {
      current: this.state.page, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.count, // 总条数
      onChange: page => handlePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handlePageChange = (page) => {
      this.setState({ page: page })
      this.paging(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", position: "relative" }}>
          <div style={{ display: 'flex', position: "absolute", left: "0px", top: "10px" }}>
            {
              this.state.StateList.map((item, index) => {
                return <div style={{ display: "flex" }}>
                  <Button type="primary" style={{ background: this.state.StateType==item.ID?"#53bfe5":"#fff", border: "1px solid #ccc", color: this.state.StateType==item.ID?"#fff":"#000", marginLeft: "20px" }} onClick={() => this.setState({ StateType: item.ID },()=>{this.paging(this.state.page)})}>{item.Name}</Button> 
                </div>
              })
            }
          </div>
          <Search placeholder="搜索" style={{ width: "200px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} />
        </div>
        <Drawer
          open={this.state.EditorVisible}
          title="邮寄"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.OrderPostLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <OrderPost ItemID={this.state.ItemID} ref={this.OrderPostRef} ></OrderPost>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.OrderPostSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 详情 */}
        <Drawer
          open={this.state.DetailsVisible}
          title="订单详情"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ DetailsVisible: false })}
          destroyOnClose={true}
        >
            <OrderDetails TableItem={this.state.TableItem} ref={this.OrderDetailstRef} ></OrderDetails>
        </Drawer>
        <Table columns={columns}
          // rowSelection={rowSelection}
          dataSource={this.state.OrderList}
          className="table"
          bordered={true}
          pagination={paginationProps}
          rowKey={record => record.ID}
          size='small'
        />
      </div>
    )
  }
  componentDidMount() {
    this.paging(this.state.page)
  }
  onSearch() {
    this.paging(this.state.page)
  }
  paging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/order",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        name: this.state.searchValue,
        state: this.state.StateType
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ OrderList: res.data, count: res.count, page: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
 
  OrderPostRef = (ref) => {
    this.childOrderPostRef = ref
  }
  OrderPostSave() {
    if(this.childOrderPostRef.state.MailingCompan!=""&& this.childOrderPostRef.state.ExpressNumber!=""){
      this.setState({
        OrderPostLoading: true,
      })
      service.request({
        url: "/admin/order/sendGoods",
        method: "put",
        data: {
          MailingCompany: this.childOrderPostRef.state.MailingCompany,
          ExpressNumber: this.childOrderPostRef.state.ExpressNumber,
          ID:this.childOrderPostRef.state.ID,
        },
      }).then(res => {
        if (res.code == 200) {
          this.setState({
            OrderPostLoading: false,
            EditorVisible:false
          })
          this.paging(this.state.page)
          message.success('快递信息填写完成');
        } else {
          this.setState({
            OrderPostLoading: false,
          })
          message.error('请求失败，请重试');
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
}
