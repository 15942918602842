import React, { Component } from 'react'
import service from '../../request';
import {  Table, message, Input, } from 'antd';
import moment from 'moment/moment';


const { Search } = Input;
export default class UserIP extends Component {
    constructor(props) {
        super(props)

        this.state = {
            LoginInfos: [],

        }
    }
    render() {
        const columns = [
            { title: 'IP地址', dataIndex: 'IP', key: 'IP' },
            { title: '国家', dataIndex: 'CountryLong', key: 'CountryLong' },
            { title: '省', dataIndex: 'Region', key: 'Region' },
            { title: '市', dataIndex: 'City', key: 'City' },
            { title: '区', dataIndex: 'District', key: 'District' },
            { title: '首次登陆', dataIndex: 'FisrtTime', key: 'FisrtTime',
            render: (text, record, index) => {
                return <div>
                  { this.timeChange(record.FisrtTime) }
                </div>
              } },
            { title: '末次登录', dataIndex: 'LastTime', key: 'LastTime',
            render: (text, record, index) => {
                return <div>
                  { this.timeChange(record.LastTime) }
                </div>
              } 
             },
            { title: '登录时长', dataIndex: 'Duration', key: 'Duration',
            render: (text, record, index) => {
                return <div>
                  { this.formatSeconds(record.Duration*60) }
                  
                </div>
              }  },
        ];

        return (
            <div style={{ padding: "10px" }}>
                <Table columns={columns}
                    dataSource={this.state.LoginInfos}
                    className="table"
                    bordered={true}
                    rowKey={record => record.ID}
                    size='small'
                    pagination={false}
                    key={record => record.ID}
                />
            </div>
        )
    }
    componentDidMount() {
            this.setState({
                LoginInfos: this.props.TableItem.LoginInfos,
            })
    }
    getMenu() {
        service.request({
            url: "/admin/phase",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ PhaseList: res.data })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
    timeChange(time) {
        var date = time.substr(0, 10); //年月日
        var hours = time.substring(11, 13);
        var minutes = time.substring(14, 16);
        var seconds = time.substring(17, 19);
        var timeFlag = date + ' ' + hours + ':' + minutes + ':' + seconds;
        timeFlag = timeFlag.replace(/-/g, "/");
        timeFlag = new Date(timeFlag);
        // timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
        timeFlag = timeFlag.getFullYear() + '-' + ((timeFlag.getMonth() + 1) < 10 ? "0" + (timeFlag.getMonth() + 1) : (timeFlag.getMonth() + 1)) + '-' + (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) + ' ' + timeFlag.getHours() + ':' + timeFlag.getMinutes() + ':' + (timeFlag.getSeconds() < 10 ? "0" + timeFlag.getSeconds() : timeFlag.getSeconds());
        return timeFlag;
      };
      formatSeconds(value) {
        //  秒
        let second = parseInt(value)
        //  分
        let minute = 0
        //  小时
        let hour = 0
        //  天
         let day = 0
        //  如果秒数大于60，将秒数转换成整数
        if (second > 60) {
          //  获取分钟，除以60取整数，得到整数分钟
          minute = parseInt(second / 60)
          //  获取秒数，秒数取佘，得到整数秒数
          second = parseInt(second % 60)
          //  如果分钟大于60，将分钟转换成小时
          if (minute > 59) {
            //  获取小时，获取分钟除以60，得到整数小时
            hour = parseInt(minute / 60)
            //  获取小时后取佘的分，获取分钟除以60取佘的分
            minute = parseInt(minute % 60)
            //  如果小时大于24，将小时转换成天
             if (hour > 23) {
               //  获取天数，获取小时除以24，得到整天数
               day = parseInt(hour / 24)
               //  获取天数后取余的小时，获取小时除以24取余的小时
               hour = parseInt(hour % 24)
             }
          }
        }
      
        let result = '' 
        // + parseInt(second) + '秒'
        if (minute > 0) {
          result = '' + parseInt(minute) + '分' + result
        }
        if (hour > 0) {
          result = '' + parseInt(hour) + '小时' + result
        }
         if (day > 0) {
           result = '' + parseInt(day) + '天' + result
         }
        return result
      }

}
