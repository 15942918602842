import React from 'react';
import { Input, Button, Space, Upload, Select } from 'antd';
import service from "../../../request"

class AddCourse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            fileList: [],
            Header: "https://api2.yuexiaojing.com/v3/",
            form: null
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>课程名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.setState({
                                    Name: e.target.value,
                                })
                            }} />
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传</Button>
                            </Upload>
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        var form = new FormData()
        form.set("Category", 1)
        this.setState({
            form: form,
        })
    }
    Upload(f) {
        this.setState({ Cover: f.file, fileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default AddCourse 