import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Modal, Select, Space, Input } from 'antd';

import AddCourse from './trainingCourse_page/addCourse';
import EditorCourse from './trainingCourse_page/editorCourse';
import AddVideo from './trainingCourse_page/addVideo';
import EditorVideo from './trainingCourse_page/editorVideo';

const { Search } = Input;
export default class TrainingCourse extends Component {
  constructor(props) {
    super(props)
    this.childAddCourseRef = null
    this.childEditorCourseRef = null
    this.childAddVideoRef = null
    this.childEditorVideoRef = null
    this.state = {
      CourseList: [],
      VideoList: [],
      offset: 0,
      CourseCount: 0,
      CoursePage: 1,
      VideoCount: 0,
      VideoPage: 1,
      TableItem: {},
      TrainID: 0,
      RowIndex: 0,
      searchValue: "",
      AddCourseVisible: false,
      AddCourseLoading: false,
      EditorCourseVisible: false,
      EditorCourseLoading: false,
      DeleteCourseLoading: false,
      DeleteCourseVisible: false,

      AddVideoVisible: false,
      AddVideoLoading: false,
      EditorVideoVisible: false,
      EditorVideoLoading: false,
      DeleteVideoLoading: false,
      DeleteVideoVisible: false,
      Header: "https://api2.yuexiaojing.com/v3/"
    }
  }
  render() {
    const CourseColumns = [
      { title: '课程名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '封面', dataIndex: 'Phone', key: 'Phone',
        render: (text, record, index) => {
          return <div><img style={{ width: "50px", height: "50px" }} src={this.state.Header + record.Cover} /></div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorCourseDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
            <Button onClick={() => this.deleteCourseModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    const VideoColumns = [
      { title: '视频名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '封面', dataIndex: 'Phone', key: 'Phone',
        render: (text, record, index) => {
          return <div><img style={{ width: "50px", height: "50px" }} src={this.state.Header + record.Cover} /></div>
        }
      },
      {
        title: '下载', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
          return <div >
            {record.IsDownload == 1 ? "允许" : "不允许"}
          </div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorVideoDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
            <Button onClick={() => this.deleteVideoModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    const paginationProps_Course = {
      current: this.state.CoursePage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.CourseCount, // 总条数
      onChange: page => handleCoursePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleCoursePageChange = (page) => {
      this.setState({ CoursePage: page })
      this.CoursePaging(page)
    }
    const paginationProps_Video = {
      current: this.state.VideoPage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.VideoCount, // 总条数
      onChange: page => handleVideoPageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleVideoPageChange = (page) => {
      this.setState({ VideoPage: page })
      this.VideoPaging(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          {/* <Search placeholder="搜索" style={{ width: "200px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} /> */}
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddCourseVisible: true })}>添加课程</Button>
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", marginLeft: "20px" }} onClick={() => this.setState({ AddVideoVisible: true })}>添加视频</Button>
        </div>
        {/* 添加课程 */}
        <Drawer
          open={this.state.AddCourseVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ AddCourseVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.AddCourseLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <AddCourse ref={this.addCourseRef} ></AddCourse>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.AddCourseSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ AddCourseVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 修改课程 */}
        <Drawer
          open={this.state.EditorCourseVisible}
          title="修改"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorCourseVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.EditorCourseLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <EditorCourse TableItem={this.state.TableItem} ref={this.editorCourseRef} ></EditorCourse>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditorCourseSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorCourseVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 删除课程 */}
        <Spin spinning={this.state.DeleteCourseLoading} tip="加载中......" size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            title={null}
            footer={null}
            modalRender={(modal) => (
              modal
            )}
            // bodyStyle={{ padding: '0px' }}
            open={this.state.DeleteCourseVisible}
            onCancel={() => this.setState({ DeleteCourseVisible: false })}
            closable={true}
            width={400}
          >
            <div style={{ heighe: "205px", padding: "10px 20px" }}>
              <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
              <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.VerifyDeleteCourse()}>确认</Button>
                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteCourseVisible: false }) }}>取消</Button>
              </div>
            </div>
          </Modal>
        </Spin>
        {/* 添加视频 */}
        <Drawer
          open={this.state.AddVideoVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ AddVideoVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.AddVideoLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <AddVideo ref={this.addVideoRef} ></AddVideo>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.AddVideorSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ AddVideoVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 修改视频 */}
        <Drawer
          open={this.state.EditorVideoVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorVideoVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.EditorVideoLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <EditorVideo TableItem={this.state.TableItem} ref={this.editorVideoRef} ></EditorVideo>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditorVideorSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorVideoVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 删除视频 */}
        <Spin spinning={this.state.DeleteVideoLoading} tip="加载中......" size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            title={null}
            footer={null}
            modalRender={(modal) => (
              modal
            )}
            // bodyStyle={{ padding: '0px' }}
            open={this.state.DeleteVideoVisible}
            onCancel={() => this.setState({ DeleteVideoVisible: false })}
            closable={true}
            width={400}
          >
            <div style={{ heighe: "205px", padding: "10px 20px" }}>
              <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
              <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.VerifyDeleteVideo()}>确认</Button>
                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteVideoVisible: false }) }}>取消</Button>
              </div>
            </div>
          </Modal>
        </Spin>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "43" }}>
            <Table columns={CourseColumns}
              // rowSelection={rowSelection}
              onRow={(record, index) => {
                return {
                  onClick: event => { this.ToggleCouser(record, index) }, // 点击行
                }
              }}
              rowClassName={this.rowClassNameFun}
              dataSource={this.state.CourseList}
              className="table"
              bordered={true}
              pagination={paginationProps_Course}
              rowKey={record => record.ID}
              size='small'
            />
          </div>
          <div style={{ flex: "2" }}></div>
          <div style={{ flex: "55" }}>
            <Table columns={VideoColumns}
              // rowSelection={rowSelection}
              dataSource={this.state.VideoList}
              className="table"
              bordered={true}
              pagination={paginationProps_Video}
              rowKey={record => record.ID}
              size='small'
            />
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    this.CoursePaging(this.state.CoursePage)
  }
  rowClassNameFun = (record, index) => {
    if (index === this.state.RowIndex)
      return 'mySelfClassName'
  }
  ToggleCouser(record, index) {
    this.setState({
      RowIndex: index,
      TrainID: record.ID,
      VideoPage: 1,
    }, () => { this.VideoPaging(this.state.VideoPage) })
  }
  // 查课程
  CoursePaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/train",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        category: 1,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        // this.setState({ CourseList: res.data, CourseCount: res.count, CoursePage: page, TrainID: res.data.length != 0 ? res.data[0].ID : 0 }, () => {
        this.setState({ CourseList: res.data, CourseCount: res.count, CoursePage: page, TrainID: this.state.TrainID != 0 ? this.state.TrainID : (res.data.length != 0 ? res.data[0].ID : 0) }, () => {
          if (this.state.TrainID != 0) {
            this.VideoPaging(this.state.VideoPage)
          } else {
            this.setState({
              VideoList: []
            })
          }
        })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  // 查视频
  VideoPaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/file",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        trainID: this.state.TrainID,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ VideoList: res.data, VideoCount: res.count, VideoPage: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  // 添加封面课程
  addCourseRef = (ref) => {
    this.childAddCourseRef = ref
  }
  AddCourseSave() {
    var form = new FormData()
    form.set("Category", 1)
    if (this.childAddCourseRef.state.Name != "" && this.childAddCourseRef.state.Cover != "") {
      form.set("Name", this.childAddCourseRef.state.Name)
      form.set("Cover", this.childAddCourseRef.state.Cover)
      this.setState({
        AddCourseLoading: true,
      })
      service.request({
        url: "/admin/train",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddCourseLoading: false,
            AddCourseVisible: false
          })
          this.CoursePaging(this.state.CoursePage)
          message.success("添加成功")
        } else {
          this.setState({
            AddCourseLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("添加失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        AddCourseLoading: false,
      })
      message.error('输入不能为空');
    }
  }

  // 修改课程
  editorCourseDrawer(item) {
    this.setState({
      EditorCourseVisible: true,
      TableItem: item,
    })
  }
  editorCourseRef = (ref) => {
    this.childEditorCourseRef = ref
  }
  EditorCourseSave() {
    var form = new FormData()
    form.set("Category", 1)
    if (this.childEditorCourseRef.state.Name != "" && this.childEditorCourseRef.state.Cover != "") {
      form.set("ID", this.childEditorCourseRef.state.ID)
      form.set("Name", this.childEditorCourseRef.state.Name)
      form.set("Cover", this.childEditorCourseRef.state.Cover)
      form.set("Path", this.childEditorCourseRef.state.Path)
      this.setState({
        EditorCourseLoading: true,
      })
      service.request({
        url: "/admin/train",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            EditorCourseLoading: false,
            EditorCourseVisible: false
          })
          this.CoursePaging(this.state.CoursePage)
          message.success("修改成功")
        } else {
          this.setState({
            EditorCourseLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("修改失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        EditorCourseLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  // 删除课程
  deleteCourseModal(record) {
    this.setState({
      TableItem: record,
      DeleteCourseVisible: true,
    })
  }
  VerifyDeleteCourse() {
    this.setState({ DeleteCourseLoading: true })
    service.request({
      url: "/admin/train",
      method: "delete",
      data: {
        ID: this.state.TableItem.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.CoursePaging(this.state.CoursePage)
        this.setState({ DeleteCourseLoading: false, DeleteCourseVisible: false })
        message.success('删除成功');
      } else {
        this.setState({ DeleteCourseLoading: false, })
        if (res.code == 451) {
          message.error('该课程下面有资源，无法删除');
        }
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
  // 添加视频
  addVideoRef = (ref) => {
    this.childAddVideoRef = ref
  }
  AddVideorSave() {
    var form = new FormData()
    form.set("Type", 1)
    if (this.childAddVideoRef.state.Name != "" && this.childAddVideoRef.state.Cover != "" && this.childAddVideoRef.state.Video != "") {
      form.set("TrainID", this.childAddVideoRef.state.TrainID)
      form.set("Name", this.childAddVideoRef.state.Name)
      form.set("Cover", this.childAddVideoRef.state.Cover)
      form.set("File", this.childAddVideoRef.state.Video)
      form.set("IsDownload", this.childAddVideoRef.state.IsDownload)
      this.setState({
        AddVideoLoading: true,
      })
      service.request({
        url: "/admin/file",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddVideoLoading: false,
            AddVideoVisible: false
          })
          this.CoursePaging(this.state.CoursePage)
          message.success("添加成功")
        } else {
          this.setState({
            AddVideoLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else if (res.code == 401) {
            message.error("添加失败，请选择课程")
          }
          else {
            message.error("添加失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        AddCourseLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  // 修改视频
  editorVideoDrawer(item) {
    this.setState({
      EditorVideoVisible: true,
      TableItem: item,
    })
  }
  editorVideoRef = (ref) => {
    this.childEditorVideoRef = ref
  }
  EditorVideorSave() {
    var form = new FormData()
    form.set("Type", 1)
    if (this.childEditorVideoRef.state.Name != "" && this.childEditorVideoRef.state.Cover != "" && this.childEditorVideoRef.state.Video != "") {
      form.set("ID", this.childEditorVideoRef.state.ID)
      form.set("TrainID", this.childEditorVideoRef.state.TrainID)
      form.set("Name", this.childEditorVideoRef.state.Name)
      form.set("Cover", this.childEditorVideoRef.state.Cover)
      form.set("File", this.childEditorVideoRef.state.Video)
      form.set("IsDownload", this.childEditorVideoRef.state.IsDownload)
      this.setState({
        EditorVideoLoading: true,
      })
      service.request({
        url: "/admin/file",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            EditorVideoLoading: false,
            EditorVideoVisible: false
          })
          this.CoursePaging(this.state.CoursePage)
          message.success("修改成功")
        } else {
          this.setState({
            EditorVideoLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("修改失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        EditorCourseLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  deleteVideoModal(record) {
    this.setState({
      TableItem: record,
      DeleteVideoVisible: true,
    })
  }
  VerifyDeleteVideo() {
    this.setState({ DeleteVideoLoading: true })
    service.request({
      url: "/admin/file",
      method: "delete",
      data: {
        ID: this.state.TableItem.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.VideoPaging(this.state.VideoPage)
        this.setState({ DeleteVideoLoading: false, DeleteVideoVisible: false })
        message.success('删除成功');
      } else {
        this.setState({ DeleteVideoLoading: false, })

        message.error('删除失败，请重试');
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
}
