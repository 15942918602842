import React from 'react';
import { Input, Button, Switch, Space, Upload, Select, message } from 'antd';
import service from '../../../request';
import { FileOutlined } from '@ant-design/icons'
const { Option } = Select;
const { TextArea } = Input;
class EditorCommodity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            IsGrounding: 1,
            CoverFileList: [],
            Cover: "",
            Note: '',
            PhaseID: 0,
            PhaseList:[],
            Price: null,
            Header: "https://api2.yuexiaojing.com/v3/",
        };
    }
    render() {
       
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>商品名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} />
                        </Space>
                      
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>商品封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadCover(f) }} fileList={this.state.CoverFileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传封面</Button>
                            </Upload>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>商品状态：</div>
                            <Switch checkedChildren="上架" unCheckedChildren="下架" checked={this.state.IsGrounding == 1 ? true : false} onChange={
                                () => {
                                    if (this.state.IsGrounding == 1) {
                                        this.setState({ IsGrounding: 2 })
                                    } else {
                                        this.setState({ IsGrounding: 1 })
                                    }
                                }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>商品简介：</div>
                            <TextArea
                                style={{ width: "300px", }}
                                value={this.state.Note}
                                onChange={(e) => { // this.state.formdata.set("Note", e.target.value,)
                                    this.setState({ Note: e.target.value, })
                                }}
                                placeholder="简介"
                                autoSize={{
                                    minRows: 3,
                                    // maxRows: 5,
                                }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>商品价格：</div>
                            <Input style={{ width: "100px", }} value={this.state.Price} onChange={(e) => { 
                                  var reg = /^\d+(\.\d{0,2})?$/;//正则表达式
                                  var isNumber = new RegExp(reg)
                                  if (isNumber.test(e.target.value)) {
                                   this.setState({Price:e.target.value})
                                  }
                                }} />
                            <div style={{ width: "100px", textAlign: 'left' }}>元</div>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>阶段：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="课程"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.PhaseID}
                                onChange={(value) => {
                                    this.setState({
                                        PhaseID:value,
                                    })
                                }}
                            >
                                <Option key={0} value={0} >全部</Option>
                                {this.state.PhaseList.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.getPhase()
        this.setState({
            Name: this.props.TableItem.Name,
            ID: this.props.TableItem.ID,
            IsGrounding:  this.props.TableItem.IsGrounding,
            Cover:  this.props.TableItem.Cover,
            Note:this.props.TableItem.Note,
            PhaseID: this.props.TableItem.CourseID,
            Price:this.props.TableItem.Price/100,
            Type:this.props.TableItem.Type,
        }, () => {
            var Covers = this.state.Cover.split("/")
            var Coverslength = Covers.length - 1
            var CoversItem = Covers[Coverslength]
            this.setState({
                CoverFileList: [{
                    uid: '-1',
                    name: CoversItem,
                    status: 'done',
                    url: this.state.Header + this.state.Cover,
                    thumbUrl: this.state.Header + this.state.Cover,
                }],
            })
        })
    }
    // 文件封面
    UploadCover(f) {
        this.setState({ Cover: f.file, CoverFileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
    getPhase(){
        service.request({
            url: "/admin/phase",
            method: "get",
            params: {
              limit: 0,
              offset: 0,
            }
          }).then(res => {
            if (res.code != 200) {
              message.error('数据加载失败，请重试');
            } else {
              this.setState({ PhaseList: res.data,  })
            }
          }).catch(function (error) {
            message.error('系统繁忙');
          })
    }
}
export default EditorCommodity;