import React from 'react';
import { Input, message, Space, DatePicker, Select } from 'antd';
import service from "../../request"
import cookie from "react-cookies";
import 'moment/locale/zh-cn';

import 'dayjs/locale/zh-cn';
const { Option } = Select;
const { TextDistrict } = Input;
class EditorSchool extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            ID: 0,
            Phone: '',
            Address: "",
            UserID: 0,
            City: "",
            CityCode: 0,
            District: "",
            DistrictCode: 0,
            Province: "",
            ProvinceCode: 0,
            ParentCode: 0,
            ProvinceList: [],
            CityList: [],
            DistrictList: [],
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>学校名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>联系电话：</div>
                            <Input style={{ width: "300px", }} value={this.state.Phone} onChange={(e) => { this.setState({ Phone: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>省：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="省"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.Province}
                                onChange={(text,record,) => {
                                    this.setState({ ParentCode: record.value, ProvinceCode: record.value,Province: record.children, }, () => { this.selectCity() })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.ProvinceList.map((item, index) => (
                                    <Option key={item.ID} value={item.Code} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>市：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="市"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.City}
                                onChange={(text,record,) => {
                                    this.setState({ CityCode: record.value, ParentCode:  record.value,City: record.children, }, () => { this.selectDistrict() })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.CityList.map((item, index) => (
                                    <Option key={item.ID} value={item.Code} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>区：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="区"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.District}
                                onChange={(text,record,) => {
                                    this.setState({ DistrictCode: record.value,District: record.children, }, () => { })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.DistrictList.map((item, index) => (
                                    <Option key={item.ID} value={item.Code} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>详细地址：</div>
                            <Input style={{ width: "300px", }} value={this.state.Address} onChange={(e) => { this.setState({ Address: e.target.value }) }} />
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            ID: this.props.TableItem.ID,
            Name: this.props.TableItem.Name,
            Phone: this.props.TableItem.Phone,
            Address: this.props.TableItem.Address,
            Users: this.props.TableItem.Users,
            City: this.props.TableItem.City,
            CityCode: this.props.TableItem.CityCode,
            District: this.props.TableItem.District,
            DistrictCode: this.props.TableItem.DistrictCode,
            Province: this.props.TableItem.Province,
            ProvinceCode: this.props.TableItem.ProvinceCode,
        })
        this.getProvince()
    }
    getProvince() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ ProvinceList: res.data, ProvinceCode: res.data[0].Code, ParentCode: res.data[0].Code, }, () => { this.getCity() })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getCity() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ CityList: res.data, }, () => {
                    this.getDistrict()
                })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getDistrict() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ DistrictList: res.data, })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    selectCity() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {

            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ CityList: res.data, CityCode: res.data[0].Code, City: res.data[0].Name, ParentCode: res.data[0].Code, }, () => {
                    this.selectDistrict()
                })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    selectDistrict() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ DistrictList: res.data, District: res.data[0].Name, DistrictCode: res.data[0].Code, })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
}
export default EditorSchool;