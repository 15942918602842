import React, { Component } from 'react'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import cookie from "react-cookies";
import axios from 'axios';
// import service from "../request"
// import moment from "moment";
//
export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Data: [],
      Password: "",
      Username: "",
    }
  }
  render() {
    return (
      <div className='loginContent' style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
        <div style={{ width: "400px", height: "300px", background: "#fff", padding: "20px", borderRadius: "6px", boxSizing: "border-box" }}>
          <div style={{ textAlign: "center", height: "50px", lineHeight: "50px" }}>悦小鲸管理系统</div>
          <Input placeholder="用户名" prefix={<UserOutlined />} value={this.state.Username} onChange={(e) => { this.setState({ Username: e.target.value, }) }} style={{ height: "40px", marginTop: "20px" }} />
          <Input type="password" placeholder="密码" prefix={<UnlockOutlined />} value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value, }) }} style={{ height: "40px", marginTop: "20px" }} />
          <Button onClick={() => { this.onlogin() }} type="primary" style={{ width: "360px", height: "40px", marginTop: "20px" }}>登录</Button>
        </div>
      </div>
    )
  }
  componentDidMount() {
  }
  onlogin() {
    if (this.state.Username == '' || this.state.Password == '') {
      message.error('用户名密码不能为空');
    } else {
      axios.post('https://api2.yuexiaojing.com/v3/admin/login', {
      // axios.post('http://127.0.0.1:8097/admin/login', {
        Username: this.state.Username,
        Password: this.state.Password
      }).then(res => {
        if (res.data.code != 200) {
          message.error('用户名密码错误');
        } else {
          let inFifteenMinutes = new Date(new Date().getTime() + 10 * 3600 * 1000);
          // console.log(inFifteenMinutes)
          cookie.save("admintoken", res.data.data.Token, { expires: inFifteenMinutes })
          // cookie.save("permissions", res.data.data.User.Role.Permissions, { expires: inFifteenMinutes })
          cookie.save("user", res.data.data.User, { expires: inFifteenMinutes })
          // console.log( this.props.history)
          this.props.history.push('/admin/tissue/user')
          this.setState({
            Username: "",
            Password: "",
          })
        }
      }).catch(function (error) {
        console.log("catch", error);
      });
    }
  }

}
