import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../../request';
import { Button, Table, Spin, Drawer, message, Modal, Radio, Space, Input } from 'antd';

import AddFile from './addFile';
import EditorFile from './editorFile';

const { Search } = Input;
export default class FileIndex extends Component {
    constructor(props) {
        super(props)

        this.childAddFileRef = null
        this.childEditorFileRef = null
        this.state = {

            FileList: [],
            offset: 0,

            FileCount: 0,
            FilePage: 1,

            FileItem: {},

            TrainID: 0,
            RowIndex: 0,
            searchValue: "",

            AddFileVisible: false,
            AddFileLoading: false,
            EditorFileVisible: false,
            EditorFileLoading: false,
            DeleteFileLoading: false,
            DeleteFileVisible: false,

            Header: "https://api2.yuexiaojing.com/v3/"
        }
    }
    render() {

        const FileColumns = [
            { title: '视频名称', dataIndex: 'Name', key: 'Name' },
            {
                title: '封面', dataIndex: 'Phone', key: 'Phone',
                render: (text, record, index) => {
                    return <div><img style={{ width: "50px", height: "50px" }} src={record.Cover?this.state.Header + record.Cover:this.state.Header +record.Path} /></div>
                }
            },
            {
                title: '下载', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
                    return <div >
                        {record.IsDownload==1?"允许":"不允许"}
                    </div>
                }
            },
            {
                title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
                    return <div>
                        <Button onClick={() => this.editorFileDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
                        <Button onClick={() => this.deleteFileModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
                    </div>
                }
            },
        ];

        const paginationProps_File = {
            current: this.state.page, //当前页码
            pageSize: 10, // 每页数据条数
            showTotal: (total) => (
                <span>共{total}条</span>
            ),
            total: this.state.FileCount, // 总条数
            onChange: page => handleFilePageChange(page), //改变页码的函数
            hideOnSinglePage: false,
            showSizeChanger: false,
        }
        const handleFilePageChange = (page) => {
            this.setState({ FilePage: page })
            this.FilePaging(page)
        }
        return (
            <div style={{ padding: "10px" }}>
                <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>

                    <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", marginLeft: "20px" }} onClick={() => this.setState({ AddFileVisible: true })}>添加文件</Button>
                </div>
                {/* 添加视频 */}
                <Drawer
                    open={this.state.AddFileVisible}
                    title="添加"
                    width='500px'
                    keyboard={true}//Esc关闭
                    onClose={() => this.setState({ AddFileVisible: false })}
                    destroyOnClose={true}
                >
                    <Spin spinning={this.state.AddFileLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
                        <AddFile TrainID={this.state.TrainID}  ref={this.addFileRef} ></AddFile>
                    </Spin>
                    <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
                        <Space>
                            <Button onClick={() => this.AddFilerSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                            <Button onClick={() => this.setState({ AddFileVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
                        </Space>
                    </div>
                </Drawer>
                {/* 修改视频 */}
                <Drawer
                    open={this.state.EditorFileVisible}
                    title="修改"
                    width='500px'
                    keyboard={true}//Esc关闭
                    onClose={() => this.setState({ EditorFileVisible: false })}
                    destroyOnClose={true}
                >
                    <Spin spinning={this.state.EditorFileLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
                        <EditorFile FileItem={this.state.FileItem} ref={this.editorFileRef} ></EditorFile>
                    </Spin>
                    <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
                        <Space>
                            <Button onClick={() => this.EditorFilerSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                            <Button onClick={() => this.setState({ EditorFileVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
                        </Space>
                    </div>
                </Drawer>
                {/* 删除文件 */}
                <Spin spinning={this.state.DeleteFileLoading} tip="加载中......" size="large">
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.DeleteFileVisible}
                        onCancel={() => this.setState({ DeleteFileVisible: false })}
                        closable={true}
                        width={400}
                    >
                        <div style={{ heighe: "205px", padding: "10px 20px" }}>
                            <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                            <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.VerifyDeleteFile()}>确认</Button>
                                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteFileVisible: false }) }}>取消</Button>
                            </div>
                        </div>
                    </Modal>
                </Spin>
                {/* 选类型 */}
                {/* <Modal
                    destroyOnClose={true}
                    centered={true}
                    title={null}
                    footer={null}
                    modalRender={(modal) => (
                        modal
                    )}
                    // bodyStyle={{ padding: '0px' }}
                    open={this.state.TypeVisible}
                    onCancel={() => { this.setState({ TypeVisible: false }) }}
                    closable={true}
                    width={500}
                >
                    <div style={{ height: "200px", padding: "10px 20px" }}>
                        <div style={{ fontSize: "20px", marginBottom: "50px" }}>请选择上传文件的类型</div>
                        <Radio.Group buttonStyle="solid">
                            {
                                this.state.TypeList.map((item, index) => {
                                    return <Radio.Button onChange={() => this.addFile(item)} value={item.ID} style={{ marginLeft: "27px" }} key={item.ID}>{item.Name}</Radio.Button>
                                })
                            }
                        </Radio.Group>
                    </div>
                </Modal> */}
                <div style={{}}>
                    <Table columns={FileColumns}
                        // rowSelection={rowSelection}
                        dataSource={this.state.FileList}
                        className="table"
                        bordered={true}
                        pagination={paginationProps_File}
                        rowKey={record => record.ID}
                        size='small'
                    />
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.setState({
            TrainID: this.props.TableItem.ID
        },()=>{
            this.FilePaging(1)
        })
    }
    // 查文件
    FilePaging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            url: "/admin/file",
            method: "get",
            params: {
                limit: 10,
                offset: newoffset,
                trainID: this.state.TrainID,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ FileList: res.data, FileCount: res.count, FilePage: page, })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }

    // 添加视频
    addFileRef = (ref) => {
        this.childAddFileRef = ref
    }
    addFile(item) {
        this.setState({
            TypeID: item.ID,
            TypeVisible: false,
            AddFileVisible: true,
        })
    }
    AddFilerSave() {
        var form=new FormData
        if (this.childAddFileRef.state.Name != "" && this.childAddFileRef.state.Type == 3 ? this.childAddFileRef.state.Cover != "" : true && this.childAddFileRef.state.File != "") {
            form.set("Name", this.childAddFileRef.state.Name)
            form.set("Cover", this.childAddFileRef.state.Cover)
            form.set("File", this.childAddFileRef.state.File)
            form.set("TrainID", this.childAddFileRef.state.TrainID)
            form.set("IsDownload", this.childAddFileRef.state.IsDownload)
            this.setState({
                AddFileLoading: true,
            })
            service.request({
                url: "/admin/file",
                method: "post",
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: form,

            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        AddFileLoading: false,
                        AddFileVisible: false
                    })
                    this.FilePaging(1)
                    message.success("添加成功")
                } else {
                    this.setState({
                        AddFileLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    }
                    else if (res.code == 401) {
                        message.error("添加失败，请选择课程")
                    }
                    else {
                        message.error("添加失败，请重试")
                    }
                }
            })
        } else {
            this.setState({
                AddCourseLoading: false,
            })
            message.error('输入不能为空');
        }
    }
    // 修改视频
    editorFileDrawer(item) {
        this.setState({
            EditorFileVisible: true,
            FileItem: item,
        })
    }
    editorFileRef = (ref) => {
        this.childEditorFileRef = ref
    }
    EditorFilerSave() {
        var form = new FormData()
        if (this.childEditorFileRef.state.Name != "" && this.childEditorFileRef.state.Cover != "" && this.childEditorFileRef.state.File != "") {
            form.set("ID", this.childEditorFileRef.state.ID)
            form.set("TrainID", this.childEditorFileRef.state.TrainID)
            form.set("Name", this.childEditorFileRef.state.Name)
            form.set("Cover", this.childEditorFileRef.state.Cover)
            form.set("File", this.childEditorFileRef.state.File)
            form.set("IsDownload", this.childEditorFileRef.state.IsDownload)
            this.setState({
                EditorFileLoading: true,
            })
            service.request({
                url: "/admin/file",
                method: "put",
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: form,

            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        EditorFileLoading: false,
                        EditorFileVisible: false
                    })
                    this.FilePaging(1)
                    message.success("修改成功")
                } else {
                    this.setState({
                        EditorFileLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    }
                    else {
                        message.error("修改失败，请重试")
                    }
                }
            })
        } else {
            this.setState({
                EditorCourseLoading: false,
            })
            message.error('输入不能为空');
        }
    }
    deleteFileModal(record) {
        this.setState({
            FileItem: record,
            DeleteFileVisible: true,
        })
    }
    VerifyDeleteFile() {
        this.setState({ DeleteFileLoading: true })
        service.request({
            url: "/admin/file",
            method: "delete",
            data: {
                ID: this.state.FileItem.ID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.FilePaging(1)
                this.setState({ DeleteFileLoading: false, DeleteFileVisible: false })
                message.success('删除成功');
            } else {
                this.setState({ DeleteFileLoading: false, })

                message.error('删除失败，请重试');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
}

