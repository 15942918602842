import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Switch, Radio, Space, Input, Tag } from 'antd';
const { Search } = Input;
export default class RemoteInfo extends Component {
  constructor(props) {
    super(props)
    this.childEditorLiveBroadcastRef = null
    this.state = {
      LiveList: [],
      offset: 0,
      count: 0,
      page: 1,
      TableItem: {},
    }
  }
  render() {
    const columns = [
      {
        title: '登录用户', dataIndex: 'NickName', key: 'NickName',
        render: (text, record, index) => {
          return <div>{record.User.NickName}</div>
        }
      },
      { title: '登录IP', dataIndex: 'IP', key: 'IP' },
      {
        title: '登录地址', dataIndex: 'Region', key: 'Region',
        render: (text, record, index) => {
          return <div>{record.Region + "-" + record.City + "-" + record.District}</div>
        }
      },
      {
        title: '登录时间', dataIndex: 'Time', key: 'Time',
        render: (text, record, index) => {
          return <div>{this.RFC3339(record.Time)}</div>
        }
      },
      {
        title: '学校所在地址', dataIndex: 'RawRegion', key: 'RawRegion',
        render: (text, record, index) => {
          return <div>{record.RawRegion + "-" + record.RawCity}</div>
        }
      },
      {
        title: '状态',
        key: 'State',
        dataIndex: 'State',
        render: (text, record, index) => {
          return <Tag color={record.State == 1 ? "rgb(195 195 195)" : "red"} key={index}>
            {record.State == 1 ? "已读" : "新"}
          </Tag>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return record.State == 1 ? '' : <div>
            < Button onClick={() => this.setRemoteState(record)
            } style={{ color: "#0081FF", }} > 设为已读</Button >
          </div >
        }
      },
    ];
    const paginationProps = {
      current: this.state.page, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.count, // 总条数
      onChange: page => handlePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handlePageChange = (page) => {
      this.setState({ page: page })
      this.getRemoteInfo(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          {/* <Search placeholder="搜索" style={{ width: "200px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} />
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddVisible: true })}>添加</Button> */}
        </div>

        <Table columns={columns}
          // rowSelection={rowSelection}
          dataSource={this.state.LiveList}
          className="table"
          bordered={true}
          pagination={paginationProps}
          rowKey={record => record.ID}
          size='small'
        />
      </div>
    )
  }
  componentDidMount() {
    this.getRemoteInfo(this.state.page)
  }

  RFC3339(dateStr) {
    var date = new Date(dateStr).toJSON();
    return new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
  }


  getRemoteInfo(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/user/queryRemoteInfo",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        name: this.state.searchValue,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ LiveList: res.data, count: res.count, page: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }

  setRemoteState(item) {
    service.request({
      url: "/admin/user/updateRemoteInfo",
      method: "put",
      data: {
        ID: item.ID,
      },
    }).then(res => {
      if (res.code == 200) {
        this.getRemoteInfo(this.state.page)
        message.success('修改成功');

      } else {
        message.error('修改失败,请重试');
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
}
