import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Modal, Radio, Space, Input } from 'antd';

import AddActivity from './activity_Page/addActivity';
import EditorActivity from './activity_Page/editorActivity';
import AddCourse from './activity_Page/addCourse';
import EditorCourse from './activity_Page/editorCourse';
import FileIndex from './activity_Page/file_Index';

const { Search } = Input;
export default class Activity extends Component {
  constructor(props) {
    super(props)
    this.childAddActivityRef = null
    this.childEditorActivityRef = null
    this.childAddCourseRef = null
    this.childEditorCourseRef = null
    this.childAddFileRef = null
    this.childEditorFileRef = null
    this.state = {
      ActivityList: [],
      CourseList: [],
      FileList: [],
      offset: 0,
      ActivityCount: 0,
      ActivityPage: 1,
      CourseCount: 0,
      CoursePage: 1,
      FileCount: 0,
      FilePage: 1,
      ActivityItem: {},
      TableItem: {},
      ActivityID: 0,
      TrainID: 0,
      RowIndex: 0,
      searchValue: "",
      AddActivityVisible: false,
      AddActivityLoading: false,
      EditorActivityVisible: false,
      EditorActivityLoading: false,
      DeleteActivityLoading: false,
      DeleteActivityVisible: false,

      AddCourseVisible: false,
      AddCourseLoading: false,
      EditorCourseVisible: false,
      EditorCourseLoading: false,
      DeleteCourseLoading: false,
      DeleteCourseVisible: false,

      FileVisible: false,

      TypeID: 0,
      TypeVisible: false,
      TypeList: [{ ID: 2, Name: "图片" }, { ID: 1, Name: "文件" }],
      Header: "https://api2.yuexiaojing.com/v3/"
    }
  }
  render() {
    const ActivityColumns = [
      { title: '活动名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '活动封面', dataIndex: 'Phone', key: 'Phone',
        render: (text, record, index) => {
          return <div><img style={{ width: "50px", height: "50px" }} src={this.state.Header + record.Cover} /></div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorActivityDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
            <Button onClick={() => this.deleteActivityModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    const CourseColumns = [
      { title: '课程名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '课程封面', dataIndex: 'Phone', key: 'Phone',
        render: (text, record, index) => {
          return <div><img style={{ width: "50px", height: "50px" }} src={this.state.Header + record.Cover} /></div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.FileDrawer(record)} style={{ color: "#0081FF", }} >文件</Button>
            <Button onClick={() => this.editorCourseDrawer(record)} style={{ color: "#0081FF",marginLeft: "20px" }} >编辑</Button>
            <Button onClick={() => this.deleteCourseModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];

    // 活动翻页
    const paginationProps_Activity = {
      current: this.state.ActivityPage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.ActivityCount, // 总条数
      onChange: page => handleActivityPageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleActivityPageChange = (page) => {
      this.setState({ ActivityPage: page })
      this.ActivityPaging(page)
    }
    // 课程翻页
    const paginationProps_Course = {
      current: this.state.CoursePage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.CourseCount, // 总条数
      onChange: page => handleCoursePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleCoursePageChange = (page) => {
      this.setState({ CoursePage: page })
      this.CoursePaging(page)
    }

    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddActivityVisible: true })}>添加活动</Button>
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", marginLeft: "20px" }} onClick={() => this.setState({ AddCourseVisible: true })}>添加课程</Button>
        </div>
        {/* 添加活动 */}
        <Drawer
          open={this.state.AddActivityVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ AddActivityVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.AddActivityLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <AddActivity ref={this.addActivityRef} ></AddActivity>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.AddActivitySave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ AddActivityVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 修改活动 */}
        <Drawer
          open={this.state.EditorActivityVisible}
          title="修改"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorActivityVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.EditorActivityLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <EditorActivity ActivityItem={this.state.ActivityItem} ref={this.editorActivityRef} ></EditorActivity>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditorActivitySave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorCourseVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
         {/* 删除活动 */}
         <Spin spinning={this.state.DeleteActivityLoading} tip="加载中......" size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            title={null}
            footer={null}
            modalRender={(modal) => (
              modal
            )}
            // bodyStyle={{ padding: '0px' }}
            open={this.state.DeleteActivityVisible}
            onCancel={() => this.setState({ DeleteActivityVisible: false })}
            closable={true}
            width={400}
          >
            <div style={{ heighe: "205px", padding: "10px 20px" }}>
              <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
              <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => { this.VerifyDeleteActivity() }}>确认</Button>
                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteActivityVisible: false }) }}>取消</Button>
              </div>
            </div>
          </Modal>
        </Spin>
        {/* 添加课程 */}
        <Drawer
          open={this.state.AddCourseVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ AddCourseVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.AddCourseLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <AddCourse ref={this.addCourseRef} ></AddCourse>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.AddCourseSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ AddCourseVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 修改课程 */}
        <Drawer
          open={this.state.EditorCourseVisible}
          title="修改"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorCourseVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.EditorCourseLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <EditorCourse TableItem={this.state.TableItem} ref={this.editorCourseRef} ></EditorCourse>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditorCourseSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorCourseVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        {/* 删除课程 */}
        <Spin spinning={this.state.DeleteCourseLoading} tip="加载中......" size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            title={null}
            footer={null}
            modalRender={(modal) => (
              modal
            )}
            // bodyStyle={{ padding: '0px' }}
            open={this.state.DeleteCourseVisible}
            onCancel={() => this.setState({ DeleteCourseVisible: false })}
            closable={true}
            width={400}
          >
            <div style={{ heighe: "205px", padding: "10px 20px" }}>
              <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
              <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => { this.VerifyDeleteCourse() }}>确认</Button>
                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteCourseVisible: false }) }}>取消</Button>
              </div>
            </div>
          </Modal>
        </Spin>
        {/* 文件 */}
        <Drawer
          open={this.state.FileVisible}
          title="文件"
          width='calc(100% - 200px)'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ FileVisible: false })}
          destroyOnClose={true}
        >
          <FileIndex TableItem={this.state.TableItem} ref={this.FileRef} ></FileIndex>
        </Drawer>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "43" }}>
            <Table columns={ActivityColumns}
              // rowSelection={rowSelection}
              onRow={(record, index) => {
                return {
                  onClick: event => { this.SelectActivity(record, index) }, // 点击行
                }
              }}
              rowClassName={this.rowClassNameFun}
              dataSource={this.state.ActivityList}
              className="table"
              bordered={true}
              pagination={paginationProps_Activity}
              rowKey={record => record.ID}
              size='small'
            />
          </div>
          <div style={{ flex: "2" }}></div>
          <div style={{ flex: "55" }}>
            <Table columns={CourseColumns}
              // rowSelection={rowSelection}
              dataSource={this.state.CourseList}
              className="table"
              bordered={true}
              pagination={paginationProps_Course}
              rowKey={record => record.ID}
              size='small'
            />
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    this.ActivityPaging(this.state.ActivityPage)
  }
  rowClassNameFun = (record, index) => {
    if (index === this.state.RowIndex)
      return 'mySelfClassName'
  }
  SelectActivity(record, index) {
    this.setState({
      RowIndex: index,
      ActivityID: record.ID,
    }, () => { this.CoursePaging(this.state.CoursePage) })
  }
  // 查活动
  ActivityPaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/activity",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        category: 2,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ ActivityList: res.data, ActivityCount: res.count, ActivityPage: page, ActivityID: res.data.length != 0 ? res.data[0].ID : 0 }, () => {
          if (this.state.ActivityID != 0) {
            this.CoursePaging(this.state.CoursePage)
          } else {
            this.setState({
              CourseList: []
            })
          }
        })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  // 查课程
  CoursePaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/train",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        activityID: this.state.ActivityID,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ CourseList: res.data, CourseCount: res.count, CoursePage: page,  })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
 
  // 添加活动
  addActivityRef = (ref) => {
    this.childAddActivityRef = ref
  }
  AddActivitySave() {
    var form = new FormData()
    form.set("Category", 2)
    if (this.childAddActivityRef.state.Name != "" && this.childAddActivityRef.state.Cover != "") {
      form.set("Name", this.childAddActivityRef.state.Name)
      form.set("Cover", this.childAddActivityRef.state.Cover)
      this.setState({
        AddActivityLoading: true,
      })
      service.request({
        url: "/admin/activity",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddActivityLoading: false,
            AddActivityVisible: false
          })
          this.ActivityPaging(this.state.ActivityPage)
          message.success("添加成功")
        } else {
          this.setState({
            AddActivityLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("添加失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        AddActivityLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  // 修改活动
  editorActivityDrawer(item) {
    this.setState({
      EditorActivityVisible: true,
      ActivityItem: item,
    })
  }
  editorActivityRef = (ref) => {
    this.childEditorActivityRef = ref
  }
  EditorActivitySave() {
    var form = new FormData()
    form.set("Category", 2)
    if (this.childEditorActivityRef.state.Name != "" && this.childEditorActivityRef.state.Cover != "") {
      form.set("ID", this.childEditorActivityRef.state.ID)
      form.set("Name", this.childEditorActivityRef.state.Name)
      form.set("Cover", this.childEditorActivityRef.state.Cover)
      form.set("Path", this.childEditorActivityRef.state.Path)
      this.setState({
        EditorActivityLoading: true,
      })
      service.request({
        url: "/admin/activity",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,
      }).then(res => {
        if (res.code == 200) {
          this.setState({
            EditorActivityLoading: false,
            EditorActivityVisible: false
          })
          this.ActivityPaging(this.state.ActivityPage)
          message.success("修改成功")
        } else {
          this.setState({
            EditorActivityLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("修改失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        EditorActivityLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  // 删除活动
  deleteActivityModal(record) {
    this.setState({
      ActivityItem: record,
      DeleteActivityVisible: true,
    })
  }
  VerifyDeleteActivity() {
    this.setState({ DeleteActivityLoading: true })
    service.request({
      url: "/admin/activity",
      method: "delete",
      data: {
        ID: this.state.ActivityItem.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.ActivityPaging(this.state.ActivityPage)
        this.setState({ DeleteActivityLoading: false, DeleteActivityVisible: false })
        message.success('删除成功');
      } else {
        this.setState({ DeleteActivityLoading: false, })
        if (res.code == 451) {
          message.error('该活动下面有课程，无法删除');
        }
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
  // 添加封面课程
  addCourseRef = (ref) => {
    this.childAddCourseRef = ref
  }
  AddCourseSave() {
    var form = new FormData()
    form.set("Category", 3)
    if (this.childAddCourseRef.state.Name != "" && this.childAddCourseRef.state.Cover != ""&&this.childAddCourseRef.state.ActivityID>0) {
      form.set("Name", this.childAddCourseRef.state.Name)
      form.set("Cover", this.childAddCourseRef.state.Cover)
      form.set("ActivityID", this.childAddCourseRef.state.ActivityID)
      this.setState({
        AddCourseLoading: true,
      })
      service.request({
        url: "/admin/train",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddCourseLoading: false,
            AddCourseVisible: false
          })
          this.CoursePaging(this.state.CoursePage)
          message.success("添加成功")
        } else {
          this.setState({
            AddCourseLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("添加失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        AddCourseLoading: false,
      })
      message.error('输入不能为空');
    }
  }

  // 修改课程
  editorCourseDrawer(item) {
    this.setState({
      EditorCourseVisible: true,
      TableItem: item,
    })
  }
  editorCourseRef = (ref) => {
    this.childEditorCourseRef = ref
  }
  EditorCourseSave() {
    var form = new FormData()
    form.set("Category", 3)
    if (this.childEditorCourseRef.state.Name != "" && this.childEditorCourseRef.state.Cover != "") {
      form.set("ID", this.childEditorCourseRef.state.ID)
      form.set("Name", this.childEditorCourseRef.state.Name)
      form.set("Cover", this.childEditorCourseRef.state.Cover)
      form.set("Path", this.childEditorCourseRef.state.Path)
      form.set("ActivityID", this.childEditorCourseRef.state.ActivityID)
      this.setState({
        EditorCourseLoading: true,
      })
      service.request({
        url: "/admin/train",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            EditorCourseLoading: false,
            EditorCourseVisible: false
          })
          this.CoursePaging(this.state.CoursePage)
          message.success("修改成功")
        } else {
          this.setState({
            EditorCourseLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("修改失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        EditorCourseLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  // 操作文件
  FileDrawer(item) {
    this.setState({
      FileVisible: true,
      TableItem: item,
    })
  }
  // 删除课程
  deleteCourseModal(record) {
    this.setState({
      TableItem: record,
      DeleteCourseVisible: true,
    })
  }
  VerifyDeleteCourse() {
    this.setState({ DeleteCourseLoading: true })
    service.request({
      url: "/admin/train",
      method: "delete",
      data: {
        ID: this.state.TableItem.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.CoursePaging(this.state.CoursePage)
        this.setState({ DeleteCourseLoading: false, DeleteCourseVisible: false })
        message.success('删除成功');
      } else {
        this.setState({ DeleteCourseLoading: false, })
        if (res.code == 451) {
          message.error('该课程下面有资源，无法删除');
        }
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }


}

