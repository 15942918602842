import React from 'react';
import { Input, Button, Switch, Space, DatePicker, Select } from 'antd';
import service from "../../request"
import cookie from "react-cookies";
import 'moment/locale/zh-cn';

import 'dayjs/locale/zh-cn';
const { Option } = Select;
const { TextArea } = Input;
class AddSchool extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Phone: '',
            Address: "",
            ParentCode: 0,
            ProvinceCode: 0,
            Province:'',
            ProvinceList: [],
            CityList: [],
            CityCode: 0,
            City: "",
            Area:'',
            AreaCode:0,
            AreaList:[],
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>学校名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>联系电话：</div>
                            <Input style={{ width: "300px", }} value={this.state.Phone} onChange={(e) => { this.setState({ Phone: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>省：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="省"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.Province}
                                onChange={(value) => {
                                    this.renderProvinceName(value)
                                    this.setState({ ParentCode: value,ProvinceCode:value, }, () => {this.getCity() })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.ProvinceList.map((item, index) => (
                                    <Option key={item.ID} value={item.Code} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>市：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="市"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.City}
                                onChange={(value) => {
                                    this.renderCityName(value)
                                    this.setState({ CityCode: value,ParentCode: value, }, () => {this.getArea() })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.CityList.map((item, index) => (
                                    <Option key={item.ID} value={item.Code} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>区：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="区"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.Area}
                                onChange={(value) => {
                                    this.renderAreaName(value)
                                    this.setState({ AreaCode: value, }, () => { })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.AreaList.map((item, index) => (
                                    <Option key={item.ID} value={item.Code} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>详细地址：</div>
                            <Input style={{ width: "300px", }} value={this.state.Address} onChange={(e) => { this.setState({ Address: e.target.value }) }} />
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.getProvince()
    }
    renderProvinceName(code) {
        let c = this.state.ProvinceList.find(item => {
          if (item.Code === code) {
            this.setState({Province:item.Name})
          }
        })
      }
    renderCityName(code) {
        let c = this.state.CityList.find(item => {
          if (item.Code === code) {
            this.setState({City:item.Name})
          }
        })
      }
      renderAreaName(code) {
        let c = this.state.AreaList.find(item => {
          if (item.Code === code) {
            this.setState({Area:item.Name})
          }
        })
      }
    getProvince() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {

            } else {
                this.setState({ ProvinceList: res.data,Province:res.data[0].Name,ProvinceCode:res.data[0].Code, ID: res.data[0].ID,ParentCode: res.data[0].Code, }, () => {this.getCity()  })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getCity() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
          
            if (res.code != 200) {
            } else {
                this.setState({ CityList: res.data,CityCode:res.data[0].Code, City: res.data[0].Name,ParentCode: res.data[0].Code, },()=>{
                    this.getArea()})
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getArea() {
        service.request({
            url: "/admin/regional",
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
            } else {
                this.setState({ AreaList: res.data,  Area: res.data[0].Name, AreaCode: res.data[0].Code,})
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
}
export default AddSchool;