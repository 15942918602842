import React from 'react';
import { Input, Space,message } from 'antd';
import service from '../../../request';

class OrderPost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            TableItem:{},
            UserList:[],
            UserName:"",
        };
    }
    render() {
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>订单编号：</div>
                            <div >{this.state.TableItem.Number}</div>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>商品名称：</div>
                            <div >{this.state.TableItem.Name}</div>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>商品数量：</div>
                            <div >{this.state.TableItem.Amount}</div>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>订单金额：</div>
                            <div >{this.state.TableItem.Price/100}</div>
                        </Space>
                       {this.state.TableItem.PayTime!=undefined? <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>时间：</div>
                            <div >{this.timeChange(this.state.TableItem.PayTime)}</div>
                        </Space>:""}
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>订单状态：</div>
                            {this.state.TableItem.State==1?<div>未支付</div>:""}
                            {this.state.TableItem.State==2?<div>未发货</div>:""}
                            {this.state.TableItem.State==3?<div>已完成</div>:""}
                        </Space>
                       <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>购买人：</div>
                            <div >{this.state.UserName}</div>
                        </Space>
                       {this.state.TableItem.Category==1? <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>收货人：</div>
                            <div >{this.state.TableItem.People}</div>
                        </Space>:""}
                        {this.state.TableItem.Category==1? <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>收货人电话：</div>
                            <div >{this.state.TableItem.Phone}</div>
                        </Space>:""}
                        {this.state.TableItem.Category==1? <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right',color:"rgb(146 131 131)" }}>收货人地址：</div>
                            <div >{this.state.TableItem.Province+this.state.TableItem.City+this.state.TableItem.Area+this.state.TableItem.Detailed}</div>
                        </Space>:""}
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            TableItem: this.props.TableItem
        },()=>{
            this.getUserList()
        })
    }
    timeChange(time) {
        if(time!=undefined){
            console.log(time)
            var date = time.substr(0, 10); //年月日
            var hours = time.substring(11, 13);
            var minutes = time.substring(14, 16);
            var seconds = time.substring(17, 19);
            var timeFlag = date + ' ' + hours + ':' + minutes + ':' + seconds;
            timeFlag = timeFlag.replace(/-/g, "/");
            timeFlag = new Date(timeFlag);
            // timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
            timeFlag = timeFlag.getFullYear() + '-' + ((timeFlag.getMonth() + 1) < 10 ? "0" + (timeFlag.getMonth() + 1) : (timeFlag.getMonth() + 1)) + '-' + (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) + ' ' + timeFlag.getHours() + ':' + timeFlag.getMinutes() + ':' + (timeFlag.getSeconds() < 10 ? "0" + timeFlag.getSeconds() : timeFlag.getSeconds());
            return timeFlag;
        }
      };
    getUserList(page) {
        service.request({
          url: "/admin/user",
          method: "get",
          params: {
            limit: 0,
            offset: 0,
          }
        }).then(res => {
          if (res.code != 200) {
            message.error('数据加载失败，请重试');
          } else {
            if( res.data.lenght>0){
                res.data.map((item,index)=>{
                    if(this.state.TableItem.UserID==item.ID){
                        this.setState({UserName:item.Name})
                    }
                })
            }
          }
        }).catch(function (error) {
          message.error('系统繁忙');
        })
      }
}
export default OrderPost;