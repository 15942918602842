import React from 'react';
import { Input, Button, Space, Upload, Switch } from 'antd';
const { TextArea } = Input;
class EditorLiveBroadcast extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            Note:"",
            fileList: [],
            Header: "https://api2.yuexiaojing.com/v3/",
            ID: 0,
            State:0,
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{}}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>直播间名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.setState({
                                    Name: e.target.value,
                                })
                            }} />
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>直播间封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传</Button>
                            </Upload>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>简介：</div>
                            <TextArea
                            style={{width:"300px",}}
                            value={this.state.Note}
                            onChange={(e) => { // this.state.formdata.set("Note", e.target.value,)
                                this.setState({ Note: e.target.value, })
                            }}
                            placeholder="简介"
                            autoSize={{
                                minRows: 3,
                                // maxRows: 5,
                            }} />
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            ID:this.props.TableItem.ID,
            Name:this.props.TableItem.Name,
            Cover:this.props.TableItem.Cover,
            Note:this.props.TableItem.Note,
         },()=>{
            var Covers = this.state.Cover.split("/")
            var Coverslength = Covers.length - 1
            var CoversItem = Covers[Coverslength]
            this.setState({
                fileList: [{
                    uid: '-1',
                    name: CoversItem,
                    status: 'done',
                    url: this.state.Header + this.state.Cover,
                    thumbUrl: this.state.Header + this.state.Cover,
                }]
            })
         })
    }
    Upload(f) {
        this.setState({ Cover: f.file, fileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default EditorLiveBroadcast 