import React from 'react';
import { Input, Button, Switch, Space, Upload,Select,message } from 'antd';

import { FileOutlined } from '@ant-design/icons'

const { TextArea } = Input;
class AddCommodity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            IsGrounding: 1,
            CoverFileList:[],
            Cover:"",
            Note:'',
            Inventory: null,
            Price:null,
        };
    }
    render() {
        const onChange = (date, dateString) => {
            if (date == null) {
                this.setState({ TimeLimit: -1 })
            } else {
                var Time = date._d.getTime()
                this.setState({
                    TimeLimit: Time
                })
            }
          };
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>商品名称：</div>
                            <Input style={{ width: "300px",  }} value={this.state.Name} onChange={(e)=>{this.setState({Name:e.target.value})}}/>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>商品库存：</div>
                            <Input style={{ width: "300px",  }} value={this.state.Inventory} onChange={(e)=>{
                                  var reg = /^[0-9]*$/;//正则表达式
                                  var isNumber = new RegExp(reg)
                                  if (isNumber.test(e.target.value)) {
                                    this.setState({Inventory:e.target.value})
                                  }
                                }}/>
                        </Space>
                        <Space align="center"  >
                                    <div style={{ width: "100px", textAlign: 'right' }}>商品封面：</div>
                                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadCover(f) }} fileList={this.state.CoverFileList}  >
                                        <Button style={{ background: "#0081FF", color: "#fff" }}>上传封面</Button>
                                    </Upload>
                                </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>商品状态：</div>
                            <Switch checkedChildren="上架" unCheckedChildren="下架" checked={this.state.IsGrounding==1?true:false} onChange={
                                () => { 
                                    if(this.state.IsGrounding==1){
                                        this.setState({IsGrounding:2})
                                    }else{
                                        this.setState({IsGrounding:1})
                                    }
                                 }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>商品简介：</div>
                            <TextArea
                            style={{width:"300px",}}
                            value={this.state.Note}
                            onChange={(e) => { // this.state.formdata.set("Note", e.target.value,)
                                this.setState({ Note: e.target.value, })
                            }}
                            placeholder="简介"
                            autoSize={{
                                minRows: 3,
                                // maxRows: 5,
                            }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>商品价格：</div>
                            <Input style={{ width: "100px",  }} value={this.state.Price} onChange={(e)=>{
                                    var reg = /^\d+(\.\d{0,2})?$/;//正则表达式
                                    var isNumber = new RegExp(reg)
                                    if (isNumber.test(e.target.value)) {
                                     this.setState({Price:e.target.value})
                                    }
                                }}/>
                            <div style={{ width: "100px", textAlign: 'left' }}>元</div>
                        </Space>
                       
                     
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
    }
        // 文件封面
        UploadCover(f) {
            this.setState({ Cover: f.file, CoverFileList: f.fileList })
        }
        beforeUpload() {
            return false
        }
}
export default AddCommodity;