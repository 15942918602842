import React from 'react';
import { Input, Button, Switch, Space, DatePicker, Select, message } from 'antd';
import service from "../../request"
import { FileOutlined } from '@ant-design/icons'
import cookie from "react-cookies";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
const { Option } = Select;
const { TextArea } = Input;
class AddUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            UserName: "",
            NickName: "",
            IsDisabled: false,
            IsLive: true,
            IsEditor: false,
            Phone: '',
            Password: "",
            NewPassword: '',
            List: [],
            RoleList: [{ Name: "老师", ID: 1 }, { Name: "校长", ID: 2 }],
            Identity: 1,
            SchoolID: null,
            TimeLimit: 0,
            Note: "",
        };
    }
    render() {
        const onChange = (date, dateString) => {

            if (date == null) {
                this.setState({ TimeLimit: -1 })
            } else {
                var Time = date._d.getTime()
                this.setState({
                    TimeLimit: Time
                })
            }
        };
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>用户名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.NickName} onChange={(e) => { this.setState({ NickName: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>账号：</div>
                            <Input style={{ width: "300px", }} value={this.state.UserName} onChange={(e) => { this.setState({ UserName: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>手机号：</div>
                            <Input style={{ width: "300px", }} value={this.state.Phone} onChange={(e) => { this.setState({ Phone: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>密码：</div>
                            <Input style={{ width: "300px", }} type='password' value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>确认密码：</div>
                            <Input style={{ width: "300px", }} type='password' value={this.state.NewPassword} onChange={(e) => { this.setState({ NewPassword: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>学校：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="学校"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.SchoolID}
                                onChange={(value) => {
                                    this.setState({ SchoolID: value, }, () => { })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.List.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>身份：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="身份"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.Identity}
                                onChange={(value) => {
                                    this.setState({ Identity: value, }, () => { })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.RoleList.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        {/* <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>学校：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="学校"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.SchoolID}
                                onChange={(value) => {
                                    this.setState({ SchoolID: value, }, () => { })
                                }}
                            >
                                {this.state.List.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space> */}
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>状态：</div>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={!this.state.IsDisabled} onChange={
                                () => { this.setState({ IsDisabled: this.state.IsDisabled }) }} />
                        </Space>
                        <Space align="center" >
                            <div style={{ width: "100px", textAlign: 'right', }}>有效时间：</div>
                            {/* <DatePicker  locale={zh-CN} onChange={(date,dateString)=>this.datepicker(date,dateString)}/> */}
                            <DatePicker locale={locale} style={{ width: "300px" }} onChange={onChange} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>备注：</div>
                            <Input style={{ width: "300px", }} value={this.state.Note} onChange={(e) => { this.setState({ Note: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>开启直播：</div>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.IsLive} onChange={
                                () => { this.setState({ IsLive: !this.state.IsLive }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>开启编辑：</div>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.IsEditor} onChange={
                                () => { this.setState({ IsEditor: !this.state.IsEditor }) }} />
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.getList()
    }
    getList() {
        service.request({
            url: "/admin/school",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ List: res.data, })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
}
export default AddUser;