import React from 'react';
import { Input, Space, } from 'antd';


class OrderPost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            MailingCompany: "",
            ExpressNumber: "",
            ID: 0,
        };
    }
    render() {
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>快递公司：</div>
                            <Input style={{ width: "300px", }} value={this.state.MailingCompany} onChange={(e) => { this.setState({ MailingCompany: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>快递编号：</div>
                            <Input style={{ width: "300px", }} value={this.state.ExpressNumber} onChange={(e) => { this.setState({ ExpressNumber: e.target.value }) }} />
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            ID: this.props.ItemID
        })
    }
}
export default OrderPost;