import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../../request';
import { Button, Table, Spin, Drawer, message, Switch, Modal, Select, Space, Input } from 'antd';
import AddPhase from './Phase_Page/addPhase'
import EditorPhase from './Phase_Page/editorPhase'
const { Option } = Select;
const { Search } = Input;
export default class Phase extends Component {
    constructor(props) {
        super(props)

        this.childAddPhaseRef = null
        this.childEditorPhaseRef = null
        this.state = {
            PhaseList: [],
            offset: 0,
            PhaseCount: 0,
            PhasePage: 1,
            PhaseItem: {},
            Type: 1,
            TypeList: [],
            RowIndex: 0,
            searchValue: "",
            AddPhaseVisible: false,
            AddPhaseLoading: false,
            EditorPhaseVisible: false,
            EditorPhaseLoading: false,
            DeletePhaseLoading: false,
            DeletePhaseVisible: false,

            Header: "https://api2.yuexiaojing.com/v3/"
        }
    }
    render() {

        const PhaseColumns = [
            { title: '阶段名称', dataIndex: 'Name', key: 'Name' },
            {
                title: '状态', dataIndex: 'IsDisabled', key: 'IsDisabled',
                render: (text, record, index) => {
                    return <div>
                        <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={!record.IsDisabled} onChange={
                            () => { this.switch(record) }} />
                    </div>
                }
            },

            {
                title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
                    return <div>
                        <Button onClick={() => this.editorPhaseDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
                        <Button onClick={() => this.deletePhaseModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
                    </div>
                }
            },
        ];

        const paginationProps_Phase = {
            current: this.state.page, //当前页码
            pageSize: 10, // 每页数据条数
            showTotal: (total) => (
                <span>共{total}条</span>
            ),
            total: this.state.Count, // 总条数
            onChange: page => handlePhasePageChange(page), //改变页码的函数
            hideOnSinglePage: false,
            showSizeChanger: false,
        }
        const handlePhasePageChange = (page) => {
            this.setState({ PhasePage: page })
            this.PhasePaging(page)
        }
        return (
            <div style={{ padding: "10px" }}>
                <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
                    <Space align="center">
                        <Select
                            showSearch
                            style={{ width: "300px", }}
                            placeholder=""
                            optionFilterProp="children"
                            defaultActiveFirstOption={true}
                            dropdownMatchSelectWidth={true}
                            value={this.state.Type}
                            onChange={(value) => {
                                this.setState({ Type: value, }, () => { this.PhasePaging(1) })
                            }}
                        >
                            {/* <Option key={0} value={0} >全部</Option> */}
                            {this.state.TypeList.map((item, index) => (
                                <Option key={item.ID} value={item.ID} >
                                    {item.Name}
                                </Option>
                            ))}
                        </Select>
                    </Space>
                    <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", marginLeft: "20px" }} onClick={() => this.setState({ AddPhaseVisible: true })}>添加阶段</Button>
                </div>
                {/* 添加 */}
                <Drawer
                    open={this.state.AddPhaseVisible}
                    title="添加"
                    width='500px'
                    keyboard={true}//Esc关闭
                    onClose={() => this.setState({ AddPhaseVisible: false })}
                    destroyOnClose={true}
                >
                    <Spin spinning={this.state.AddPhaseLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
                        <AddPhase TypeList={this.state.TypeList} ref={this.addPhaseRef} ></AddPhase>
                    </Spin>
                    <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
                        <Space>
                            <Button onClick={() => this.AddPhaserSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                            <Button onClick={() => this.setState({ AddPhaseVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
                        </Space>
                    </div>
                </Drawer>
                {/* 修改 */}
                <Drawer
                    open={this.state.EditorPhaseVisible}
                    title="修改"
                    width='500px'
                    keyboard={true}//Esc关闭
                    onClose={() => this.setState({ EditorPhaseVisible: false })}
                    destroyOnClose={true}
                >
                    <Spin spinning={this.state.EditorPhaseLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
                        <EditorPhase PhaseItem={this.state.PhaseItem} TypeList={this.state.TypeList} ref={this.editorPhaseRef} ></EditorPhase>
                    </Spin>
                    <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
                        <Space>
                            <Button onClick={() => this.EditorPhaserSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                            <Button onClick={() => this.setState({ EditorPhaseVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
                        </Space>
                    </div>
                </Drawer>
                {/* 删除文件 */}
                <Spin spinning={this.state.DeletePhaseLoading} tip="加载中......" size="large">
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.DeletePhaseVisible}
                        onCancel={() => this.setState({ DeletePhaseVisible: false })}
                        closable={true}
                        width={400}
                    >
                        <div style={{ heighe: "205px", padding: "10px 20px" }}>
                            <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                            <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.VerifyDeletePhase()}>确认</Button>
                                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeletePhaseVisible: false }) }}>取消</Button>
                            </div>
                        </div>
                    </Modal>
                </Spin>

                <div style={{}}>
                    <Table columns={PhaseColumns}
                        // rowSelection={rowSelection}
                        dataSource={this.state.PhaseList}
                        className="table"
                        bordered={true}
                        pagination={paginationProps_Phase}
                        rowKey={record => record.ID}
                        size='small'
                    />
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.queryPlate()
    }
    //
    queryPlate() {
        service.request({
            url: "/admin/plate",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ TypeList: res.data,Type:res.data[0].ID},()=>{
                    this.PhasePaging(1)
                })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
    // 查文件
    PhasePaging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            url: "/admin/phase",
            method: "get",
            params: {
                limit: 10,
                offset: newoffset,
                plateID: this.state.Type
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ PhaseList: res.data, PhaseCount: res.count, PhasePage: page, })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
    switch(item) {
        service.request({
            url: "/admin/phase",
            method: "put",
            data: {
                ID: item.ID,
                name: item.Name,
                IsDisabled: !item.IsDisabled,
                Type:item.Type
            }
        }).then(res => {
            if (res.code != 200) {
                message.error("状态改变失败，请重试！")
            } else {
                this.PhasePaging(1)
            }
        }).catch(function (error) {
            message.error("系统繁忙！")
            console.log(error)
        })
    }
    // 添加视频
    addPhaseRef = (ref) => {
        this.childAddPhaseRef = ref
    }
    addPhase(item) {
        this.setState({
            TypeID: item.ID,
            TypeVisible: false,
            AddPhaseVisible: true,
        })
    }
    AddPhaserSave() {
        if (this.childAddPhaseRef.state.Name != "" && this.childAddPhaseRef.state.Type != 0) {
            this.setState({
                AddPhaseLoading: true,
            })
            service.request({
                url: "/admin/phase",
                method: "post",
                data: {
                    Name: this.childAddPhaseRef.state.Name,
                    PlateID: this.childAddPhaseRef.state.Type,
                    IsDisabled: this.childAddPhaseRef.state.IsDisabled,
                    IsOpenPermission: this.childAddPhaseRef.state.IsOpenPermission,
                }

            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        AddPhaseLoading: false,
                        AddPhaseVisible: false
                    })
                    this.PhasePaging(1)
                    message.success("添加成功")
                } else {
                    this.setState({
                        AddPhaseLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    }
                    else if (res.code == 401) {
                        message.error("添加失败，请选择课程")
                    }
                    else {
                        message.error("添加失败，请重试")
                    }
                }
            })
        } else {
            this.setState({
                AddCourseLoading: false,
            })
            message.error('输入不能为空');
        }
    }
    // 修改视频
    editorPhaseDrawer(item) {
        this.setState({
            EditorPhaseVisible: true,
            PhaseItem: item,
        })
    }
    editorPhaseRef = (ref) => {
        this.childEditorPhaseRef = ref
    }
    EditorPhaserSave() {
        if (this.childEditorPhaseRef.state.Name != "" && this.childEditorPhaseRef.state.Type != 0) {
            this.setState({
                EditorPhaseLoading: true,
            })
            service.request({
                url: "/admin/phase",
                method: "put",
                data: {
                    Name: this.childEditorPhaseRef.state.Name,
                    ID: this.childEditorPhaseRef.state.ID,
                    PlateID: this.childEditorPhaseRef.state.Type,
                    IsDisabled: !this.childEditorPhaseRef.state.IsDisabled,
                }
            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        EditorPhaseLoading: false,
                        EditorPhaseVisible: false
                    })
                    this.PhasePaging(1)
                    message.success("修改成功")
                } else {
                    this.setState({
                        EditorPhaseLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    }
                    else {
                        message.error("修改失败，请重试")
                    }
                }
            })
        } else {
            this.setState({
                EditorCourseLoading: false,
            })
            message.error('输入不能为空');
        }
    }
    deletePhaseModal(record) {
        this.setState({
            PhaseItem: record,
            DeletePhaseVisible: true,
        })
    }
    VerifyDeletePhase() {
        this.setState({ DeletePhaseLoading: true })
        service.request({
            url: "/admin/phase",
            method: "delete",
            data: {
                ID: this.state.PhaseItem.ID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.PhasePaging(1)
                this.setState({ DeletePhaseLoading: false, DeletePhaseVisible: false })
                message.success('删除成功');
            } else {
                this.setState({ DeletePhaseLoading: false, })
                message.error('删除失败，请重试');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
}

