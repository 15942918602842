import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Modal, Select, Space, Input } from 'antd';

import AddSchool from './addSchool';
import EditorSchool from './editorSchool';

const { Search } = Input;
export default class School extends Component {
  constructor(props) {
    super(props)
    this.childAddSchoolRef = null
    this.childEditorSchoolRef = null
    this.state = {
      List: [],
      offset: 0,
      Count: 0,
      page: 1,
      searchValue: "",
      AddVisible: false,
      AddLoading: false,
      EditorVisible: false,
      EditorLoading: false,
      DeleteVisible: false,
      DeleteLoading: false,
      TableItem: {},
      tableID:0,
    }
  }
  render() {
    const columns = [
      { title: '学校名称', dataIndex: 'Name', key: 'Name' },
      { title: '联系电话', dataIndex: 'Phone', key: 'Phone', },
      { title: '地址', dataIndex: 'Address', key: 'Address' },
      // {
      //   title: '校长', dataIndex: 'TimeLimit', key: 'TimeLimit',
      //   // render: (text, record, index) => moment(parseInt(record.Time / 1000000)).format("YYYY-MM-DD HH:mm:ss") 
      //   // render: (text, record, index) => this.timeChange(record.TimeLimit)
      // },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorShowDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
            <Button onClick={() => this.deleteShowModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    const paginationProps = {
      current: this.state.page, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.Count, // 总条数
      onChange: page => handlePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handlePageChange = (page) => {
      this.setState({ page: page })
      this.paging(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          <Search placeholder="搜索" style={{ width: "200px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} />
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddVisible: true })}>添加</Button>
        </div>
        <Drawer
          open={this.state.AddVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ AddVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.AddLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <AddSchool ref={this.addSchoolRef} ></AddSchool>
          </Spin>
          {/* 底部 */}
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.AddSchoolSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ AddVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        <Drawer
          open={this.state.EditorVisible}
          title="修改"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.EditorLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <EditorSchool TableItem={this.state.TableItem} ref={this.editorSchoolRef} ></EditorSchool>
          </Spin>
          {/* 底部 */}
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditorSchoolSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        <Spin spinning={this.state.DeleteLoading} tip="加载中......" size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            title={null}
            footer={null}
            modalRender={(modal) => (
              modal
            )}
            // bodyStyle={{ padding: '0px' }}
            open={this.state.DeleteVisible}
            onCancel={() => this.setState({ DeleteVisible: false })}
            closable={true}
            width={400}
          >
            <div style={{ heighe: "205px", padding: "10px 20px" }}>
              <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
              <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.determineDelete()}>确认</Button>
                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => this.hideModal()}>取消</Button>
              </div>
            </div>
          </Modal>
        </Spin>
        <Table columns={columns}
          // rowSelection={rowSelection}
          dataSource={this.state.List}
          className="table"
          bordered={true}
          pagination={paginationProps}
          rowKey={record => record.ID}
          size='small'
        />
      </div>
    )
  }
  componentDidMount() {
    this.paging(this.state.page)
  }
  timeChange(time) {
    var date = time.substr(0, 10); //年月日
    var hours = time.substring(11, 13);
    var minutes = time.substring(14, 16);
    var seconds = time.substring(17, 19);
    var timeFlag = date + ' ' + hours + ':' + minutes + ':' + seconds;
    timeFlag = timeFlag.replace(/-/g, "/");
    timeFlag = new Date(timeFlag);
    // timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
    timeFlag = timeFlag.getFullYear() + '-' + ((timeFlag.getMonth() + 1) < 10 ? "0" + (timeFlag.getMonth() + 1) : (timeFlag.getMonth() + 1)) + '-' + (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) + ' ' + timeFlag.getHours() + ':' + timeFlag.getMinutes() + ':' + (timeFlag.getSeconds() < 10 ? "0" + timeFlag.getSeconds() : timeFlag.getSeconds());
    return timeFlag;
  };
  onSearch() {
this.paging(this.state.page)
  }
  paging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/school",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        name: this.state.searchValue,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ List: res.data, Count: res.count, page: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  addSchoolRef = (ref) => {
    this.childAddSchoolRef = ref
  }
  AddSchoolSave() {
    if (this.childAddSchoolRef.state.Name != "" && this.childAddSchoolRef.state.Phone != "" && this.childAddSchoolRef.state.Address != "") {
      this.setState({
        AddLoading: true,
      })
      service.request({
        url: "/admin/school",
        method: "post",
        data: {
          Name: this.childAddSchoolRef.state.Name,
          Phone: this.childAddSchoolRef.state.Phone,
          Address: this.childAddSchoolRef.state.Address,
          ProvinceCode: this.childAddSchoolRef.state.ProvinceCode,
          CityCode: this.childAddSchoolRef.state.CityCode,
          City: this.childAddSchoolRef.state.City,
          province: this.childAddSchoolRef.state.Province,
          District: this.childAddSchoolRef.state.Area,
          DistrictCode: this.childAddSchoolRef.state.AreaCode
        },
      }).then(res => {
        if (res.code == 200) {
          this.paging(this.state.page)
          message.success('添加成功');
          this.setState({
            AddLoading: false,
            AddVisible: false,
          })
        } else {
          this.setState({
            AddLoading: false,
          })
          if (res.code == 452) {
            message.error('添加失败,名字重复');
          } else {
            message.error('添加失败,请重试');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
  editorShowDrawer(item) {
    this.setState({
      EditorVisible: true,
      TableItem: item,
    })
  }
  editorSchoolRef = (ref) => {
    this.childEditorSchoolRef = ref
  }
  EditorSchoolSave() {
    if (111) {
      this.setState({
        EditorLoading: true,
      })
      service.request({
        url: "/admin/school",
        method: "put",
        data: {
          ID: this.childEditorSchoolRef.state.ID,
          Name: this.childEditorSchoolRef.state.Name,
          Phone: this.childEditorSchoolRef.state.Phone,
          Address: this.childEditorSchoolRef.state.Address,
          City: this.childEditorSchoolRef.state.City,
          CityCode: this.childEditorSchoolRef.state.CityCode,
          District: this.childEditorSchoolRef.state.District,
          DistrictCode: this.childEditorSchoolRef.state.DistrictCode,
          Province: this.childEditorSchoolRef.state.Province,
          ProvinceCode: this.childEditorSchoolRef.state.ProvinceCode,
        },
      }).then(res => {
        if (res.code == 200) {
          this.paging(this.state.page)
          this.setState({
            EditorLoading: false,
            EditorVisible: false,
          })
          message.success('修改成功');
        } else {
          this.setState({
            EditorLoading: false,
          })
          if (res.code == 452) {
            message.error('修改失败,名字重复');
          } else {
            message.error('修改失败,请重试');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
  deleteShowModal(record){
    this.setState({
      tableID: record.ID,
      DeleteVisible:true
    })
  }
  determineDelete() {
    this.setState({
      DeleteLoading:true
    })
    service.request({
      url: "/admin/school",
      method: "delete",
      data: {
        ID: this.state.tableID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.paging(this.state.page)
        this.setState({
          DeleteLoading:false,
          DeleteVisible:false
        })
        message.success('删除成功');
      } else {
        this.setState({
          DeleteLoading:false,
        })
        message.error('删除失败，请重试');
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
}
