import React from "react"
import { Layout, Select, Button, Table, Drawer, Spin, message, Tabs, Modal } from 'antd';
// import 'antd/dist/antd.min.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import * as styled from "./styled"
import service from "../../../../request"
import 'moment/locale/zh-cn';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
//
import AddFiles from "./addFiles"
import EditorFiles from "./editorFiles"
const { Header, Footer, Sider, Content } = Layout;
const { TabPane } = Tabs;
class FileList extends React.Component {
    constructor(props) {
        super(props)
        this.AddFilesRef = null
        this.EditorFilesRef = null

        this.state = {
            Header: "https://api2.yuexiaojing.com/v3/",
            type: "课件",
            className: "",
            //
            FileList: [],
            FileListCount: 0,
            FileListpage: 1,
            FileListID: 0,
            FileListItem: {},
            //
            AddFilesVisible: false,
            AddFilesLoading: false,
            EditorFilesVisible: false,
            EditorFilesLoading: false,
            DeleteFilesLoading: false,
            DeleteFilesVisible: false,

        }
    }
    render() {
        const FileListColumns = [
            {
                title: '名称', dataIndex: 'Name', key: 'Name',
                render: (text, record, index) => {
                    return <div >{record.Name ? (record.Name.length > 12 ? record.Name.substring(0, 12) + "..." : record.Name) : ""}</div>
                }
            },
            {
                title: '下载', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
                    return <div >
                        {record.IsDownload==1?"允许":"不允许"}
                    </div>
                }
            },
            {
                title: '类型', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
                    return <div >
                        {record.Type}
                    </div>
                }
            },
            {
                title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
                    return <div>
                        <Button onClick={() => this.OpenEditorFiles( record)} style={{ color: "#2BC17B", border: "none" }} >编辑</Button>
                        <Button onClick={() => this.OpenDeleteFiles( record)} style={{ marginLeft: "20px", color: "#FB5451", border: "none" }}>删除</Button>
                        <Spin spinning={this.state.DeleteFilesLoading} tip="加载中......" size="large">
                            <Modal
                                destroyOnClose={true}
                                centered={true}
                                title={null}
                                footer={null}
                                modalRender={(modal) => (
                                    modal
                                )}
                                mask={false}
                                // bodyStyle={{ padding: '0px' }}
                                open={this.state.DeleteFilesVisible}
                                onCancel={() => { this.setState({ DeleteFilesVisible: false }) }}
                                closable={true}
                                width={400}
                                // maskStyle={{opacity:0.1}}
                            >
                                <div style={{ heighe: "205px", padding: "10px 20px" }}>
                                    <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                                    <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                                        <Button type="primary" style={{ background: "#2BC17B", border: "1px solid #2BC17B", marginRight: "40px" }} onClick={() => this.IsDeleteFiles()}>确认</Button>
                                        <Button style={{ border: "1px solid #2BC17B", color: "#2BC17B" }} onClick={() => { this.setState({ DeleteFilesVisible: false }) }}>取消</Button>
                                    </div>
                                </div>
                            </Modal>
                        </Spin>
                    </div>
                }
            },
        ];


        const FileListPaginationProps = {
            current: this.state.FileListpage, //当前页码
            pageSize: 10, // 每页数据条数
            showTotal: (total) => (
                <span>共{total}条</span>
            ),
            total: this.state.FileListCount, // 总条数
            onChange: page => handleFileListChange(page), //改变页码的函数
            hideOnSinglePage: false,
            showSizeChanger: false,
        }
        const handleFileListChange = (page) => {
            this.setState({ FileListpage: page })
            this.getFileListPaging(page)
        }
        return (

            <div style={{ background: "#fff", margin: "5px" }}>
               
                    <Drawer
                        headerStyle={{ marginTop: "50px" }}
                        open={this.state.AddFilesVisible}
                        title={"添加" + this.state.type}
                        width='500px'
                        keyboard={true}//Esc关闭
                        onClose={() => { this.setState({ AddFilesVisible: false }) }}
                        destroyOnClose={true}
                    >
                         <Spin spinning={this.state.AddFilesLoading} tip="加载中......" size="large">
                        <AddFiles ref={this.addFilesRef}  ></AddFiles>
                        </Spin>
                        {/* 底部 */}
                        <div style={{ display: "flex", position: "absolute", bottom: "30px", left: "40px" }}>
                            <Button onClick={() => this.SaveAddFiles()} style={{ background: "#2BC17B", border: "1px solid #2BC17B", color: "#fff", }}>确定</Button>
                            <Button onClick={() => { this.setState({ AddFilesVisible: false }) }} style={{ border: "1px solid #2BC17B", color: "#2BC17B", marginLeft: "100px" }}>取消</Button>
                        </div>
                    </Drawer>
                <Spin spinning={this.state.EditorFilesLoading} tip="加载中......" size="large">
                    <Drawer
                        headerStyle={{ marginTop: "50px" }}
                        open={this.state.EditorFilesVisible}
                        title={"修改" + this.state.type}
                        width='500px'
                        keyboard={true}//Esc关闭
                        onClose={() => { this.setState({ EditorFilesVisible: false }) }}
                        destroyOnClose={true}
                    >
                        <EditorFiles ref={this.editorFilesRef} FileListItem={this.state.FileListItem}  ></EditorFiles>
                        {/* 底部 */}
                        <div style={{ display: "flex", position: "absolute", bottom: "30px", left: "40px" }}>
                            <Button onClick={() => this.SaveEditorFiles()} style={{ background: "#2BC17B", border: "1px solid #2BC17B", color: "#fff", }}>确定</Button>
                            <Button onClick={() => { this.setState({ EditorFilesVisible: false }) }} style={{ border: "1px solid #2BC17B", color: "#2BC17B", marginLeft: "100px" }}>取消</Button>
                        </div>
                    </Drawer>
                </Spin>

                <Tabs defaultActiveKey="课件" type="card" size={"middle"} destroyInactiveTabPane={true} onTabClick={(e) => this.OpenType(e)}>
                    <TabPane tab="课件" key="课件" forceRender={true}>
                        <div style={{ width: "100%", height: "40px", textAlign: "right", lineHeight: "40px", }}>
                            <Button style={{ background: "#27ae60", color: "#fff" }} onClick={() => this.OpenAddFiles()}>添加课件</Button>
                        </div>
                        <Table columns={FileListColumns}
                            // rowSelection={rowSelection}
                            dataSource={this.state.FileList}
                            className="table"
                            size={"small"}
                            bordered={true}
                            pagination={FileListPaginationProps}
                        />
                    </TabPane>
                    <TabPane tab="视频" key="视频" forceRender={true}>
                        <div style={{ width: "100%", height: "40px", textAlign: "right", lineHeight: "40px", }}>
                            <Button style={{ background: "#27ae60", color: "#fff" }} onClick={() => this.OpenAddFiles()}>添加视频</Button>
                        </div>
                        <Table columns={FileListColumns}
                            // rowSelection={rowSelection}
                            dataSource={this.state.FileList}
                            className="table"
                            size={"small"}
                            bordered={true}
                            pagination={FileListPaginationProps}
                        />
                    </TabPane>
                    <TabPane tab="音频" key="音频" forceRender={true}>
                        <div style={{ width: "100%", height: "40px", textAlign: "right", lineHeight: "40px", }}>
                            <Button style={{ background: "#27ae60", color: "#fff" }} onClick={() => this.OpenAddFiles()}>添加音频</Button>
                        </div>
                        <Table columns={FileListColumns}
                            // rowSelection={rowSelection}
                            dataSource={this.state.FileList}
                            className="table"
                            size={"small"}
                            bordered={true}
                            pagination={FileListPaginationProps}
                        />
                    </TabPane>
                    <TabPane tab="教案" key="教案" forceRender={true}>
                        <div style={{ width: "100%", height: "40px", textAlign: "right", lineHeight: "40px", }}>
                            <Button style={{ background: "#27ae60", color: "#fff" }} onClick={() => this.OpenAddFiles()}>添加教案</Button>
                        </div>
                        <Table columns={FileListColumns}
                            // rowSelection={rowSelection}
                            dataSource={this.state.FileList}
                            className="table"
                            size={"small"}
                            bordered={true}
                            pagination={FileListPaginationProps}
                        />
                    </TabPane>
                </Tabs>

            </div>
        )
    }
    componentDidMount() {
        this.setState({
            classID: this.props.ClassItem.ID,
            courseID: this.props.ClassItem.CourseID,
            className: this.props.ClassItem.Name,
        }, () => {
            this.getCoursewarePaging(1)
        })
    }
    OpenType(e) {
        this.setState({
            type: e
        }, () => {
            if (this.state.type == "课件") {
                this.getCoursewarePaging(1)
            }
            if (this.state.type == "视频") {
                this.getVideoPaging(1)
            }
            if (this.state.type == "音频") {
                this.getAudioPaging(1)
            }
            if (this.state.type == "教案") {
                this.getLessonPlanPaging(1)
            }
        })
    }
    addFilesRef = (ref) => {
        this.AddFilesRef = ref
    }
    OpenAddFiles() {
        this.setState({
            AddFilesVisible: true,
        })
    }
    SaveAddFiles() {
        if (this.AddFilesRef.state.Name != "" && this.AddFilesRef.state.file != "") {
            let formdata = new FormData
            formdata.set("ClassID", this.state.classID)
            formdata.set("Type", this.state.type)
            // formdata.set("courseID",this.state.courseID,)
            formdata.set("Name", this.AddFilesRef.state.Name,)
            formdata.set("File", this.AddFilesRef.state.file,)
            formdata.set("IsDownload", this.AddFilesRef.state.IsDownload,)
            
            this.setState({
                AddFilesLoading: true,
            })
            service.request({
                url: "/admin/resources",
                method: "post",
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formdata,
            }).then(res => {
                if (res.code == 200) {
                    if (this.state.type == "课件") {
                        this.getCoursewarePaging(1)
                    }
                    if (this.state.type == "视频") {
                        this.getVideoPaging(1)
                    }
                    if (this.state.type == "音频") {
                        this.getAudioPaging(1)
                    }
                    if (this.state.type == "教案") {
                        this.getLessonPlanPaging(1)
                    }
                    this.setState({
                        AddFilesLoading: false,
                        AddFilesVisible: false,
                    })
                    message.success({ content: '添加成功', style: { marginTop: '10vh', }, });
                } else {
                    this.setState({
                        AddFilesLoading: false,
                    })
                    if (res.code == 452) {
                        message.error({ content: '添加失败,名字重复', style: { marginTop: '10vh', }, });
                    } else {
                        message.error({ content: '添加失败', style: { marginTop: '10vh', }, });
                    }
                }
            }).catch(function (error) {
                console.log(error)
            })
        }

    }
    // 修改
    editorFilesRef = (ref) => {
        this.EditorFilesRef = ref
    }
    OpenEditorFiles(record) {
        this.setState({
            EditorFilesVisible: true,
            FileListItem: record,
        })
    }
    SaveEditorFiles() {
        if (this.EditorFilesRef.state.Name != "" && this.EditorFilesRef.state.file != "") {
            var form = new FormData()
            form.set("ClassID",this.EditorFilesRef.state.ClassID)
            form.set("ID", this.EditorFilesRef.state.ID)
            form.set("Type", this.EditorFilesRef.state.Type)
            form.set("Path", this.EditorFilesRef.state.Path)
            form.set("Name",this.EditorFilesRef.state.Name)
            form.set("File",this.EditorFilesRef.state.File)
            form.set("IsDownload",this.EditorFilesRef.state.IsDownload)
            this.setState({
                EditorFilesLoading: true,
            })
            service.request({
                url: "/admin/resources",
                method: "put",
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: form,
            }).then(res => {
                if (res.code == 200) {
                    if (this.state.type == "课件") {
                        this.getCoursewarePaging(1)
                    }
                    if (this.state.type == "视频") {
                        this.getVideoPaging(1)
                    }
                    if (this.state.type == "音频") {
                        this.getAudioPaging(1)
                    }
                    if (this.state.type == "教案") {
                        this.getLessonPlanPaging(1)
                    }
                    this.setState({
                        EditorFilesLoading: false,
                        EditorFilesVisible: false,
                    })
                    message.success({ content: '修改成功', style: { marginTop: '10vh', }, });
                } else {
                    this.setState({
                        EditorFilesLoading: false,
                    })
                    if (res.code == 403) {
                        message.error({ content: '修改失败,名字重复', style: { marginTop: '10vh', }, });
                    } else {
                        message.error({ content: '修改失败', style: { marginTop: '10vh', }, });
                    }
                }
            }).catch(function (error) {
                console.log(error)
            })
        }
    }
    // 删除文件
    OpenDeleteFiles(record) {
        this.setState({
            FileListItem: record,
            DeleteFilesVisible: true,
        })
    }
    IsDeleteFiles() {
        this.setState({ DeleteFilesLoading: true })
        service.request({
            url: "/admin/resources",
            method: "delete",
            data: {
                ID: this.state.FileListItem.ID
            }
        }).then(res => {
            if (res.code == 200) {
                if (this.state.type == "课件") {
                    this.getCoursewarePaging(1)
                }
                if (this.state.type == "视频") {
                    this.getVideoPaging(1)
                }
                if (this.state.type == "音频") {
                    this.getAudioPaging(1)
                }
                if (this.state.type == "教案") {
                    this.getLessonPlanPaging(1)
                }
                message.success({ content: '删除成功', style: { marginTop: '10vh', }, });
                this.setState({ DeleteFilesVisible: false, DeleteFilesLoading: false })
            } else {
                message.error({ content: '删除失败，请重试', style: { marginTop: '10vh', }, });
                this.setState({ DeleteFilesVisible: false, DeleteFilesLoading: false })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    //
    getCoursewarePaging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            url: "/admin/resources",
            method: "get",
            params: {
                limit: limit,
                offset: newoffset,
                // name: this.state.className,
                classID: this.state.classID,
                // courseID: this.state.courseID,
                type: this.state.type
            }
        }).then(res => {
            if (res.code != 200) {
                message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
            } else {
                this.setState({ FileList: res.data, FileListCount: res.count, FileListPage: page, })
            }
        })
    }
    getVideoPaging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            url: "/admin/resources",
            method: "get",
            params: {
                limit: limit,
                offset: newoffset,
                classID: this.state.classID,
                // courseID: 0,
                type: this.state.type
            }
        }).then(res => {
            if (res.code != 200) {
                message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
            } else {
                this.setState({ FileList: res.data, FileListCount: res.count, FileListPage: page, }, () => { })
            }
        })
    }
    getAudioPaging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            url: "/admin/resources",
            method: "get",
            params: {
                limit: limit,
                offset: newoffset,
                classID: this.state.classID,
                courseID: 0,
                type: this.state.type
            }
        }).then(res => {
            if (res.code != 200) {
                message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
            } else {
                this.setState({ FileList: res.data, FileListCount: res.count, FileListPage: page, }, () => { })
            }
        })
    }
    getLessonPlanPaging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            url: "/admin/resources",
            method: "get",
            params: {
                limit: limit,
                offset: newoffset,
                classID: this.state.classID,
                courseID: 0,
                type: this.state.type
            }
        }).then(res => {
            if (res.code != 200) {
                message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
            } else {
                this.setState({ FileList: res.data, FileListCount: res.count, FileListPage: page, }, () => { })
            }
        })
    }
}
export default FileList