import React from 'react';
import { Input, Button, Switch, Space, DatePicker, Select, message, ConfigProvider } from 'antd';
import service from "../../request"
import { FileOutlined } from '@ant-design/icons'
import cookie from "react-cookies";
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh_cn')
const { Option } = Select;
const { TextArea } = Input;
class EditorUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID: 0,
            Username: "",
            NickName: "",
            IsDisabled: false,
            IsLive: false,
            IsEditor: false,
            Phone: '',
            Password: "",
            NewPassword: '',
            SchoolList: [],
            SchoolID: null,
            TimeLimit: 0,
            Note: "",
            RoleList: [{ Name: "老师", ID: 1 }, { Name: "校长", ID: 2 }],
            Identity: 1,
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>用户名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.NickName} onChange={(e) => { this.setState({ NickName: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>账号：</div>
                            <Input style={{ width: "300px", }} value={this.state.Username} onChange={(e) => { this.setState({ Username: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>手机号：</div>
                            <Input style={{ width: "300px", }} value={this.state.Phone} onChange={(e) => { this.setState({ Phone: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>密码：</div>
                            <Input style={{ width: "300px", }} type='password' value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>确认密码：</div>
                            <Input style={{ width: "300px", }} type='password' value={this.state.NewPassword} onChange={(e) => { this.setState({ NewPassword: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>学校：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="学校"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.SchoolID}
                                onChange={(value) => {
                                    this.setState({ SchoolID: value, }, () => { })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.SchoolList.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>身份：</div>
                            <Select
                                showSearch
                                style={{ width: "300px", }}
                                placeholder="身份"
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                dropdownMatchSelectWidth={true}
                                value={this.state.Identity}
                                onChange={(value) => {
                                    this.setState({ Identity: value, }, () => { })
                                }}
                            >
                                {/* <Option key={0} value={0} >全部</Option> */}
                                {this.state.RoleList.map((item, index) => (
                                    <Option key={item.ID} value={item.ID} >
                                        {item.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>状态：</div>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.IsDisabled} onChange={
                                () => { this.setState({ IsDisabled: !this.state.IsDisabled }) }} />
                        </Space>
                        <Space align="center" >
                            <div style={{ width: "100px", textAlign: 'right', }}>有效时间：</div>
                            {/* <DatePicker  locale={zh-CN} onChange={(date,dateString)=>this.datepicker(date,dateString)}/> */}
                            <ConfigProvider locale={zhCN}>
                                <DatePicker style={{ width: "300px" }}
                                    onChange={(date, dateString) => this.onChangeTime(date, dateString)}
                                    // showTime={{ defaultValue: moment('00:00:00',), }}
                                    // onBlur={null}
                                    // format="YYYY-MM-DD"
                                    value={this.state.TimeLimit == -1 ? null : moment(this.state.TimeLimit)}
                                // value={this.state.TimeLimit}
                                />
                            </ConfigProvider >

                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>备注：</div>
                            <Input style={{ width: "300px", }} value={this.state.Note} onChange={(e) => { this.setState({ Note: e.target.value }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>开启直播：</div>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.IsLive} onChange={
                                () => { this.setState({ IsLive: !this.state.IsLive }) }} />
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>开启编辑：</div>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.IsEditor} onChange={
                                () => { this.setState({ IsEditor: !this.state.IsEditor }) }} />
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            ID: this.props.TableItem.ID,
            Username: this.props.TableItem.Username,
            NickName: this.props.TableItem.NickName,
            IsDisabled: !this.props.TableItem.IsDisabled,
            IsLive: this.props.TableItem.IsLive == 1 ? true : false,
            IsEditor: this.props.TableItem.IsEditor == 1 ? true : false,
            Phone: this.props.TableItem.Phone,
            Password: this.props.TableItem.Password,
            SchoolID: this.props.TableItem.SchoolID,
            Identity: this.props.TableItem.Identity,
            Note: this.props.TableItem.Note,
            TimeLimit: this.props.TableItem.TimeLimit == -1 ? -1 : this.props.TableItem.TimeLimit,
            // TimeLimit: 1690339704000,
        })
        this.getSchoolList()
    }
    onChangeTime(date, dateString) {
        if (date == null) {
            this.setState({ TimeLimit: -1 })
        } else {
            var Time = date._d.getTime()
            this.setState({
                TimeLimit: Time
            })
        }

    }
    getSchoolList() {
        service.request({
            url: "/admin/school",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ SchoolList: res.data, })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
}
export default EditorUser;