import React from 'react';
import { Input, Button, Switch, Space, DatePicker,ConfigProvider,message } from 'antd';
import service from "../../request"
import { FileOutlined } from '@ant-design/icons'
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh_cn')
const { TextArea } = Input;
class EditorAnnouncement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Title:"",
            Content:"",
            Time:0,
            ID:0,
        };
    }
    render() {
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>公告名称：</div>
                            <Input style={{ width: "300px",  }} value={this.state.Title} onChange={(e)=>{this.setState({Title:e.target.value})}}/>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>公告内容：</div>
                            <TextArea
                            style={{width:"300px",}}
                            value={this.state.Content}
                            onChange={(e) => { // this.state.formdata.set("Note", e.target.value,)
                                this.setState({ Content: e.target.value, })
                            }}
                            placeholder="公告内容"
                            autoSize={{
                                minRows: 3,
                                // maxRows: 5,
                            }} />
                        </Space>
                        
                        <Space align="center" >
                            <div style={{ width: "100px", textAlign: 'right',  }}>发布时间：</div>
                            <ConfigProvider locale={zhCN}>
                                <DatePicker style={{ width: "300px" }}
                                    onChange={(date, dateString) => this.onChangeTime(date, dateString)}
                                      value={moment(this.state.Time)} 
                                />
                            </ConfigProvider >
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            Title: this.props.TableItem.Title,
            Content: this.props.TableItem.Content,
            Time: this.props.TableItem.Time,
            ID: this.props.TableItem.ID,
        })
    }
    onChangeTime(date, dateString) {
        var Time = date._d.getTime()
            this.setState({
                Time: Time
            })
        }
    
}
export default EditorAnnouncement;