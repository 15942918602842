import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../../request';
import { Button, Table, Spin, Drawer, message, Switch, Modal, Select, Space, Input } from 'antd';
import AddPlate from './Plate_Page/addPlate'
import EditorPlate from './Plate_Page/editorPlate'
const { Option } = Select;
export default class Plate extends Component {
    constructor(props) {
        super(props)

        this.childAddPlateRef = null
        this.childEditorPlateRef = null
        this.state = {
            PlateList: [],
            offset: 0,
            PlateCount: 0,
            PlatePage: 1,
            PlateItem: {},
            Type: 1,
            RowIndex: 0,
            searchValue: "",
            AddPlateVisible: false,
            AddPlateLoading: false,
            EditorPlateVisible: false,
            EditorPlateLoading: false,
            DeletePlateLoading: false,
            DeletePlateVisible: false,
        }
    }
    render() {

        const PlateColumns = [
            { title: '板块名称', dataIndex: 'Name', key: 'Name' },
            { title: '创建时间', dataIndex: 'CreateTime', key: 'CreateTime',},
            {
                title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
                    return <div>
                        <Button onClick={() => this.editorPlateDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
                        <Button onClick={() => this.deletePlateModal(record)} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
                    </div>
                }
            },
        ];

        const paginationProps_Plate = {
            current: this.state.page, //当前页码
            pageSize: 10, // 每页数据条数
            showTotal: (total) => (
                <span>共{total}条</span>
            ),
            total: this.state.Count, // 总条数
            onChange: page => handlePlatePageChange(page), //改变页码的函数
            hideOnSinglePage: false,
            showSizeChanger: false,
        }
        const handlePlatePageChange = (page) => {
            this.setState({ PlatePage: page })
            this.PlatePaging(page)
        }
        return (
            <div style={{ padding: "10px" }}>
                <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
                    <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", marginLeft: "20px" }} onClick={() => this.setState({ AddPlateVisible: true })}>添加板块</Button>
                </div>
                {/* 添加 */}
                <Drawer
                    open={this.state.AddPlateVisible}
                    title="添加"
                    width='500px'
                    keyboard={true}//Esc关闭
                    onClose={() => this.setState({ AddPlateVisible: false })}
                    destroyOnClose={true}
                >
                    <Spin spinning={this.state.AddPlateLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
                        <AddPlate TrainID={this.state.TrainID} ref={this.addPlateRef} ></AddPlate>
                    </Spin>
                    <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
                        <Space>
                            <Button onClick={() => this.AddPlateSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                            <Button onClick={() => this.setState({ AddPlateVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
                        </Space>
                    </div>
                </Drawer>
                {/* 修改 */}
                <Drawer
                    open={this.state.EditorPlateVisible}
                    title="修改"
                    width='500px'
                    keyboard={true}//Esc关闭
                    onClose={() => this.setState({ EditorPlateVisible: false })}
                    destroyOnClose={true}
                >
                    <Spin spinning={this.state.EditorPlateLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
                        <EditorPlate PlateItem={this.state.PlateItem} ref={this.editorPlateRef} ></EditorPlate>
                    </Spin>
                    <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
                        <Space>
                            <Button onClick={() => this.EditorPlateSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                            <Button onClick={() => this.setState({ EditorPlateVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
                        </Space>
                    </div>
                </Drawer>
                {/* 删除文件 */}
                <Spin spinning={this.state.DeletePlateLoading} tip="加载中......" size="large">
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.DeletePlateVisible}
                        onCancel={() => this.setState({ DeletePlateVisible: false })}
                        closable={true}
                        width={400}
                    >
                        <div style={{ heighe: "205px", padding: "10px 20px" }}>
                            <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                            <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.VerifyDeletePlate()}>确认</Button>
                                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeletePlateVisible: false }) }}>取消</Button>
                            </div>
                        </div>
                    </Modal>
                </Spin>

                <div style={{}}>
                    <Table columns={PlateColumns}
                        // rowSelection={rowSelection}
                        dataSource={this.state.PlateList}
                        className="table"
                        bordered={true}
                        pagination={paginationProps_Plate}
                        rowKey={record => record.ID}
                        size='small'
                    />
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.PlatePaging(1)
    }
    // 查文件
    PlatePaging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            url: "/admin/plate",
            method: "get",
            params: {
                limit: 10,
                offset: newoffset,
                type: this.state.Type
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ PlateList: res.data, PlateCount: res.count, PlatePage: page, })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
    // 
    addPlateRef = (ref) => {
        this.childAddPlateRef = ref
    }
    addPlate(item) {
        this.setState({
            TypeID: item.ID,
            TypeVisible: false,
            AddPlateVisible: true,
        })
    }
    AddPlateSave() {
        if (this.childAddPlateRef.state.Name != "" && this.childAddPlateRef.state.Type != 0) {
            this.setState({
                AddPlateLoading: true,
            })
            service.request({
                url: "/admin/plate",
                method: "post",
                data: {
                    Name: this.childAddPlateRef.state.Name,
                }

            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        AddPlateLoading: false,
                        AddPlateVisible: false
                    })
                    this.PlatePaging(1)
                    message.success("添加成功")
                } else {
                    this.setState({
                        AddPlateLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    }
                    else {
                        message.error("添加失败，请重试")
                    }
                }
            })
        } else {
            this.setState({
                AddCourseLoading: false,
            })
            message.error('输入不能为空');
        }
    }
    // 修改视频
    editorPlateDrawer(item) {
        this.setState({
            EditorPlateVisible: true,
            PlateItem: item,
        })
    }
    editorPlateRef = (ref) => {
        this.childEditorPlateRef = ref
    }
    EditorPlateSave() {
        if (this.childEditorPlateRef.state.Name != "" && this.childEditorPlateRef.state.Type != 0) {
            this.setState({
                EditorPlateLoading: true,
            })
            service.request({
                url: "/admin/plate",
                method: "put",
                data: {
                    Name: this.childEditorPlateRef.state.Name,
                    ID: this.childEditorPlateRef.state.ID,
                }
            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        EditorPlateLoading: false,
                        EditorPlateVisible: false
                    })
                    this.PlatePaging(1)
                    message.success("修改成功")
                } else {
                    this.setState({
                        EditorPlateLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    }
                    else {
                        message.error("修改失败，请重试")
                    }
                }
            })
        } else {
            this.setState({
                EditorCourseLoading: false,
            })
            message.error('输入不能为空');
        }
    }
    deletePlateModal(record) {
        this.setState({
            PlateItem: record,
            DeletePlateVisible: true,
        })
    }
    VerifyDeletePlate() {
        this.setState({ DeletePlateLoading: true })
        service.request({
            url: "/admin/plate",
            method: "delete",
            data: {
                ID: this.state.PlateItem.ID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.PlatePaging(1)
                this.setState({ DeletePlateLoading: false, DeletePlateVisible: false })
                message.success('删除成功');
            } else {
                this.setState({ DeletePlateLoading: false, })
                message.error('删除失败，请重试');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
}

