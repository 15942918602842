import React from 'react';
import { Layout, Select, Button, Table, Space, Drawer, Spin, message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import { Dropdown, } from "@cs/react"
// import * as styled from "./styled"
import service from "../../../request"
import 'moment/locale/zh-cn';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
//
import AddCourse from "./course_Page/addCourse";
import EditorCourse from "./course_Page/editorCourse";
import AddClass from "./course_Page/addClass";
import EditorClass from "./course_Page/editorClass";
import EditorCollection from "./course_Page/editorCollection";
import FileList from "./course_Page/FileList";

const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;
class Courses extends React.Component {
  constructor(props) {
    super(props)
    this.AddCourseRef = null
    this.EditorCourseRef = null
    this.AddClassRef = null
    this.EditorClassRef = null
    this.EditorCollectionRef = null
    this.state = {
      Header: "https://api2.yuexiaojing.com/v3/",
      PhaseList: [],
      PhaseID: null,
      Type: 0,
      //
      CourseList: [],
      CourseCount: 0,
      Coursepage: 1,
      CourseID: 0,
      CourseItem: {},
      AddCourseVisible: false,
      EditorCourseVisible: false,
      DeleteCourseVisible: false,
      AddCourseLoading: false,
      EditorCourseLoading: false,
      DeleteCourseLoading: false,
      //
      ClassList: [],
      ClassCount: 0,
      ClassPage: 1,
      ClassID: 0,
      ClassItem: {},
      AddClassVisible: false,
      EditorClassVisible: false,
      DeleteClassVisible: false,
      AddClassLoading: false,
      EditorClassLoading: false,
      DeleteClassLoading: false,
      //
      CollectionList: [],
      CollectionCount: 0,
      CollectionPage: 1,
      CollectionID: 0,
      CollectionItem: {},
      EditorCollectionVisible: false,
      DeleteCollectionVisible: false,
      EditorCollectionLoading: false,
      DeleteCollectionLoading: false,
      //
      FileListVisible: false,
    }
  }
  render() {
    const CourseColumns = [
      {
        title: '课程名称', dataIndex: 'Name', key: 'Name',
        render: (text, record, index) => {
          return <div >{record.Name ? (record.Name.length > 12 ? record.Name.substring(0, 12) + "..." : record.Name) : ""}</div>
        }
      },
      {
        title: '课程封面', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
          return <div >
            <img style={{ width: "50px", height: "50px", objectFit: "contain", }} src={this.state.Header + record.Cover}></img>
          </div>
        }
      },
      {
        title: '普通/合集', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
          return <div >{record.Type == 1 ? "普通课程" : "合集课程"}</div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={(e) => this.OpenEditorCourse(e, record)} style={{ color: "#0081FF", border: "none" }} >编辑</Button>
            <Button onClick={(e) => this.OpenDeleteCourse(e, record)} style={{ marginLeft: "20px", color: "#FB5451", border: "none" }}>删除</Button>
          </div>
        }
      },
    ];
    const ClassColumns = [
      {
        title: '课时名称', dataIndex: 'Name', key: 'Name',
        render: (text, record, index) => {
          return <div >{record.Name ? (record.Name.length > 12 ? record.Name.substring(0, 12) + "..." : record.Name) : ""}</div>
        }
      },
      {
        title: '课时封面', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
          return <div >
            <img style={{ width: "50px", height: "50px", objectFit: "contain", }} src={this.state.Header + record.Cover}></img>
          </div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.OpenFileList(record)} style={{ color: "#FB5451", border: "none" }}>文件</Button>

            <Button onClick={() => this.OpenEditorClass(record)} style={{ marginLeft: "20px", color: "#0081FF", border: "none" }} >编辑</Button>
            <Button onClick={() => this.OpenDeleteClass(record)} style={{ marginLeft: "20px", color: "#FB5451", border: "none" }}>删除</Button>
            <Spin spinning={this.state.DeleteClassLoading} tip="加载中......" size="large">
              <Modal
                destroyOnClose={true}
                centered={true}
                title={null}
                footer={null}
                modalRender={(modal) => (
                  modal
                )}
                // bodyStyle={{ padding: '0px' }}
                open={this.state.DeleteClassVisible}
                onCancel={() => { this.setState({ DeleteClassVisible: false }) }}
                closable={true}
                width={400}
              >
                <div style={{ heighe: "205px", padding: "10px 20px" }}>
                  <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                  <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.IsDeleteClass()}>确认</Button>
                    <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteClassVisible: false }) }}>取消</Button>
                  </div>
                </div>
              </Modal>
            </Spin>
          </div>
        }
      },
    ];
    const CollectionColumns = [
      {
        title: '文件名称', dataIndex: 'Name', key: 'Name',
        render: (text, record, index) => {
          return <div >{record.Name ? (record.Name.length > 12 ? record.Name.substring(0, 12) + "..." : record.Name) : ""}</div>
        }
      },
      {
        title: '文件封面', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
          return <div >
            <img style={{ width: "50px", height: "50px", objectFit: "contain", }} src={record.Type == "其他" ? this.state.Header + record.Cover : this.state.Header + record.Path}></img>
          </div>
        }
      },
      {
        title: '下载', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
            return <div >
                {record.IsDownload==1?"允许":"不允许"}
            </div>
        }
    },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.OpenEditorCollection(record)} style={{ color: "#0081FF", border: "none" }} >编辑</Button>
            <Button onClick={() => this.OpenDeleteCollection(record)} style={{ marginLeft: "20px", color: "#FB5451", border: "none" }}>删除</Button>
            <Spin spinning={this.state.DeleteCollectionLoading} tip="加载中......" size="large">
              <Modal
                destroyOnClose={true}
                centered={true}
                title={null}
                footer={null}
                modalRender={(modal) => (
                  modal
                )}
                // bodyStyle={{ padding: '0px' }}
                open={this.state.DeleteCollectionVisible}
                onCancel={() => { this.setState({ DeleteCollectionVisible: false }) }}
                closable={true}
                width={400}
              >
                <div style={{ heighe: "205px", padding: "10px 20px" }}>
                  <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                  <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.IsDeleteCollection()}>确认</Button>
                    <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteCollectionVisible: false }) }}>取消</Button>
                  </div>
                </div>
              </Modal>
            </Spin>
          </div>
        }
      },
    ];


    const CoursePaginationProps = {
      current: this.state.Coursepage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.CourseCount, // 总条数
      onChange: page => handleCourseChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleCourseChange = (page) => {
      this.setState({ Coursepage: page })
      this.getCoursePaging(page)
    }
    const ClassPaginationProps = {
      current: this.state.ClassPage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.ClassCount, // 总条数
      onChange: page => handleClassChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleClassChange = (page) => {
      this.setState({ ClassPage: page })
      this.getClassPaging(page)
    }
    const CollectionPaginationProps = {
      current: this.state.CollectionPage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.CollectionCount, // 总条数
      onChange: page => handleCollectionChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleCollectionChange = (page) => {
      this.setState({ CollectionPage: page })
      this.getCollectionPaging(page)
    }
    return (
      <Layout>
        <Header style={{ height: "50px", textAlign: 'right', lineHeight: "50px", background: "#fff", padding: "0px 15px", borderBottom: "1px solid #ccc" }} span={24}>
          <Space align="center">
            <div style={{ width: "100px", textAlign: 'right' }}>阶段：</div>
            <Select
              showSearch
              style={{ width: "300px", }}
              placeholder=""
              optionFilterProp="children"
              defaultActiveFirstOption={true}
              dropdownMatchSelectWidth={true}
              value={this.state.PhaseID}
              onChange={(value) => {
                this.setState({ PhaseID: value }, () => { this.getCoursePaging(1) })
              }}
            >
              {/* <Option key={0} value={0} >全部</Option> */}
              {this.state.PhaseList.map((item, index) => (
                <Option key={item.ID} value={item.ID} >
                  {item.Name}
                </Option>
              ))}
            </Select>
          </Space>
        </Header>
        <Content style={{ padding: "10px", overflow: "auto", background: '#fff', display: "flex", justifyContent: 'center', flexDirection: 'row', }}>
          <div style={{ width: "100%", height: "100%", background: "#fff", margin: "5px" }}>
            <div style={{ width: "100%", height: "40px", textAlign: "right", lineHeight: "40px", }}>
              <Button style={{ background: "#0081FF", color: "#fff" }} onClick={() => this.OpenAddCouse()}>添加课程</Button>
            </div>
            {/* 添加课程 */}
            <Drawer
              open={this.state.AddCourseVisible}
              title="添加课程"
              width='500px'
              keyboard={true}//Esc关闭
              onClose={() => this.CloseAddCourse()}
              destroyOnClose={true}
            >
              <Spin spinning={this.state.AddCourseLoading} tip="加载中......" size="large">
                <AddCourse ref={this.addCourseRef}  ></AddCourse>
              </Spin>
              <div style={{ display: "flex", position: "absolute", bottom: "30px", left: "40px" }}>
                <Button onClick={() => this.SaveAddCourse()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => this.CloseAddCourse()} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "100px" }}>取消</Button>
              </div>
            </Drawer>
            <Table columns={CourseColumns}
              onRow={(record, index) => {
                return {
                  onClick: event => { this.CourseItem(record, index) }, // 点击行
                }
              }}
              dataSource={this.state.CourseList}
              className="table"
              size={"small"}
              bordered={true}
              pagination={CoursePaginationProps}
            />
            {/* 修改课程 */}

            <Drawer
              open={this.state.EditorCourseVisible}
              title="修改课程"
              width='500px'
              keyboard={true}//Esc关闭
              onClose={() => this.CloseEditorCourse()}
              destroyOnClose={true}
            >
              <Spin spinning={this.state.EditorCourseLoading} tip="加载中......" size="large">
                <EditorCourse ref={this.editorCourseRef} CourseItem={this.state.CourseItem} ></EditorCourse>
              </Spin>
              <div style={{ display: "flex", position: "absolute", bottom: "30px", left: "40px" }}>
                <Button onClick={() => this.SaveEditorCourse()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => this.CloseEditorCourse()} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "100px" }}>取消</Button>
              </div>
            </Drawer>
            <Spin spinning={this.state.DeleteCourseLoading} tip="加载中......" size="large">
              <Modal
                destroyOnClose={true}
                centered={true}
                title={null}
                footer={null}
                modalRender={(modal) => (
                  modal
                )}
                open={this.state.DeleteCourseVisible}
                onCancel={() => { this.setState({ DeleteCourseVisible: false }) }}
                closable={true}
                width={400}
              >
                <div style={{ heighe: "205px", padding: "10px 20px" }}>
                  <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                  <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.IsDeleteCourse()}>确认</Button>
                    <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteCourseVisible: false }) }}>取消</Button>
                  </div>
                </div>
              </Modal>
            </Spin>
          </div>
          <div style={{ width: "100%", height: "100%", background: "#fff", margin: "5px" }}>
            <div style={{ width: "100%", height: "40px", textAlign: "right", lineHeight: "40px", }}>
              <Button style={{ marginRight: "20px", background: "#0081FF", color: "#fff" }} onClick={() => this.OpenAddClass()}>添加课时/合集</Button>
            </div>

            <Drawer

              open={this.state.AddClassVisible}
              title="添加课时/合集"
              width='1000px'
              keyboard={true}//Esc关闭
              onClose={() => this.setState({ AddClassVisible: false })}
              destroyOnClose={true}
            >
              <Spin spinning={this.state.AddClassLoading} tip="加载中......" size="large">
                <AddClass ref={this.addClassRef}  ></AddClass>
              </Spin>
              <div style={{ display: "flex", position: "absolute", bottom: "30px", left: "40px" }}>

                <Button onClick={() => this.SaveAddClass()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => this.setState({ AddClassVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "100px" }}>取消</Button>

              </div>
            </Drawer>

            <Drawer
              open={this.state.EditorClassVisible}
              title="修改课时"
              width='500px'
              keyboard={true}//Esc关闭
              onClose={() => { this.setState({ EditorClassVisible: false }) }}
              destroyOnClose={true}
            >
              <Spin spinning={this.state.EditorClassLoading} tip="加载中......" size="large">
                <EditorClass ref={this.editorClassRef} ClassItem={this.state.ClassItem} ></EditorClass>
              </Spin>
              <div style={{ display: "flex", position: "absolute", bottom: "30px", left: "40px" }}>
                <Button onClick={() => this.SaveEditorClass()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => { this.setState({ EditorClassVisible: false }) }} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "100px" }}>取消</Button>
              </div>
            </Drawer>

            <Drawer
              open={this.state.EditorCollectionVisible}
              title="修改文件"
              width='500px'
              keyboard={true}//Esc关闭
              onClose={() => { this.setState({ EditorCollectionVisible: false }) }}
              destroyOnClose={true}
            >
              <Spin spinning={this.state.EditorCollectionLoading} tip="加载中......" size="large">
                <EditorCollection ref={this.editorCollectionRef} CollectionItem={this.state.CollectionItem} ></EditorCollection>
              </Spin>
              <div style={{ display: "flex", position: "absolute", bottom: "30px", left: "40px" }}>
                <Button onClick={() => this.SaveEditorCollection()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => { this.setState({ EditorCollectionVisible: false }) }} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "100px" }}>取消</Button>
              </div>
            </Drawer>
            <Drawer
             
              open={this.state.FileListVisible}
              title="文件列表"
              width='90%'
              keyboard={true}//Esc关闭
              onClose={() => { this.setState({ FileListVisible: false }) }}
              destroyOnClose={true}
            >
              <FileList ClassItem={this.state.ClassItem} ></FileList>

            </Drawer>
            {this.state.Type == 1 ? <Table columns={ClassColumns}
              dataSource={this.state.ClassList}
              className="table"
              size={"small"}
              bordered={true}
              pagination={ClassPaginationProps}
            /> : <Table columns={CollectionColumns}
              dataSource={this.state.CollectionList}
              className="table"
              size={"small"}
              bordered={true}
              pagination={CollectionPaginationProps}
            />}
          </div>
        </Content>
      </Layout >
    )
  }
  componentDidMount() {
    this.getPhasePaging()
  }

  // 课程添加
  addCourseRef = (ref) => {
    this.AddCourseRef = ref
  }
  OpenAddCouse() {
    this.setState({
      AddCourseVisible: true,
    })
  }
  CloseAddCourse() {
    this.setState({
      AddCourseVisible: false,
    })
  }
  SaveAddCourse() {
    var form = new FormData
    if (this.AddCourseRef.state.Cover != "" && this.AddCourseRef.state.Name != "" && this.AddCourseRef.state.Type > 0) {
      form.set("PhaseID", this.AddCourseRef.state.PhaseID)
      form.set("Name", this.AddCourseRef.state.Name)
      form.set("Cover", this.AddCourseRef.state.Cover)
      form.set("Type", this.AddCourseRef.state.Type)
      this.setState({
        AddCourseLoading: true,
      })
      service.request({
        url: "/admin/course",
        method: "post",
        Headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,
      }).then(res => {
        if (res.code == 200) {
          this.getCoursePaging(1)
          this.setState({
            AddCourseLoading: false,
            AddCourseVisible: false,
          })
          message.success({ content: '添加成功', style: { marginTop: '10vh', }, });
        } else {
          this.setState({
            AddCourseLoading: false,
          })
          if (res.code == 452) {
            message.error({ content: '添加失败,名字重复', style: { marginTop: '10vh', }, });
          } else {
            message.error({ content: '添加失败', style: { marginTop: '10vh', }, });
          }
        }
      }).catch(function (error) {
        console.log(error)
      })
    } else {
      message.error({ content: '输入或选项不能为空', style: { marginTop: '10vh', }, });
    }
  }
  // 修改课程
  editorCourseRef = (ref) => {
    this.EditorCourseRef = ref
  }
  OpenEditorCourse(e, record) {
    e.stopPropagation()
    this.setState({
      CourseItem: record,
      EditorCourseVisible: true,
    })
  }
  CloseEditorCourse() {
    this.setState({
      EditorCourseVisible: false,
    })
  }
  SaveEditorCourse() {
    var form = new FormData
    if (this.EditorCourseRef.state.Cover != "" && this.EditorCourseRef.state.Name != "") {
      // form.set("PhaseID",this.AddCourseRef.state.PhaseID)
      form.set("Name", this.EditorCourseRef.state.Name)
      form.set("Cover", this.EditorCourseRef.state.Cover)
      form.set("Type", this.EditorCourseRef.state.Type)
      form.set("ID", this.EditorCourseRef.state.ID)
      this.setState({
        EditorCourseLoading: true,
      })
      service.request({
        url: "/admin/course",
        method: "PUT",
        Headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,
      }).then(res => {
        if (res.code == 200) {
          this.getCoursePaging(1)
          this.setState({
            EditorCourseLoading: false,
            EditorCourseVisible: false,
          })
          message.success({ content: '修改成功', style: { marginTop: '10vh', }, });
        } else {
          this.setState({
            EditorCourseLoading: false,
          })
          if (res.code == 452) {
            message.error({ content: '修改失败,名字重复', style: { marginTop: '10vh', }, });
          } else {
            message.error({ content: '修改失败,', style: { marginTop: '10vh', }, });
          }
        }
      }).catch(function (error) {
        console.log(error)
      })
    } else {
      message.error({ content: '输入或选项不能为空', style: { marginTop: '10vh', }, });
    }

  }
  // 删除课程
  OpenDeleteCourse(e, record) {
    e.stopPropagation()
    this.setState({
      CourseItem: record,
      DeleteCourseVisible: true,
    })
  }
  IsDeleteCourse() {
    this.setState({ DeleteCourseLoading: true })
    service.request({
      url: "/admin/course",
      method: "delete",
      data: {
        ID: this.state.CourseItem.ID
      }
    }).then(res => {
      if (res.code == 200) {
        this.getCoursePaging(1)
        message.success({ content: '删除成功', style: { marginTop: '10vh', }, });
        this.setState({ DeleteCourseVisible: false, DeleteCourseLoading: false })
      } else {
        message.error({ content: '删除失败，该课程下有数据', style: { marginTop: '10vh', }, });
        this.setState({ DeleteCourseVisible: false, DeleteCourseLoading: false })
      }
    }).catch(function (error) {
      console.log(error)
    })
  }
  // 添加课时
  addClassRef = (ref) => {
    this.AddClassRef = ref
  }
  OpenAddClass() {
    this.setState({
      AddClassVisible: true
    })
  }
  SaveAddClass() {
    if (this.AddClassRef.state.Type == 1) {
      if (this.AddClassRef.state.Cover != "" && this.AddClassRef.state.Name != "" && this.AddClassRef.state.CourseID != "") {
        let formdata = new FormData
        formdata.set("CourseID", this.AddClassRef.state.CourseID,)
        formdata.set("Name", this.AddClassRef.state.Name,)
        formdata.set("Cover", this.AddClassRef.state.Cover,)
        this.setState({
          AddClassLoading: true,
        })
        service.request({
          url: "/admin/class",
          method: "post",
          Headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formdata,
        }).then(res => {
          if (res.code == 200) {
            this.getCoursePaging(1)
            this.setState({
              AddClassLoading: false,
              AddClassVisible: false,
            })
            message.success({ content: '添加成功', style: { marginTop: '10vh', }, });
          } else {
            this.setState({
              AddClassLoading: false,
            })
            if (res.code == 452) {
              message.error({ content: '添加失败,名字重复', style: { marginTop: '10vh', }, });
            } else {
              message.error({ content: '添加失败', style: { marginTop: '10vh', }, });
            }
          }
        }).catch(function (error) {
          console.log(error)
        })
      } else {
        message.error({ content: '输入或选项不能为空', style: { marginTop: '10vh', }, });
      }
    } else {

      if (this.AddClassRef.state.Name != "" && this.AddClassRef.state.CourseID != "" && this.AddClassRef.state.FileCover != "" && this.AddClassRef.state.File != "") {
        let formdata = new FormData
        formdata.set("CourseID", this.AddClassRef.state.CourseID,)
        formdata.set("Name", this.AddClassRef.state.Name,)
        formdata.set("Cover", this.AddClassRef.state.FileCover,)
        formdata.set("File", this.AddClassRef.state.File,)
        formdata.set("IsDownload", this.AddClassRef.state.IsDownload,)
        formdata.set("Type", "其他",)
        this.setState({
          AddClassLoading: true,
        })
        service.request({
          url: "/admin/resources",
          method: "post",
          Headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formdata,
        }).then(res => {
          if (res.code == 200) {
            this.getCoursePaging(1)
            this.setState({
              AddClassLoading: false,
              AddClassVisible: false,
            })
            message.success({ content: '添加成功', style: { marginTop: '10vh', }, });
          } else {
            this.setState({
              AddClassLoading: false,
            })
            if (res.code == 452) {
              message.error({ content: '添加失败,名字重复', style: { marginTop: '10vh', }, });
            } else {
              message.error({ content: '添加失败', style: { marginTop: '10vh', }, });
            }
          }
        }).catch(function (error) {
          console.log(error)
        })
      } else {
        message.error({ content: '输入或选项不能为空', style: { marginTop: '10vh', }, });
      }
    }
  }
  // 修改
  editorClassRef = (ref) => {
    this.EditorClassRef = ref
  }
  OpenEditorClass(record) {
    this.setState({
      ClassItem: record,
      EditorClassVisible: true,
    })
  }
  SaveEditorClass() {
    if (this.EditorClassRef.state.Cover != "" && this.EditorClassRef.state.Name != "") {
      let formdata = new FormData
      formdata.set("ID", this.EditorClassRef.state.ID,)
      formdata.set("Name", this.EditorClassRef.state.Name,)
      formdata.set("Cover", this.EditorClassRef.state.Cover,)
      this.setState({
        EditorClassLoading: true,
      })
      service.request({
        url: "/admin/class",
        method: "put",
        Headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formdata,
      }).then(res => {
        if (res.code == 200) {
          this.getClassPaging(1)
          this.setState({
            EditorClassLoading: false,
            EditorClassVisible: false,
          })
          message.success({ content: '修改成功', style: { marginTop: '10vh', }, });
        } else {
          this.setState({
            EditorClassLoading: false,
          })
          if (res.code == 452) {
            message.error({ content: '修改失败,名字重复', style: { marginTop: '10vh', }, });
          } else {
            message.error({ content: '修改失败,', style: { marginTop: '10vh', }, });
          }
        }
      }).catch(function (error) {
        console.log(error)
      })
    } else {
      message.error({ content: '输入或选项不能为空', style: { marginTop: '10vh', }, });
    }

  }
  // 删除课时
  OpenDeleteClass(record) {
    this.setState({
      ClassItem: record,
      DeleteClassVisible: true,
    })
  }
  IsDeleteClass() {
    this.setState({ DeleteClassLoading: true })
    service.request({
      url: "/admin/class",
      method: "delete",
      data: {
        ID: this.state.ClassItem.ID
      }
    }).then(res => {
      if (res.code == 200) {
        this.getClassPaging(1)
        message.success({ content: '删除成功', style: { marginTop: '10vh', }, });
        this.setState({ DeleteClassVisible: false, DeleteClassLoading: false })
      } else {
        message.error({ content: '删除失败，请重试', style: { marginTop: '10vh', }, });
        this.setState({ DeleteClassVisible: false, DeleteClassLoading: false })
      }
    }).catch(function (error) {
      console.log(error)
    })
  }
  // 修改合集文件
  editorCollectionRef = (ref) => {
    this.EditorCollectionRef = ref
  }
  OpenEditorCollection(record) {
    this.setState({
      CollectionItem: record,
      EditorCollectionVisible: true,
    })
  }
  SaveEditorCollection() {


    if (this.EditorCollectionRef.state.Name != "" && this.EditorCollectionRef.state.File != "" && this.EditorCollectionRef.state.FileCover != "") {
      let formdata = new FormData
      formdata.set("ID", this.EditorCollectionRef.state.ID,)
      formdata.set("Name", this.EditorCollectionRef.state.Name,)
      formdata.set("Type", this.EditorCollectionRef.state.FileType,)
      formdata.set("File", this.EditorCollectionRef.state.File,)
      formdata.set("Cover", this.EditorCollectionRef.state.FileCover,)
      formdata.set("IsDownload", this.EditorCollectionRef.state.IsDownload,)
      this.setState({
        EditorCollectionLoading: true,
      })
      service.request({
        url: "/admin/resources",
        method: "put",
        Headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formdata,
      }).then(res => {
        if (res.code == 200) {
          this.getCoursePaging(1)
          this.setState({
            EditorCollectionLoading: false,
            EditorCollectionVisible: false,
          })
          message.success({ content: '修改成功', style: { marginTop: '10vh', }, });
        } else {
          this.setState({
            EditorCollectionLoading: false,
          })
          if (res.code == 403) {
            message.error({ content: '修改失败,名字重复', style: { marginTop: '10vh', }, });
          } else {
            message.error({ content: '修改失败', style: { marginTop: '10vh', }, });
          }
        }
      }).catch(function (error) {
        console.log(error)
      })
    } else {
      message.error({ content: '输入或选项不能为空', style: { marginTop: '10vh', }, });
    }


  }
  // 删除合集
  OpenDeleteCollection(record) {
    this.setState({
      CollectionItem: record,
      DeleteCollectionVisible: true,
    })
  }
  IsDeleteCollection() {
    this.setState({ DeleteCollectionLoading: true })
    service.request({
      url: "/admin/resources",
      method: "delete",
      data: {
        ID: this.state.CollectionItem.ID
      }
    }).then(res => {
      if (res.code == 200) {
        this.getCollectionPaging(1)
        message.success({ content: '删除成功', style: { marginTop: '10vh', }, });
        this.setState({ DeleteCollectionVisible: false, DeleteCollectionLoading: false })
      } else {
        message.error({ content: '删除失败，请重试', style: { marginTop: '10vh', }, });
        this.setState({ DeleteCollectionVisible: false, DeleteCollectionLoading: false })
      }
    }).catch(function (error) {
      console.log(error)
    })
  }
  CourseItem(record, index) {
    this.setState({
      Type: record.Type,
      CourseID: record.ID
    }, () => {
      if (this.state.Type == 1) {
        this.getClassPaging(1)
      } else {
        this.getCollectionPaging(1)
      }
    })
  }
  ClassRow(record, index) {
    this.setState({
      ClassID: record.ID
    })
  }
  getPhasePaging() {

    service.request({
      url: "/admin/phase",
      method: "get",
      params: {
        offset: 0,
        limit: 0,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
      } else {
        this.setState({ PhaseList: res.data, PhaseID: res.data.length > 0 ? res.data[0].ID : 0, }, () => {
          if (this.state.PhaseID != 0) {
            this.getCoursePaging(1)
          }
        })
      }
    }).catch(function (error) {
      console.log(error)
    })

  }
  getCoursePaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/course",
      method: "get",
      params: {
        limit: limit,
        offset: newoffset,
        phaseID: this.state.PhaseID,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
      } else {
        this.setState({ CourseList: res.data, CourseID: res.data.length > 0 ? res.data[0].ID : 0, Type: res.data.length > 0 ? res.data[0].Type : 1, CourseCount: res.count, CoursePage: page, }, () => {
          if (this.state.CourseID != 0) {
            if (this.state.Type == 1) {
              this.getClassPaging(1)
            } else {
              this.getCollectionPaging(1)
            }

          } else {
            this.setState({
              ClassList: [],
            })
          }
        })

      }
    })
  }
  getClassPaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/class",
      method: "get",
      params: {
        limit: limit,
        offset: newoffset,
        courseID: this.state.CourseID,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
      } else {

        this.setState({ ClassList: res.data, ClassCount: res.count, ClassPage: page, })

      }
    })
  }
  getCollectionPaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/resources",
      method: "get",
      params: {
        limit: limit,
        offset: newoffset,
        courseID: this.state.CourseID,
        classID: 0,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
      } else {
        this.setState({ CollectionList: res.data, CollectionCount: res.count, CollectionPage: page, })
      }
    })
  }
  OpenFileList(record) {
    this.setState({
      ClassItem: record,
      FileListVisible: true,
    })
  }
}
export default Courses