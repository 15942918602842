
import React from 'react';
import { Input, Tabs, Select, Upload, Space, message, Button } from 'antd';
import service from "../../../../request"
import moment from 'moment';

import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select;
class EditorCourse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            PhaseID: 0,
            Name: 0,
            Cover: "",
            PhaseList: [],
            Type: 1,
            Header: "https://api2.yuexiaojing.com/v3/",
            fileList:[]
        };
    }
    // 抽屉
    render() {
        return (
            <div style={{ width: "100%", }}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        display: 'flex',
                    }}
                >
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>名称</div>
                        <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                            this.setState({ Name: e.target.value, })
                        }} placeholder="名称" />
                    </Space>
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>封面</div>
                        <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}>
                            <Button icon={<UploadOutlined />}>上传</Button>
                        </Upload>
                    </Space>
                </Space>
            </div>

        );
    }
    componentDidMount() {
        this.setState({
            ID:this.props.CourseItem.ID,
            Name: this.props.CourseItem.Name,
            Cover: this.props.CourseItem.Cover,
            Type:this.props.CourseItem.Type,
        },()=>{
                var Covers= this.state.Cover.split("/")
                var Coverslength=Covers.length-1
                var CoversItem=Covers[Coverslength]
                 this.setState({
                     fileList:[{
                         uid: '-1',
                         name:CoversItem,
                         status: 'done',
                         url:this.state.Header+this.state.Cover,
                         thumbUrl: this.state.Header+this.state.Cover,
                       }]
                 })
        })
        this.getPhasePaging()
    }
    getPhasePaging() {
        return new Promise(resolve => {
            service.request({
                url: "/admin/phase",
                method: "get",
                params: {
                    offset: 0,
                    limit: 0,
                }
            }).then(res => {
                if (res.code != 200) {
                    message.error("系统繁忙！")
                } else {
                    this.setState({ PhaseList: res.data, })
                }
            }).catch(function (error) {
                message.error("系统繁忙！")
                console.log(error)
            })
        })
    }
    Upload(f) {
        this.setState({ Cover: f.file, fileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}

export default EditorCourse;