
import React from 'react';
import { Input, Tabs, Select, Upload, Space, Button, Switch } from 'antd';

import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select;
const { TabPane } = Tabs;
class EditorCollection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            ID: 0,
            FileType: "其他",
            Header: "https://api2.yuexiaojing.com/v3/",
            FileCover: "",
            FileCoverList: [],
            File: "",
            FilesList: [],
            path: "",
            form: null,
            IsDownload:1,
        };
    }
    // 抽屉
    render() {
        return (
            <div style={{ width: "100%", }}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        display: 'flex',
                    }}
                >
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>名称</div>
                        <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                            this.setState({ Name: e.target.value, })
                        }} placeholder="名称" />
                    </Space>
                        <Space align="center" style={{ marginBottom: "20px" }}>
                            <div style={{ width: "60px", textAlign: 'right' }}>文件</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.FileUpload(f) }} fileList={this.state.FilesList}>
                                <Button icon={<UploadOutlined />}>上传</Button>
                            </Upload>
                        </Space>

                        <Space align="center" >
                            <div style={{ width: "60px", textAlign: 'right' }}>封面</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.FileCoverUpload(f) }} fileList={this.state.FileCoverList}>
                                <Button icon={<UploadOutlined />}>上传</Button>
                            </Upload>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>允许下载</div>
                            <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.IsDownload==1?true:false} onChange={
                                () => { this.setState({ IsDownload: this.state.IsDownload==1?2:1 }) }} />
                        </Space>
                </Space>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            ID: this.props.CollectionItem.ID,
            Name: this.props.CollectionItem.Name,
            FileType: this.props.CollectionItem.Type,
            File: this.props.CollectionItem.Path,
            path: this.props.CollectionItem.Path,
            IsDownload: this.props.CollectionItem.IsDownload,
            FileCover: this.props.CollectionItem.Cover ? this.props.CollectionItem.Cover : '',
        }, () => {
            var FileCovers = this.state.FileCover.split("/")
            var FileCoverslength = FileCovers.length - 1
            var FileCoversItem = FileCovers[FileCoverslength]
            //
            var Files = this.state.File.split("/")
            var Fileslength = Files.length - 1
            var FilesItem = Files[Fileslength]
            this.setState({
                FilesList: [{
                    uid: '-1',
                    name: FilesItem,
                    status: 'done',
                    url: this.state.Header + this.state.File,
                    thumbUrl: this.state.Header + this.state.File,
                }],
                FileCoverList: [{
                    uid: '-1',
                    name: FileCoversItem,
                    status: 'done',
                    url: this.state.Header + this.state.FileCover,
                    thumbUrl: this.state.Header + this.state.FileCover,
                }]
            })
        })
    }
    FileUpload(f) {
        this.setState({ File: f.file, FilesList: f.fileList, })
    }
    FileCoverUpload(f) {

        this.setState({ FileCover: f.file, FileCoverList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}

export default EditorCollection;