import React from 'react';
import { Input, Button, Space, Upload, Select, Switch } from 'antd';
import service from "../../../request"
const { Option } = Select;
class AddFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            File: "",
            Header: "https://api2.yuexiaojing.com/v3/",
            DirectoryID:0,
            FileList: [],
            CoverFileList:[],
            form:null,
            IsDownload:1,
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>文件名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.changeName(e)
                            }} />
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>文件：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadVideo(f) }} fileList={this.state.FileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传文件</Button>
                            </Upload>
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>文件封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadCover(f) }} fileList={this.state.CoverFileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传封面</Button>
                            </Upload>
                        </Space>
                        <Space align="center" >
                                <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>允许下载</div>
                                <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.IsDownload == 1 ? true : false} onChange={
                                    () => { this.setState({ IsDownload: this.state.IsDownload == 1 ? 2 : 1 }) }} />
                            </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {

        this.setState({
            DirectoryID: this.props.FileParentID,
        })
    }
    changeName(e) {
        this.setState({
            Name: e.target.value,
        })
    }

    // 文件
    UploadVideo(f) {
        this.setState({ File: f.file, FileList: f.fileList })
    }
    // 文件封面
    UploadCover(f) {
        this.setState({ Cover: f.file, CoverFileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default AddFile 