import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Switch, Modal, Space, Input } from 'antd';
import moment from 'moment/moment';
import AddCommodity from "../shoppingCenter/payCourse_Page/addCommodity"
import EditorCommodity from "../shoppingCenter/payCourse_Page/editorCommodity"


const { Search } = Input;
export default class PayCourse extends Component {
  constructor(props) {
    super(props)
    this.childAddCommodityRef = null
    this.childEditorCommodityRef = null
    this.state = {
      CommodityList: [],
      SchoolList: [],
      offset: 0,
      count: 0,
      page: 1,
      TableItem: {},
      IsGrounding:1,
      searchValue: "",
      AddVisible: false,
      AddLoading: false,
      EditorVisible: false,
      EditorLoading: false,
      DeleteVisible:false,
      DeleteLoading:false,
      ID:0,      

      Header: "https://api2.yuexiaojing.com/v3/"
    }
  }
  render() {
    const columns = [
      { title: '商品名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '封面', dataIndex: 'Phone', key: 'Phone',
        render: (text, record, index) => {
          return <div><img style={{ width: "50px", height: "50px" }} src={this.state.Header + record.Cover} /></div>
        }
      },
      {
        title: '价格', dataIndex: 'Price', key: 'Price',
        render: (text, record, index) => {
          return <div>
           {record.Price/100}
          </div>
        }
      },
      {
        title: '状态', dataIndex: 'IsGrounding', key: 'IsGrounding',
        render: (text, record, index) => {
          return <div>
            <Switch checkedChildren="上架" unCheckedChildren="下架" checked={record.IsGrounding==1?true:false} onChange={() => {this.setIsGrounding(record)  }} />
          </div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.editorShowDrawer(record)} style={{ color: "#0081FF", }} >编辑</Button>
            <Button onClick={() => this.setState({ID:record.ID},()=>{this.setState({DeleteVisible:true})})} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];
    const paginationProps = {
      current: this.state.page, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.count, // 总条数
      onChange: page => handlePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handlePageChange = (page) => {
      this.setState({ page: page })
      this.paging(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          <Search placeholder="搜索" style={{ width: "200px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} />
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddVisible: true })}>添加商品</Button>
        </div>
        <Drawer
          open={this.state.AddVisible}
          title="添加"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ AddVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.AddLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <AddCommodity ref={this.addCommodityRef} ></AddCommodity>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.AddCommoditySave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ AddVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
        <Drawer
          open={this.state.EditorVisible}
          title="修改"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorVisible: false })}
          destroyOnClose={true}
        >
          <Spin spinning={this.state.EditorLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
            <EditorCommodity TableItem={this.state.TableItem} ref={this.editorCommodityRef} ></EditorCommodity>
          </Spin>
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditorCommoditySave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
         {/* 删除 */}
         <Spin spinning={this.state.DeleteLoading} tip="加载中......" size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            title={null}
            footer={null}
            modalRender={(modal) => (
              modal
            )}
            // bodyStyle={{ padding: '0px' }}
            open={this.state.DeleteVisible}
            onCancel={() => this.setState({ DeleteVisible: false })}
            closable={true}
            width={400}
          >
            <div style={{ heighe: "205px", padding: "10px 20px" }}>
              <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
              <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => this.determineDelete()}>确认</Button>
                <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => this.setState({ DeleteVisible: false })}>取消</Button>
              </div>
            </div>
          </Modal>
        </Spin>
        <Table columns={columns}
          // rowSelection={rowSelection}
          dataSource={this.state.CommodityList}
          className="table"
          bordered={true}
          pagination={paginationProps}
          rowKey={record => record.ID}
          size='small'
        />
      </div>
    )
  }
  componentDidMount() {
    this.paging(this.state.page)
  }
 
  renderSchool(ID) {
    let c = this.state.SchoolList.find(c => {
      if (c.ID === ID) {
        return c
      }
    })
    if (c) {
      return c.Name
    }
    return ""
  }

  onSearch() {
this.paging(this.state.page)
  }

  paging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/commodity",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        name: this.state.searchValue,
        category:2,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ CommodityList: res.data, count: res.count, page: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  addCommodityRef = (ref) => {
    this.childAddCommodityRef = ref
  }
  AddCommoditySave() {
    var form = new FormData
    if (this.childAddCommodityRef.state.Name != "" && this.childAddCommodityRef.state.Cover != "" && this.childAddCommodityRef.state.Inventory != "" && this.childAddCommodityRef.state.Price != ""&&this.childAddCommodityRef.state.PhaseID!=0 ) {
      form.set("Name", this.childAddCommodityRef.state.Name)
      form.set("Cover", this.childAddCommodityRef.state.Cover)
      form.set("CourseID", this.childAddCommodityRef.state.PhaseID)
      form.set("Price", this.childAddCommodityRef.state.Price*100)
      form.set("IsGrounding", this.childAddCommodityRef.state.IsGrounding)
      form.set("Note", this.childAddCommodityRef.state.Note)
      form.set("Category", 2)
      this.setState({
        AddLoading: true,
      })
      service.request({
        url: "/admin/commodity",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,
      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddLoading: false,
            AddVisible: false,
          })
          this.paging(this.state.page)
          message.success('添加成功');
        } else {
          this.setState({
            AddLoading: false,
          })
          if (res.code == 452) {
            message.error('添加失败,名字重复');
          } else {
            message.error('添加失败,请重试');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
  editorShowDrawer(item) {
    this.setState({
      EditorVisible: true,
      TableItem: item,
    })
  }
  editorCommodityRef = (ref) => {
    this.childEditorCommodityRef = ref
  }
  EditorCommoditySave() {
    var form = new FormData
    if (this.childEditorCommodityRef.state.Name != "" && this.childEditorCommodityRef.state.Cover != "" && this.childEditorCommodityRef.state.Inventory != "" && this.childEditorCommodityRef.state.Price != ""&&this.childEditorCommodityRef.state.PhaseID!=0 ) {
      form.set("ID", this.childEditorCommodityRef.state.ID)
      form.set("Name", this.childEditorCommodityRef.state.Name)
      form.set("Cover", this.childEditorCommodityRef.state.Cover)
      form.set("CourseID", this.childEditorCommodityRef.state.PhaseID)
      form.set("Price", this.childEditorCommodityRef.state.Price*100)
      form.set("IsGrounding", this.childEditorCommodityRef.state.IsGrounding)
      form.set("Note", this.childEditorCommodityRef.state.Note)
      form.set("Category", 2)
      this.setState({
        EditorLoading: true,
      })
      service.request({
        url: "/admin/commodity",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,
      }).then(res => {
        if (res.code == 200) {
          this.paging(this.state.page)
          message.success('添加成功');
          this.setState({
            EditorLoading: false,
            EditorVisible: false,
          })
        } else {
          this.setState({
            EditorLoading: false,
          })
          if (res.code == 452) {
            message.error('添加失败,名字重复');
          } else {
            message.error('添加失败,请重试');
          }
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
  setIsGrounding(item) {
    service.request({
      url: "/admin/commodity/updateIsGrounding",
      method: "put",
      data: {
        ID: item.ID,
        IsGrounding: item.IsGrounding==1?2:1,
      },
    }).then(res => {
      if (res.code == 200) {
        this.paging(this.state.page)
        message.success('修改成功');
      } else {
        message.error('修改失败,请重试');
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
  determineDelete() {
    this.setState({
      DeleteLoading: true
    })
    service.request({
      url: "/admin/commodity",
      method: "delete",
      data: {
        ID: this.state.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.getUserList(this.state.page)
        this.setState({
          DeleteLoading: false,
          DeleteVisible: false
        })
        message.success('删除成功');
      } else {
       
          message.error('删除失败，请重试');
        this.setState({
          DeleteLoading: false,
        })
       
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
}
