import React from 'react';
import { Input, Button, Space, Upload, Select } from 'antd';
import service from "../../../request"
import '../../../index.css'

class EditorCourse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID:0,
            Path:"",
            Name: "",
            Cover: "",
            FileList: [],
            Header: "https://api2.yuexiaojing.com/v3/",
            form: null
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center" style={{ width: "400px", }}>
                            <div style={{ width: "100px", textAlign: 'right' }}>课程名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.setState({
                                    Name: e.target.value,
                                })
                            }} />
                        </Space>
                        <Space align="center" style={{ width: "400px", }} >
                            <div style={{ width: "100px", textAlign: 'right' }}>封面：</div>
                            <Upload style={{width: "300px",}} maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.FileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传</Button>
                            </Upload>
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
     this.setState({
        ID:this.props.TableItem.ID,
        Name:this.props.TableItem.Name,
        Path:this.props.TableItem.Path,
        Cover:this.props.TableItem.Cover,
     },()=>{
        var Covers = this.state.Cover.split("/")
        var Coverslength = Covers.length - 1
        var CoversItem = Covers[Coverslength]
        this.setState({
            FileList: [{
                uid: '-1',
                name: CoversItem,
                status: 'done',
                url: this.state.Header + this.state.Cover,
                thumbUrl: this.state.Header + this.state.Cover,
            }]
        })
     })
    }
    Upload(f) {
        this.setState({ Cover: f.file, FileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default EditorCourse