import { Route, Redirect } from 'react-router-dom'
import { ShopOutlined,SnippetsOutlined,ClusterOutlined,VideoCameraOutlined } from '@ant-design/icons';
import cookie from "react-cookies";
import Login from "../pages/login";
import User from "../pages/tissue/user";
import School from "../pages/tissue/school";
import Announcement from "../pages/tissue/announcement";
import TrainingCourse from "../pages/courseManage/trainingCourse";
import Phase from "../pages/courseManage/courseSystem/phase";
import Plate from "../pages/courseManage/courseSystem/plate";
import Courses from "../pages/courseManage/courseSystem/courses";
import Activity from "../pages/courseManage/activity";
import CampusOperations from "../pages/courseManage/campusOperations";
import PayCourse from "../pages/shoppingCenter/payCourse";
import Commodity from "../pages/shoppingCenter/commodity";
import OrderManage from "../pages/shoppingCenter/orderManage";
import WebsitesSettings from "../pages/shoppingCenter/websitesSettings";
import LiveBroadcast from "../pages/liveBroadcast/index";
import RemoteInfo from "../pages/tissue/remoteInfo"


export const mainRoutes = [{
    path: '/login',
    component: Login
}]

export const adminRoutes = [
    {
        path: '/admin/tissue',
        isShow: true,
        exact: true,
        title: '组织架构',
        icon: <ClusterOutlined />,
        permissionID: 1,
        children: [
            {
                path: '/admin/tissue/user',
                isShow: true,
                exact: true,
                title: '用户管理',
                component: User,
            },
            {
                path: '/admin/tissue/remoteInfo',
                isShow: true,
                exact: true,
                title: '异地警告',
                component: RemoteInfo,
            },
            {
                path: '/admin/tissue/school',
                isShow: true,
                exact: true,
                title: '学校管理',
                component: School,
            },
            {
                path: '/admin/tissue/announcement',
                isShow: true,
                exact: true,
                title: '公告管理',
                component: Announcement,
            },
        ]

    },
    {
        path: '/admin/courseManage',
        isShow: true,
        exact: true,
        title: '课程管理',
        icon: <SnippetsOutlined />,
        permissionID: 2,
        children: [
            {
                path: '/admin/courseManage/trainingCourse',
                isShow: true,
                exact: true,
                title: '师训课程',
                component: TrainingCourse,
            },
            {
                path: '/admin/courseManage/courseSystem',
                isShow: true,
                exact: true,
                title: '课程体系',
                icon: <ShopOutlined />,
                permissionID:10,
                children: [
                    {
                        path: '/admin/courseManage/courseSystem/plate',
                        isShow: true,
                        exact: true,
                        title: '板块',
                        component: Plate,
                    },
                    {
                        path: '/admin/courseManage/courseSystem/phase',
                        isShow: true,
                        exact: true,
                        title: '阶段',
                        component: Phase,
                    },
                    {
                        path: '/admin/courseManage/courseSystem/courses',
                        isShow: true,
                        exact: true,
                        title: '课程',
                        component: Courses,
                    },
                ]
            },
            {
                path: '/admin/courseManage/activity',
                isShow: true,
                exact: true,
                title: '全年活动',
                component: Activity,
            },
            {
                path: '/admin/courseManage/campusOperations',
                isShow: true,
                exact: true,
                title: '校区运营',
                component: CampusOperations,
            },
        ]
    },
    {
        path: '/admin/shoppingCenter',
        isShow: true,
        exact: true,
        title: '商城管理',
        icon: <ShopOutlined />,
        permissionID: 3,
        children: [
            {
                path: '/admin/shoppingCenter/payCourse',
                isShow: true,
                exact: true,
                title: '付费课程',
                component: PayCourse,
            },
            {
                path: '/admin/shoppingCenter/commodity',
                isShow: true,
                exact: true,
                title: "周边订购",
                component: Commodity,
            },
            {
                path: '/admin/shoppingCenter/orderManage',
                isShow: true,
                exact: true,
                title: '订单管理',
                component: OrderManage,
            },
            // {
            //     path: '/admin/shoppingCenter/websitesSettings',
            //     isShow: true,
            //     exact: true,
            //     title: '网站设置',
            //     component: WebsitesSettings,
            // },
        ]
    },
    {
        path: '/admin/liveBroadcast',
        isShow: true,
        exact: true,
        title: '直播管理',
        icon: <VideoCameraOutlined />,
        permissionID: 4,
        component: LiveBroadcast,
    },

]


export const renderRoutesMain = (Routes) => {
    // var permissions = cookie.load("permissions")
    // var ids = []
    // permissions.forEach(p => { ids.push(p.ID) })
    return Routes.map((route, index) => {
        return (
            <div key={index}>
                < Route
                    path={route.path}
                    isShow={route.isShow}
                    exact={route.exact}
                    render={props => {
                        return <route.component {...props} />
                    }}
                />
                {route.children && renderRoutesMain(route.children)}
            </div>
        )
    })
}
