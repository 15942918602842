import React from 'react';
import { Input, Button, Switch, Space, DatePicker,Select,message } from 'antd';
import service from "../../request"
import { FileOutlined } from '@ant-design/icons'
import cookie from "react-cookies";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
const { TextArea } = Input;
class AddAnnouncement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Title:'',
            Content:"",
        };
    }
    render() {
        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>公告名称：</div>
                            <Input style={{ width: "300px",  }} value={this.state.Title} onChange={(e)=>{this.setState({Title:e.target.value})}}/>
                        </Space>
                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>公告内容：</div>
                            <TextArea
                            style={{width:"300px",}}
                            value={this.state.Content}
                            onChange={(e) => { // this.state.formdata.set("Note", e.target.value,)
                                this.setState({ Content: e.target.value, })
                            }}
                            placeholder="公告内容"
                            autoSize={{
                                minRows: 3,
                                // maxRows: 5,
                            }} />
                        </Space>
                        
                        <Space align="center" >
                            <div style={{ width: "100px", textAlign: 'right',  }}>发布时间：</div>
                            <DatePicker locale={locale} style={{ width: "300px" }} onChange={(date, dateString) => this.onChangeTime(date, dateString)}/>  
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {

    }
    onChangeTime(date, dateString) {
        this.setState({
            Time: new Date(date).getTime()
        })
    }
}
export default AddAnnouncement;