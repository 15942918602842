import React, { Component } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Switch, Radio, Space, Input, Modal } from 'antd';
import moment from 'moment/moment';
import EditorLiveBroadcast from "../liveBroadcast/editorLiveBroadcast"
const { Search } = Input;
export default class LiveBroadcast extends Component {
  constructor(props) {
    super(props)
    this.childEditorLiveBroadcastRef = null
    this.state = {
      LiveList: [],
      offset: 0,
      count: 0,
      page: 1,
      // Header: "http://10.18.1.99:8097/admin",
      TableItem: {},
      EditorVisible: false,
      Header: "https://api2.yuexiaojing.com/v3/"
    }
  }
  render() {
    const columns = [
      { title: '直播间名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '活动封面', dataIndex: 'Cover', key: 'Cover',
        render: (text, record, index) => {
          return <div><img style={{ width: "50px", height: "50px" }} src={this.state.Header + record.Cover} /></div>
        }
      },
      {
        title: '简介', dataIndex: 'Note', key: 'Note',
      },
      {
        title: '直播间状态', dataIndex: 'State', key: 'State',
        render: (text, record, index) => {
          return <div>
            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={record.State == 2 ? true : false} onChange={
              () => { this.setIsDisabled(record) }} />
          </div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.setState({ TableItem: record }, () => { this.setState({ EditorVisible: true }) })} style={{ color: "#0081FF", }} >编辑</Button>
          </div>
        }
      },
    ];
    const paginationProps = {
      current: this.state.page, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.count, // 总条数
      onChange: page => handlePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handlePageChange = (page) => {
      this.setState({ page: page })
      this.getLiveBroadcast(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          <Search placeholder="搜索" style={{ width: "200px", marginRight: "20px" }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onSearch={(value) => this.onSearch(value)} />
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddVisible: true })}>添加</Button>
        </div>

        <Table columns={columns}
          // rowSelection={rowSelection}
          dataSource={this.state.LiveList}
          className="table"
          bordered={true}
          pagination={paginationProps}
          rowKey={record => record.ID}
          size='small'
        />
        <Drawer
          open={this.state.EditorVisible}
          title="修改"
          width='500px'
          keyboard={true}//Esc关闭
          onClose={() => this.setState({ EditorVisible: false })}
          destroyOnClose={true}
        >
          {/* <Spin spinning={this.state.EditorLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large"> */}
          <EditorLiveBroadcast TableItem={this.state.TableItem} ref={this.editorLiveBroadcastRef} ></EditorLiveBroadcast>
          {/* </Spin> */}
          <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
            <Space>
              <Button onClick={() => this.EditoSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
              <Button onClick={() => this.setState({ EditorVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
            </Space>
          </div>
        </Drawer>
      </div>
    )
  }
  componentDidMount() {
    this.getLiveBroadcast(this.state.page)
  }

  getLiveBroadcast(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/liveRoom",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        name: this.state.searchValue,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ LiveList: res.data, count: res.count, page: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }

  setIsDisabled(item) {
    service.request({
      url: "/admin/liveRoom/updateState",
      method: "put",
      data: {
        ID: item.ID,
      },
    }).then(res => {
      if (res.code == 200) {
        this.getLiveBroadcast(this.state.page)
        message.success('修改成功');

      } else {
        message.error('修改失败,请重试');
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }

  editorLiveBroadcastRef = (ref) => {
    this.childEditorLiveBroadcastRef = ref
  }
  EditoSave() {
    var form = new FormData
    if (this.childEditorLiveBroadcastRef.state.Name != "" && this.childEditorLiveBroadcastRef.state.Cover != "" ) {
      form.set("ID", this.childEditorLiveBroadcastRef.state.ID)
      form.set("Name", this.childEditorLiveBroadcastRef.state.Name)
      form.set("Cover", this.childEditorLiveBroadcastRef.state.Cover)
      form.set("Note", this.childEditorLiveBroadcastRef.state.Note)
      this.setState({
        EditorLoading: true,
      })
      service.request({
        url: "/admin/liveRoom",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,
      }).then(res => {
        if (res.code == 200) {
          this.getLiveBroadcast(this.state.page)
          message.success('修改成功');
          this.setState({
            EditorVisible: false,
          })
        } else {
            message.error('修改失败,请重试');
        }
      }).catch(function (error) {
        message.error('系统繁忙');
        console.log(error)
      })
    } else {
      message.error('输入不能为空');
    }
  }
}
