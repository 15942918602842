
import React from 'react';
import { Input, message, Select, Upload, Space, Radio, Button, } from 'antd';
// import { Helper, } from "@cs/react"
import service from "../../../../request"
import moment from 'moment';

import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select;
class AddCourse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            PhaseID: 0,
            Name: "",
            Cover: "",
            PhaseList: [],
            Type: 0,
            fileList: [],
            typeList:[{Name:"普通课程",Type:1},{Name:"合集课程",Type:2},],
        };
    }
    // 抽屉
    render() {
        return (
            <div style={{ width: "100%", }}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        display: 'flex',
                    }}
                >
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>阶段</div>
                        <Select
                            showSearch
                            style={{ width: "300px", marginRight: "20px" }}
                            placeholder="阶段"
                            optionFilterProp="children"
                            defaultActiveFirstOption={true}
                            dropdownMatchSelectWidth={true}
                            // value={this.state.PhaseList}
                            // defaultValue={this.state.PhaseList[0]}
                            onChange={(e) => {
                                this.setState({
                                    PhaseID: e,
                                })
                            }}
                        >
                            {this.state.PhaseList.map((item, index) => (
                                <Option key={index}  value={item.ID} >
                                    {item.Name}
                                </Option>
                            ))}
                        </Select>
                    </Space>
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>名称</div>
                        <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                            this.setState({ Name: e.target.value, })
                        }} placeholder="名称" />
                    </Space>
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>封面</div>
                        <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}>
                            <Button icon={<UploadOutlined />}>上传</Button>
                        </Upload>
                    </Space>
                    <Space align="center">
                        <div style={{ width: "60px", textAlign: 'right' }}>类型</div>
                        <Radio.Group buttonStyle="solid">
                            {
                                this.state.typeList.map((item, index) => {
                                    return <Radio.Button onChange={() => this.checked(item)} value={item.Type} style={{ marginRight: "20px" }} key={index}>{item.Name}</Radio.Button>
                                })
                            }
                        </Radio.Group>
                    </Space>
                </Space>
            </div>

        );
    }
    componentDidMount() {
        this.getPhasePaging()
    }
    checked(item) {
        this.setState({
            Type: item.Type
        })
    }
    getPhasePaging() {
        return new Promise(resolve => {
            service.request({
                url: "/admin/phase",
                method: "get",
                params: {
                    offset: 0,
                    limit: 0,
                }
            }).then(res => {
                if (res.code != 200) {
                    message.error("系统繁忙！")
                } else {
                    this.setState({ PhaseList: res.data, })
                }
            }).catch(function (error) {
                message.error("系统繁忙！")
                console.log(error)
            })
        })
    }
    Upload(f) {
        this.setState({ Cover: f.file, fileList: f.fileList })
     
    }
    beforeUpload() {
        return false
    }
}

export default AddCourse;