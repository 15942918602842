
import React from 'react';
import { Input, Tabs, Select, Upload, Space, Button, Switch } from 'antd';
// import { Dropdown, } from "@cs/react"
import service from "../../../../request"
import moment from 'moment';

import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select;
const { TabPane } = Tabs;
class AddFiles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
           file:"",
           fileList:[],
           IsDownload:1,
        };
    }
    // 抽屉
    render() {
        return (
            <div style={{ width: "100%", }}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        display: 'flex',
                    }}
                >
                    <Space align="center">
                        <div style={{ width: "100px", textAlign: 'right' }}>名称</div>
                        <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                            this.setState({ Name: e.target.value, })
                        }} placeholder="名称" />
                    </Space>
                    <Space align="center">
                        <div style={{ width: "100px", textAlign: 'right' }}>文件</div>
                        <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.FileUpload(f) }} fileList={this.state.fileList}>
                            <Button icon={<UploadOutlined />}>上传</Button>
                        </Upload>
                    </Space>
                    <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>允许下载</div>
                            <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.IsDownload==1?true:false} onChange={
                                () => { this.setState({ IsDownload: this.state.IsDownload==1?2:1 }) }} />
                        </Space>
                </Space>
            </div>
        );
    }
    componentDidMount() {
    }
    FileUpload(f) {
        this.setState({ file: f.file, fileList: f.fileList }, () => { })
    }
    beforeUpload() {
        return false
    }
}

export default AddFiles;