import axios from "axios";
import cookie from "react-cookies";


// 创建实例
const service = axios.create({
  baseURL:'https://api2.yuexiaojing.com/v3',
  // baseURL: 'http://127.0.0.1:8097',
})



// 请求拦截
service.interceptors.request.use(
  function (config) {
    config.headers.token = cookie.load("admintoken")
    return config;
  }, function (error) {
    return Promise.reject(error);
  }
)

service.interceptors.response.use(
  function (response) {
    // console.log("响应成功：");
    // console.log(response);
    // 响应数据处理
    if (response.data.code === 499) {
      cookie.remove("admintoken")
      cookie.remove("user")
      window.location.reload()
    }
    return response.data;
  }, function (error) {
    // console.log("响应错误：");
    // console.log(error);
    // 响应错误处理
    return Promise.reject(error);
  }
);

export default service;
