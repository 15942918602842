
import React from 'react';
import { Input, Tabs, Select, Upload, Space, Button, message, Switch } from 'antd';
// import { Dropdown, } from "@cs/react"
import service from "../../../../request"
import moment from 'moment';

import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select;
const { TabPane } = Tabs;
class AddClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            Type: 0,
            ImgList: [],
            FileType: "图片",
            //
            CourseList: [],
            CourseID: 0,
            //
            PhaseList: [],
            PhaseID: 0,
            //
            FileCover: "",
            FileCoverList: [],
            File: "",
            FilesList: [],
            //
            ImageFilesList: [],
            IsDownload: 1,
            ImageFile: "",
            header: "https://api2.yuexiaojing.com/v3/"
        };
    }
    // 抽屉
    render() {
        return (
            <div style={{ width: "100%", }}>
                <Space
                    direction="vertical"
                    size="large"
                    style={{
                        display: 'flex',
                    }}
                >
                    <Space align="center">
                        <div style={{ width: "100px", textAlign: 'right' }}>阶段</div>
                        <Select
                            showSearch
                            style={{ width: "300px", marginRight: "20px" }}
                            placeholder="课程"
                            optionFilterProp="children"
                            defaultActiveFirstOption={true}
                            dropdownMatchSelectWidth={true}
                            value={this.state.PhaseID}
                            onSelect={(LabeledValue, option) => {
                                this.setState({
                                    PhaseID: LabeledValue,
                                }, () => { this.getCoursePaging() })
                            }}
                        >
                            {this.state.PhaseList.map((item, index) => (
                                <Option key={index} type={item.Type} value={item.ID} >
                                    {item.Name}
                                </Option>
                            ))}
                        </Select>
                    </Space>
                    <Space align="center">
                        <div style={{ width: "100px", textAlign: 'right' }}>课程</div>
                        <Select
                            showSearch
                            style={{ width: "300px", marginRight: "20px" }}
                            placeholder="课程"
                            optionFilterProp="children"
                            defaultActiveFirstOption={true}
                            dropdownMatchSelectWidth={true}
                            value={this.state.CourseID}
                            onSelect={(LabeledValue, option) => {
                                this.setState({
                                    CourseID: LabeledValue,
                                    Type: option.type,
                                })
                            }}
                        >
                            {this.state.CourseList.map((item, index) => (
                                <Option key={index} type={item.Type} value={item.ID} >
                                    {item.Name}
                                </Option>
                            ))}
                        </Select>

                    </Space>
                    <Space align="center">
                        <div style={{ width: "100px", textAlign: 'right' }}>名称</div>
                        <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                            this.setState({ Name: e.target.value, })
                        }} placeholder="名称" />
                    </Space>
                    {this.state.Type == 1 ? <Space align="center">
                        <div style={{ width: "100px", textAlign: 'right' }}>封面</div>
                        <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.ImgUpload(f) }} fileList={this.state.ImgList}>
                            <Button icon={<UploadOutlined />}>上传</Button>
                        </Upload>
                    </Space> :
                        <div>
                            <div style={{ marginBottom: "20px" }}>
                                <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right' }}>文件</div>
                                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.FileUpload(f) }} fileList={this.state.FilesList}>
                                        <Button icon={<UploadOutlined />}>上传</Button>
                                    </Upload>
                                </Space>
                            </div>
                            <div style={{marginBottom:"20px"}}>
                                <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right' }}>封面</div>
                                    <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.FileCoverUpload(f) }} fileList={this.state.FileCoverList}>
                                        <Button icon={<UploadOutlined />}>上传</Button>
                                    </Upload>
                                </Space>
                            </div>
                            <Space align="center" >
                                <div style={{ width: "100px", textAlign: 'right', marginRight: "10px" }}>允许下载</div>
                                <Switch checkedChildren="是" unCheckedChildren="否" checked={this.state.IsDownload == 1 ? true : false} onChange={
                                    () => { this.setState({ IsDownload: this.state.IsDownload == 1 ? 2 : 1 }) }} />
                            </Space>
                        </div>
                    }
                </Space>
            </div>
        );
    }
    componentDidMount() {
        this.getPhasePaging()
    }
    getPhasePaging() {
        return new Promise(resolve => {
            service.request({
                url: "/admin/phase",
                method: "get",
                params: {
                    offset: 0,
                    limit: 0,
                }
            }).then(res => {
                if (res.code != 200) {
                    message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
                } else {
                    this.setState({ PhaseList: res.data, PhaseID: res.data.length > 0 ? res.data[0].ID : 0, }, () => {
                        if (this.state.PhaseID != 0) {
                            this.getCoursePaging(1)
                        }
                    })
                }
            }).catch(function (error) {
                console.log(error)
            })
        })
    }
    getCoursePaging() {
        service.request({
            url: "/admin/course",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
                phaseID: this.state.PhaseID,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error({ content: '数据加载失败，请重试', style: { marginTop: '10vh', }, });
            } else {
                this.setState({ CourseList: res.data, CourseID: res.data.length > 0 ? res.data[0].ID : 0, Type: res.data.length > 0 ? res.data[0].Type : 1, })
            }
        })
    }
    ImgUpload(f) {
        this.setState({ Cover: f.file, ImgList: f.fileList })
    }


    FileUpload(f) {
        this.setState({ File: f.file, FilesList: f.fileList })
    }
    FileCoverUpload(f) {
        this.setState({ FileCover: f.file, FileCoverList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}

export default AddClass;