import React, { Component } from 'react'
import service from '../../request';
import { Button, Table, Spin, Drawer, message, Switch, Radio, Space, Input, Modal } from 'antd';
import moment from 'moment/moment';


const { Search } = Input;
export default class PermissionsUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            PhaseList: [],
            PermissionsList: [],
            ID: 0,
            selectList: [],
            Type:0,
        }
    }
    render() {
        const columns = [
            { title: '阶段名称', dataIndex: 'Name', key: 'Name' },

        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectList,
            onChange: (selectedRowKeys, selectedRows) => rowSelectionChange(selectedRowKeys, selectedRows)
        };
        const rowSelectionChange = (selectedRowKeys, selectedRows) => {
            this.setState({
                PermissionsList: selectedRows,
                selectList: selectedRowKeys
            })
        }

        return (
            <div style={{ padding: "10px",overflow:"auto",paddingBottom:"60px" }}>
                <Table columns={columns}
                    rowSelection={rowSelection}
                    //   selectedRowKeys={this.state.PermissionsList}
                    dataSource={this.state.PhaseList}
                    className="table"
                    bordered={true}
                    rowKey={record => record.ID}
                    size='small'
                    pagination={false}
                    key={record => record.ID}
                />
            </div>
        )
    }
    componentDidMount() {
        var IDs = []
        if (this.props.Type == 1) {
            if (this.props.TableItem.ViewPhase.length > 0) {
                this.props.TableItem.ViewPhase.map((item, index) => {
                    IDs.push(item.ID)
                })
            }
            this.setState({
                ID: this.props.TableItem.ID,
                selectList: IDs,
                Type:this.props.Type,
            })
        } else {
            if (this.props.TableItem.DownloadPhase.length > 0) {
                this.props.TableItem.DownloadPhase.map((item, index) => {
                    IDs.push(item.ID)
                })
            }
            this.setState({
                ID: this.props.TableItem.ID,
                selectList: IDs,
                Type:this.props.Type,
            })
        }
        this.getMenu()
    }
    getMenu() {
        service.request({
            url: "/admin/phase",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ PhaseList: res.data })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
}
