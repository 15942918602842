import React, { Component } from 'react'
import { UpOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Tree, Button, Table, Spin, Drawer, message, Modal, Radio, Space, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../request';
import '../../index.css'
import AddDirectory from './campusOperations_Page/addDirectory';
import EditorDirectory from './campusOperations_Page/editorDirectory';
import AddFile from './campusOperations_Page/addFile';
import EditorFile from './campusOperations_Page/editorFile';

export default class CampusOperations extends Component {
  constructor(props) {
    super(props)
    this.childAddActivityRef = null
    this.childEditorDirectoryRef = null
    this.childAddFileRef = null
    this.childEditorFileRef = null
    this.treeRef = React.createRef()
    this.state = {
      ParentID: 0,
      Header: "https://api2.yuexiaojing.com/v3/",
      treeData: [], //存放初始化加载的菜单内容，即第一层级菜单内容
      TreeNodeData: [], //存放获取的子菜单内容
      TableItem: {},
      FileParentID: 0,
      // 目录
      AddDirectoryVisible: false,
      AddDirectoryLoading: false,
      EditorDirectoryVisible: false,
      EditorDirectoryLoading: false,
      DeleteDirectoryVisible: false,
      DeleteDirectoryLoading: false,
      // 文件
      FileList: [],
      FileCount: 0,
      FilePage: 0,
      FileItem: {},
      AddFileVisible: false,
      AddFileLoading: false,
      EditorFileVisible: false,
      EditorFileLoading: false,
      DeleteFileVisible: false,
      DeleteFileLoading: false,
      loadedKeys: [],
    }
  }
  render() {
    const FileColumns = [
      { title: '名称', dataIndex: 'Name', key: 'Name' },
      {
        title: '封面', dataIndex: 'Phone', key: 'Phone',
        render: (text, record, index) => {
          return <div><img style={{ width: "50px", height: "50px" }} src={record.Cover?this.state.Header + record.Cover:this.state.Header +record.Path} /></div>
        }
      },
      {
        title: '下载', dataIndex: 'Cover', key: 'Cover', render: (text, record, index) => {
          return <div >
            {record.IsDownload == 1 ? "允许" : "不允许"}
          </div>
        }
      },
      {
        title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
          return <div>
            <Button onClick={() => this.setState({ FileItem: record, FileParentID: record.ID }, () => { this.setState({ EditorFileVisible: true }) })} style={{ color: "#0081FF", }} >编辑</Button>
            <Button onClick={() => this.setState({ DeleteFileVisible: true, FileItem: record }, () => { this.setState({ DeleteFileVisible: true }) })} style={{ marginLeft: "20px", color: "#FB5451", }}>删除</Button>
          </div>
        }
      },
    ];

    const paginationProps_File = {
      current: this.state.FilePage, //当前页码
      pageSize: 10, // 每页数据条数
      showTotal: (total) => (
        <span>共{total}条</span>
      ),
      total: this.state.FileCount, // 总条数
      onChange: page => handleFilePageChange(page), //改变页码的函数
      hideOnSinglePage: false,
      showSizeChanger: false,
    }
    const handleFilePageChange = (page) => {
      this.setState({ FilePage: page })
      this.FilePaging(page)
    }
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: "50px", margin: "10px 0px", padding: "0px 10px", background: "#fff", display: "flex", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", }}>
          <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }} onClick={() => this.setState({ AddDirectoryVisible: true })}>添加目录</Button>

          {/* 添加活动 */}
          <Drawer
            open={this.state.AddDirectoryVisible}
            title="添加"
            width='500px'
            keyboard={true}//Esc关闭
            onClose={() => this.setState({ AddDirectoryVisible: false })}
            destroyOnClose={true}
          >
            <Spin spinning={this.state.AddDirectoryLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
              <AddDirectory ParentID={this.state.ParentID} ref={this.addDirectoryRef} ></AddDirectory>
            </Spin>
            <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
              <Space>
                <Button onClick={() => this.AddDirectorySave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => this.setState({ AddDirectoryVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
              </Space>
            </div>
          </Drawer>
          {/* 修改活动 */}
          <Drawer
            open={this.state.EditorDirectoryVisible}
            title="修改"
            width='500px'
            keyboard={true}//Esc关闭
            onClose={() => this.setState({ EditorDirectoryVisible: false })}
            destroyOnClose={true}
          >
            <Spin spinning={this.state.EditorDirectoryLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
              <EditorDirectory TableItem={this.state.TableItem} ref={this.editorDirectoryRef} ></EditorDirectory>
            </Spin>
            <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
              <Space>
                <Button onClick={() => this.EditorDirectorySave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => this.setState({ EditorDirectoryVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
              </Space>
            </div>
          </Drawer>
          {/* 删除目录 */}
          <Spin spinning={this.state.DeleteDirectoryLoading} tip="加载中......" size="large">
            <Modal
              destroyOnClose={true}
              centered={true}
              title={null}
              footer={null}
              modalRender={(modal) => (
                modal
              )}
              // bodyStyle={{ padding: '0px' }}
              open={this.state.DeleteDirectoryVisible}
              onCancel={() => this.setState({ DeleteDirectoryVisible: false })}
              closable={true}
              width={400}
            >
              <div style={{ heighe: "205px", padding: "10px 20px" }}>
                <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => { this.VerifyDeleteDirectory() }}>确认</Button>
                  <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteDirectoryVisible: false }) }}>取消</Button>
                </div>
              </div>
            </Modal>
          </Spin>
          {/* 添加文件 */}
          <Drawer
            open={this.state.AddFileVisible}
            title="添加"
            width='500px'
            keyboard={true}//Esc关闭
            onClose={() => this.setState({ AddFileVisible: false })}
            destroyOnClose={true}
          >
            <Spin spinning={this.state.AddFileLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
              <AddFile FileParentID={this.state.FileParentID} ref={this.addFileRef} ></AddFile>
            </Spin>
            <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
              <Space>
                <Button onClick={() => this.AddFileSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => this.setState({ AddFileVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
              </Space>
            </div>
          </Drawer>
          {/* 修改文件 */}
          <Drawer
            open={this.state.EditorFileVisible}
            title="修改"
            width='500px'
            keyboard={true}//Esc关闭
            onClose={() => this.setState({ EditorFileVisible: false })}
            destroyOnClose={true}
          >
            <Spin spinning={this.state.EditorFileLoading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
              <EditorFile FileItem={this.state.FileItem} ref={this.editorFileRef} ></EditorFile>
            </Spin>
            <div style={{ position: "absolute", left: "0px", bottom: "0px", width: "100%", height: "60px", borderTop: "1px solid #ccc", padding: ' 14px 20px' }}>
              <Space>
                <Button onClick={() => this.EditorFileSave()} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", }}>确定</Button>
                <Button onClick={() => this.setState({ EditorFileVisible: false })} style={{ border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>取消</Button>
              </Space>
            </div>
          </Drawer>
          {/* 删除文件 */}
          <Spin spinning={this.state.DeleteFileLoading} tip="加载中......" size="large">
            <Modal
              destroyOnClose={true}
              centered={true}
              title={null}
              footer={null}
              modalRender={(modal) => (
                modal
              )}
              // bodyStyle={{ padding: '0px' }}
              open={this.state.DeleteFileVisible}
              onCancel={() => this.setState({ DeleteFileVisible: false })}
              closable={true}
              width={400}
            >
              <div style={{ heighe: "205px", padding: "10px 20px" }}>
                <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Button type="primary" style={{ background: "#0081FF", border: "1px solid #0081FF", marginRight: "40px" }} onClick={() => { this.VerifyDeleteFile() }}>确认</Button>
                  <Button style={{ border: "1px solid #0081FF", color: "#0081FF" }} onClick={() => { this.setState({ DeleteFileVisible: false }) }}>取消</Button>
                </div>
              </div>
            </Modal>
          </Spin>
        </div>
        <div style={{ width: '100%', height: '100%', display: "flex" }}>
          <div style={{ flex: "49" }}>
            <Tree
              loadData={this.onLoadData} defaultSelectedKeys='0'
              ref={this.treeRef}
              loadedKeys={this.state.loadedKeys}
              expandedKeys={this.state.loadedKeys}
              onLoad={this.onLoad}
              onSelect={(selectedKeys, info) => this.onSelect(selectedKeys, info)}
              titleRender={(item) => {
                return <div style={{ display: "flex", height: "50px", lineHeight: "50px", }}>
                  <div style={{ width: "150px" }}>{item.Name}</div>
                  <img style={{ width: "40px", height: "40px", margin: "5px" }} src={this.state.Header + item.Cover}></img>
                  <div style={{}}>
                    {item.IsChild == 1 ? <Button onClick={() => { this.setState({ ParentID: item.ID }, () => { this.setState({ AddDirectoryVisible: true }) }) }} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", marginLeft: "80px" }}>添加子目录</Button> :
                      <Button onClick={() => { this.setState({ FileParentID: item.ID }, () => { this.setState({ AddFileVisible: true }) }) }} style={{ background: "#0081FF", border: "1px solid #0081FF", color: "#fff", marginLeft: "80px" }}>添加文件</Button>}
                    <Button onClick={() => { this.setState({ TableItem: item }, () => { this.setState({ EditorDirectoryVisible: true }) }) }} style={{ background: "#FFF", border: "1px solid #0081FF", color: "#0081FF", marginLeft: "20px" }}>编辑</Button>
                    <Button onClick={() => { this.setState({ TableItem: item }, () => { this.setState({ DeleteDirectoryVisible: true }) }) }} style={{ background: "#FFF", border: "1px solid red", color: "red", marginLeft: "20px" }}>删除</Button>
                  </div>
                </div>
              }}
              treeData={this.state.treeData}
            >
            </Tree>
          </div>
          <div style={{ flex: "2" }}></div>
          <div style={{ flex: "49" }}>
            <Table columns={FileColumns}
              // rowSelection={rowSelection}
              dataSource={this.state.FileList}
              className="table"
              bordered={true}
              pagination={paginationProps_File}
              rowKey={record => record.ID}
              size='small'
            ></Table>
          </div>

        </div>
      </div>
    )
  }
  componentDidMount() {
    this.DirectoryPaging(1); //获取第一层级菜单内容
  }
  onSelect(selectedKeys, info) {
    this.setState({ FileParentID: info.node.ID }, () => { this.FilePaging(1) })
  }
  DirectoryPaging(page) {
    // let limit = 10
    // let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/directory",
      method: "get",
      params: {
        limit: 0,
        offset: 0,
        parentID: 0
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ treeData: res.data, loadedKeys: [] })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  // 通过id获取下一层层级关系
  getKnowledgeStorageNextLayer(knowid) {
    service.request({
      url: "/admin/directory",
      method: "get",
      params: {
        limit: 0,
        offset: 0,
        parentID: knowid
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {

        this.setState({ TreeNodeData: res.data })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }
  onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      if (treeNode.props.children) {
        resolve();
        return;
      }
      this.getKnowledgeStorageNextLayer(treeNode.props.ID);
      setTimeout(() => {
        treeNode.props.data.children = this.state.TreeNodeData;
        this.setState({
          treeData: [...this.state.treeData],
        });
        resolve();
      }, 1000);
    });
  }
  onLoad = (loadedKeys) => {
    this.setState({ loadedKeys: loadedKeys })
  }
  // 查文件
  FilePaging(page) {
    let limit = 10
    let newoffset = (page - 1) * limit
    service.request({
      url: "/admin/material",
      method: "get",
      params: {
        limit: 10,
        offset: newoffset,
        directoryID: this.state.FileParentID,
      }
    }).then(res => {
      if (res.code != 200) {
        message.error('数据加载失败，请重试');
      } else {
        this.setState({ FileList: res.data, FileCount: res.count, FilePage: page, })
      }
    }).catch(function (error) {
      message.error('系统繁忙');
    })
  }


  addDirectoryRef = (ref) => {
    this.childAddDirectoryRef = ref
  }
  AddDirectorySave() {
    var form = new FormData()
    if (this.childAddDirectoryRef.state.Name != "" && this.childAddDirectoryRef.state.Cover != "" && this.childAddDirectoryRef.state.IsChild != 0) {
      form.set("Name", this.childAddDirectoryRef.state.Name)
      form.set("Cover", this.childAddDirectoryRef.state.Cover)
      form.set("ParentID", this.childAddDirectoryRef.state.ParentID)
      form.set("IsChild", this.childAddDirectoryRef.state.IsChild)
      this.setState({
        AddDirectoryLoading: true,
      })
      service.request({
        url: "/admin/directory",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddDirectoryLoading: false,
            AddDirectoryVisible: false
          })
          this.DirectoryPaging(1)
          message.success("添加成功")
        } else {
          this.setState({
            AddDirectoryLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("添加失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        AddDirectoryLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  editorDirectoryRef = (ref) => {
    this.childEditorDirectoryRef = ref
  }
  EditorDirectorySave() {
    var form = new FormData()
    if (this.childEditorDirectoryRef.state.Name != "" && this.childEditorDirectoryRef.state.Cover != "") {
      form.set("Name", this.childEditorDirectoryRef.state.Name)
      form.set("Cover", this.childEditorDirectoryRef.state.Cover)
      form.set("ParentID", this.childEditorDirectoryRef.state.ParentID)
      form.set("IsChild", this.childEditorDirectoryRef.state.IsChild)
      form.set("ID", this.childEditorDirectoryRef.state.ID)
      this.setState({
        AddDirectoryLoading: true,
      })
      service.request({
        url: "/admin/directory",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            EditorDirectoryLoading: false,
            EditorDirectoryVisible: false
          })
          this.DirectoryPaging(1)
          message.success("修改成功")
        } else {
          this.setState({
            EditorDirectoryLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else {
            message.error("修改失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        EditorDirectoryLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  // 删除
  VerifyDeleteDirectory() {
    this.setState({ DeleteDirectoryLoading: true })
    service.request({
      url: "/admin/directory",
      method: "delete",
      data: {
        ID: this.state.TableItem.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.DirectoryPaging(1)
        this.setState({ DeleteDirectoryLoading: false, DeleteDirectoryVisible: false })
        message.success('删除成功');
      } else {
        this.setState({ DeleteDirectoryLoading: false, })
        if (res.code == 451) {
          message.error('该目录下面有数据，无法删除');
        }
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
  // 文件
  // 添加.
  addFileRef = (ref) => {
    this.childAddFileRef = ref
  }
  AddFileSave() {
    var form=new FormData
    if (this.childAddFileRef.state.Name != "" && this.childAddFileRef.state.Cover != "" && this.childAddFileRef.state.File != "") {
      form.set("Name", this.childAddFileRef.state.Name)
      form.set("Cover", this.childAddFileRef.state.Cover)
      form.set("File", this.childAddFileRef.state.File)
      form.set("DirectoryID", this.childAddFileRef.state.DirectoryID)
      form.set("IsDownload", this.childAddFileRef.state.IsDownload)
      this.setState({
        AddFileLoading: true,
      })
      service.request({
        url: "/admin/material",
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data:form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            AddFileLoading: false,
            AddFileVisible: false
          })
          this.FilePaging(1)
          message.success("添加成功")
        } else {
          this.setState({
            AddFileLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else if (res.code == 401) {
            message.error("添加失败，请选择课程")
          }
          else {
            message.error("添加失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        AddCourseLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  editorFileRef = (ref) => {
    this.childEditorFileRef = ref
  }
  EditorFileSave() {
    var form=new FormData
    if (this.childEditorFileRef.state.Name != "" && this.childEditorFileRef.state.Cover != "" && this.childEditorFileRef.state.File != "") {
      form.set("Name", this.childEditorFileRef.state.Name)
      form.set("Cover", this.childEditorFileRef.state.Cover)
      form.set("File", this.childEditorFileRef.state.File)
      form.set("DirectoryID", this.childEditorFileRef.state.DirectoryID)
      form.set("IsDownload", this.childEditorFileRef.state.IsDownload)
      form.set("ID", this.childEditorFileRef.state.ID)
      this.setState({
        EditorFileLoading: true,
      })
      service.request({
        url: "/admin/material",
        method: "put",
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,

      }).then(res => {
        if (res.code == 200) {
          this.setState({
            EditorFileLoading: false,
            EditorFileVisible: false
          })
          this.FilePaging(1)
          message.success("修改成功")
        } else {
          this.setState({
            EditorFileLoading: false,
          })
          if (res.code == 403) {
            message.error("该名称已存在！")
          }
          else if (res.code == 401) {
            message.error("修改失败，请选择课程")
          }
          else {
            message.error("修改失败，请重试")
          }
        }
      })
    } else {
      this.setState({
        EditorFileLoading: false,
      })
      message.error('输入不能为空');
    }
  }
  // 删除
  VerifyDeleteFile() {
    this.setState({ DeleteFileLoading: true })
    service.request({
      url: "/admin/material",
      method: "delete",
      data: {
        ID: this.state.FileItem.ID,
      }
    }).then(res => {
      if (res.code == 200) {
        this.FilePaging(1)
        this.setState({ DeleteFileLoading: false, DeleteFileVisible: false })
        message.success('删除成功');
      } else {
        this.setState({ DeleteFileLoading: false, })
        if (res.code == 451) {
          message.error('该目录下面有数据，无法删除');
        }
      }
    }).catch(function (error) {
      message.error('系统繁忙');
      console.log(error)
    })
  }
}