import React from 'react';
import { Input, Button, Space, Upload, Radio } from 'antd';

class AddDirectory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Cover: "",
            fileList: [],
            Header: "https://api2.yuexiaojing.com/v3/",
            form: null,
            ParentID: 0,
            TypeList: [{ Name: "有子级", ID: 1 }, { Name: "无子级", ID: 2 }],
            IsChild:0,
        };
    }
    render() {

        return (
            <div style={{ widht: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "1200px", }}>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}
                    >

                        <Space align="center">
                            <div style={{ width: "100px", textAlign: 'right' }}>活动名称：</div>
                            <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => {
                                this.setState({
                                    Name: e.target.value,
                                })
                            }} />
                        </Space>
                        <Space align="center"  >
                            <div style={{ width: "100px", textAlign: 'right' }}>活动封面：</div>
                            <Upload maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.Upload(f) }} fileList={this.state.fileList}  >
                                <Button style={{ background: "#0081FF", color: "#fff" }}>上传</Button>
                            </Upload>
                        </Space>
                        <Space align="center"  >
                        <div style={{ width: "100px", textAlign: 'right' }}>子级：</div>
                            <Radio.Group buttonStyle="solid" defaultValue={this.state.IsChild}>
                                {
                                    this.state.TypeList.map((item, index) => {
                                        return <Radio.Button checked={true} style={{marginLeft:index==0?"0px":"15px"}} onChange={() => this.selectType(item)} value={item.ID}  key={item.ID}>{item.Name}</Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.setState({
            ParentID: this.props.ParentID
        })
    }
    selectType(item) {
        this.setState({
            IsChild: item.ID
        })
    }
    Upload(f) {

        this.setState({ Cover: f.file, fileList: f.fileList })
    }
    beforeUpload() {
        return false
    }
}
export default AddDirectory 