import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { adminRoutes, renderRoutesMain, } from './routes'
import Frame from './components/frame'
// import { isLogined } from './Utlis/auth'
import cookie from "react-cookies";
import 'antd/dist/antd.css';

export default class App extends Component {
    render() {
        return this.checkLogin() ? (
            <Frame>
                {renderRoutesMain(adminRoutes)}
            </Frame>
        ) : (<Redirect to="/login" />)
        //  return <Frame>
        //         {renderRoutesMain(adminRoutes)}
        //     </Frame>
       
    }
    checkLogin() {
        var token = cookie.load("admintoken")
        if (token) {
            return true
        }
            return false
        
    }
}
